import request from "@/utils/request";

//信息流配置列表
export function getinfoFlowList(query) {
  return request({
    url: 'admin/inner/channel/infoFlow/list',
    method: 'get',
    params: query
  });
}
//新增信息流
export function addinfoFlow(data) {
  return request({
    url: 'admin/inner/channel/infoFlow/add',
    method: 'post',
    data: data
  });
}
//删除信息流
export function delChannel(query) {
  return request({
    url: 'admin/inner/channel/infoFlow/del',
    method: 'delete',
    params: query
  });
}
//修改信息流
export function updateinfoFlow(data) {
  return request({
    url: 'admin/inner/channel/infoFlow/update',
    method: 'put',
    data: data
  });
}
//修改设备获取检测连接前缀
export function monitoringLink(query) {
  return request({
    url: 'admin/inner/channel/infoFlow/monitoringLink',
    method: 'get',
    params: query
  });
}
//联调
export function debugging(query) {
  return request({
    url: 'admin/inner/channel/infoFlow/debugging',
    method: 'get',
    params: query
  });
}
//获取渠道推官广第三方平台列表
export function getChannelPlatformList() {
  return request({
    url: 'admin/inner/channel/infoFlow/getChannelPlatformList',
    method: 'get'
  });
}