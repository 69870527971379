//修改官方渠道
import request from "@/utils/request";
export function getAppUserOrderBaseData(query) {
  return request({
    url: 'admin/inner/application/getAppUserOrderBaseData',
    method: 'get',
    params: query
  });
}
export function appOfficialChannelListByAppId(query) {
  return request({
    url: 'admin/inner/application/appChannelListByAppId',
    method: 'get',
    params: query
  });
}