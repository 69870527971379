var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _vm._m(0),
    _c("div", { staticClass: "irBWyp" }, [
      _c("div", [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "login-iframe" },
          [
            _c(
              "el-form",
              {
                ref: "loginForm",
                attrs: {
                  "label-position": "top",
                  rules: _vm.rules,
                  model: _vm.formLogin,
                  size: "default",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c("el-input", {
                      attrs: {
                        "prefix-icon": "el-icon-user",
                        type: "text",
                        placeholder: "请输入邮箱",
                      },
                      model: {
                        value: _vm.formLogin.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLogin, "username", $$v)
                        },
                        expression: "formLogin.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("el-input", {
                      attrs: {
                        "prefix-icon": "el-icon-lock",
                        type: "password",
                        "show-password": "",
                        placeholder: "请输入密码",
                      },
                      model: {
                        value: _vm.formLogin.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLogin, "password", $$v)
                        },
                        expression: "formLogin.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "checkPass" } },
                  [
                    _c("el-input", {
                      attrs: {
                        "prefix-icon": "el-icon-lock",
                        type: "password",
                        "show-password": "",
                        placeholder: "再次输入登录密码",
                      },
                      model: {
                        value: _vm.formLogin.checkPass,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLogin, "checkPass", $$v)
                        },
                        expression: "formLogin.checkPass",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "captcha" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { type: "text", placeholder: "请输入验证码" },
                        model: {
                          value: _vm.formLogin.cap,
                          callback: function ($$v) {
                            _vm.$set(_vm.formLogin, "cap", $$v)
                          },
                          expression: "formLogin.cap",
                        },
                      },
                      [
                        _c("template", { slot: "append" }, [
                          _c("img", {
                            staticClass: "login-code",
                            staticStyle: { height: "37px" },
                            attrs: { src: _vm.image_base },
                            on: { click: _vm.getCaptcha },
                          }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "button-login",
                    attrs: { size: "default", type: "primary" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("注册")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "logbtn" }, [
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("span", { staticClass: "tips-text" }, [_vm._v("已有账号,")]),
              _c(
                "router-link",
                { staticClass: "tips-link", attrs: { to: "inlogin" } },
                [_vm._v("去登录")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marketing-wrapper" }, [
      _c("img", { attrs: { src: "/image/theme/star/1.png" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tabs-nav" }, [
      _c("div", { staticClass: "tabs-item active" }, [
        _c("div", { staticClass: "tabs-item-text" }, [_vm._v("账号密码注册")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }