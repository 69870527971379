import router from '@/router';
import { cloneDeep } from 'lodash';
import { database as getDatabase, dbGet, dbSet } from '@/libs/util.db';
export default {
  namespaced: true,
  actions: {
    /**
     * @description 将数据存储到指定位置 | 路径不存在会自动初始化
     * @description 效果类似于取值 dbName.path = value
     * @param {Object} context
     * @param {Object} payload dbName {String} 数据库名称
     * @param {Object} payload path {String} 存储路径
     * @param {Object} payload value {*} 需要存储的值
     * @param {Object} payload user {Boolean} 是否区分用户
     */set: function set(context, _ref) {
      var _ref$dbName = _ref.dbName,
        dbName = _ref$dbName === void 0 ? 'database' : _ref$dbName,
        _ref$path = _ref.path,
        path = _ref$path === void 0 ? '' : _ref$path,
        _ref$value = _ref.value,
        value = _ref$value === void 0 ? '' : _ref$value,
        _ref$user = _ref.user,
        user = _ref$user === void 0 ? false : _ref$user;
      dbSet({
        dbName: dbName,
        path: path,
        value: value,
        user: user
      });
    },
    /**
     * @description 获取数据
     * @description 效果类似于取值 dbName.path || defaultValue
     * @param {Object} context
     * @param {Object} payload dbName {String} 数据库名称
     * @param {Object} payload path {String} 存储路径
     * @param {Object} payload defaultValue {*} 取值失败的默认值
     * @param {Object} payload user {Boolean} 是否区分用户
     */get: function get(context, _ref2) {
      var _ref2$dbName = _ref2.dbName,
        dbName = _ref2$dbName === void 0 ? 'database' : _ref2$dbName,
        _ref2$path = _ref2.path,
        path = _ref2$path === void 0 ? '' : _ref2$path,
        _ref2$defaultValue = _ref2.defaultValue,
        defaultValue = _ref2$defaultValue === void 0 ? '' : _ref2$defaultValue,
        _ref2$user = _ref2.user,
        user = _ref2$user === void 0 ? false : _ref2$user;
      return dbGet({
        dbName: dbName,
        path: path,
        defaultValue: defaultValue,
        user: user
      });
    },
    /**
     * @description 获取存储数据库对象
     * @param {Object} context
     * @param {Object} payload user {Boolean} 是否区分用户
     */database: function database(context) {
      var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref3$user = _ref3.user,
        user = _ref3$user === void 0 ? false : _ref3$user;
      return getDatabase({
        user: user,
        defaultValue: {}
      });
    },
    /**
     * @description 清空存储数据库对象
     * @param {Object} context
     * @param {Object} payload user {Boolean} 是否区分用户
     */databaseClear: function databaseClear(context) {
      var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref4$user = _ref4.user,
        user = _ref4$user === void 0 ? false : _ref4$user;
      return getDatabase({
        user: user,
        validator: function validator() {
          return false;
        },
        defaultValue: {}
      });
    },
    /**
     * @description 获取存储数据库对象 [ 区分页面 ]
     * @param {Object} context
     * @param {Object} payload basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} payload user {Boolean} 是否区分用户
     */databasePage: function databasePage(context) {
      var _ref5 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref5$basis = _ref5.basis,
        basis = _ref5$basis === void 0 ? 'fullPath' : _ref5$basis,
        _ref5$user = _ref5.user,
        user = _ref5$user === void 0 ? false : _ref5$user;
      return getDatabase({
        path: "$page.".concat(router.app.$route[basis]),
        user: user,
        defaultValue: {}
      });
    },
    /**
     * @description 清空存储数据库对象 [ 区分页面 ]
     * @param {Object} context
     * @param {Object} payload basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} payload user {Boolean} 是否区分用户
     */databasePageClear: function databasePageClear(context) {
      var _ref6 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref6$basis = _ref6.basis,
        basis = _ref6$basis === void 0 ? 'fullPath' : _ref6$basis,
        _ref6$user = _ref6.user,
        user = _ref6$user === void 0 ? false : _ref6$user;
      return getDatabase({
        path: "$page.".concat(router.app.$route[basis]),
        user: user,
        validator: function validator() {
          return false;
        },
        defaultValue: {}
      });
    },
    /**
     * @description 快速将页面当前的数据 ( $data ) 持久化
     * @param {Object} context
     * @param {Object} payload instance {Object} vue 实例
     * @param {Object} payload basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} payload user {Boolean} 是否区分用户
     */pageSet: function pageSet(context, _ref7) {
      var instance = _ref7.instance,
        _ref7$basis = _ref7.basis,
        basis = _ref7$basis === void 0 ? 'fullPath' : _ref7$basis,
        _ref7$user = _ref7.user,
        user = _ref7$user === void 0 ? false : _ref7$user;
      return getDatabase({
        path: "$page.".concat(router.app.$route[basis], ".$data"),
        user: user,
        validator: function validator() {
          return false;
        },
        defaultValue: cloneDeep(instance.$data)
      });
    },
    /**
     * @description 快速获取页面快速持久化的数据
     * @param {Object} context
     * @param {Object} payload instance {Object} vue 实例
     * @param {Object} payload basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} payload user {Boolean} 是否区分用户
     */pageGet: function pageGet(context, _ref8) {
      var instance = _ref8.instance,
        _ref8$basis = _ref8.basis,
        basis = _ref8$basis === void 0 ? 'fullPath' : _ref8$basis,
        _ref8$user = _ref8.user,
        user = _ref8$user === void 0 ? false : _ref8$user;
      return dbGet({
        path: "$page.".concat(router.app.$route[basis], ".$data"),
        user: user,
        defaultValue: cloneDeep(instance.$data)
      });
    },
    /**
     * @description 清空页面快照
     * @param {Object} context
     * @param {Object} payload basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} payload user {Boolean} 是否区分用户
     */pageClear: function pageClear(context, _ref9) {
      var _ref9$basis = _ref9.basis,
        basis = _ref9$basis === void 0 ? 'fullPath' : _ref9$basis,
        _ref9$user = _ref9.user,
        user = _ref9$user === void 0 ? false : _ref9$user;
      return getDatabase({
        path: "$page.".concat(router.app.$route[basis], ".$data"),
        user: user,
        validator: function validator() {
          return false;
        },
        defaultValue: {}
      });
    }
  }
};