//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getlicationList, addApplication, switchApp, updateapplication } from "@/api/outsystem/applist";
export default {
  name: "index",
  data: function data() {
    return {
      open: false,
      title: "",
      // 用户表格数据
      appList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        username: undefined,
        status: undefined,
        deptId: undefined
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "应用名不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**
     * 注销
     */cancellation: function cancellation(e) {
      var _this = this;
      switchApp({
        id: e.id
      }).then(function (res) {
        _this.msgSuccess("注销成功");
        _this.getList();
      });
    },
    /** 查询应用列表 */getList: function getList() {
      var _this2 = this;
      this.loading = true;
      getlicationList(this.queryParams).then(function (res) {
        _this2.appList = res.list;
        _this2.total = res.total;
        _this2.loading = false;
      });
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = Object.assign({}, row);
      this.open = true;
      this.title = "修改应用";
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加应用";
      this.form = {};
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        userId: undefined,
        deptId: undefined,
        username: undefined,
        nickName: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: undefined,
        status: "0"
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this3.form.id != undefined) {
            updateapplication(_this3.form).then(function (res) {
              _this3.msgSuccess("修改成功");
              _this3.open = false;
              _this3.getList();
            });
          } else {
            addApplication(_this3.form).then(function (res) {
              _this3.msgSuccess("新增成功");
              _this3.open = false;
              _this3.getList();
            });
          }
        }
      });
    }
  }
};