import request from "@/utils/request";
export function eventList(query) {
  return request({
    url: "admin/inner/application/event/list",
    method: "get",
    params: query
  });
}
export function createEvent(data) {
  return request({
    url: "admin/inner/application/event/create",
    method: "post",
    data: data
  });
}