import request from "@/utils/request";
export function listJobAll(query) {
  return request({
    url: "admin/inner/tspManage/list",
    method: "get",
    params: query
  });
}
export function listJob(query) {
  return request({
    url: "admin/inner/tsp/job/list",
    method: "get",
    params: query
  });
}
export function getscriptList(query) {
  return request({
    url: "admin/inner/tsp/scriptList",
    method: "get",
    params: query
  });
}
export function hostList(query) {
  return request({
    url: "admin/inner/tsp/hostList",
    method: "get",
    params: query
  });
}
export function addJob(data) {
  return request({
    url: "admin/inner/tsp/job/add",
    method: "post",
    data: data
  });
}
export function tspjobstart(data) {
  return request({
    url: "admin/inner/tsp/job/start",
    method: "post",
    data: data
  });
}
export function tspjobpause(data) {
  return request({
    url: "admin/inner/tsp/job/pause",
    method: "post",
    data: data
  });
}
export function delJob(query) {
  return request({
    url: "admin/inner/tsp/job/remove",
    method: "delete",
    params: query
  });
}
export function jobresume(data) {
  return request({
    url: "admin/inner/tsp/job/resume",
    method: "put",
    data: data
  });
}