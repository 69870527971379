import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/fullscreen', ['active'])),
  methods: _objectSpread({}, mapActions('d2admin/fullscreen', ['toggle']))
};