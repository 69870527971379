import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listRole, getRole, delRole, addRole, updateRole, changeRoleStatus } from "@/api/system/role";
import { handleTree } from "@/utils/common";
import { mapState } from "vuex";
export default {
  name: "Role",
  data: function data() {
    return {
      options: [{
        label: "禁用",
        state: 0
      }, {
        label: "启用",
        state: 1
      }],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 菜单列表
      menuOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        state: undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "name"
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "角色名称不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
    this.getMenuTreeselect();
  },
  computed: _objectSpread({}, mapState("d2admin/routelist", ["inroute", 'btnprm'])),
  methods: {
    /** 查询角色列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      listRole(this.addDateRange(this.queryParams, this.dateRange)).then(function (response) {
        _this.roleList = response.list;
        _this.total = response.total;
        _this.loading = false;
      });
    },
    /** 查询菜单树结构 */getMenuTreeselect: function getMenuTreeselect() {
      var data = this.inroute;
      //   .filter(item => {
      //   return item.visible === true
      // })
      this.menuOptions = handleTree(JSON.parse(JSON.stringify(data)).concat(this.btnprm), "id", "parent");
    },
    /**所有菜单节点数据*/getMenuAllCheckedKeys: function getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      var checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      var halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    /**所有部门节点数据*/getDeptAllCheckedKeys: function getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      var checkedKeys = this.$refs.dept.getCheckedKeys();
      // 半选中的部门节点
      var halfCheckedKeys = this.$refs.dept.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    /**取消按钮*/cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      this.menuExpand = true, this.menuNodeAll = false, this.deptExpand = true, this.deptNodeAll = false, this.form = {
        name: undefined,
        state: undefined,
        menuIds: [],
        menuCheckStrictly: true,
        deptCheckStrictly: true,
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /**
     * 多选框选中数据
     */handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /**
     * 树权限（展开/折叠）
     */handleCheckedTreeExpand: function handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        var treeList = this.menuOptions;
        for (var i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
        }
      }
    },
    /**
     *  树权限（全选/全不选）
     */handleCheckedTreeNodeAll: function handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
      }
    },
    /**
     *   树权限（父子联动）
     */handleCheckedTreeConnect: function handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.form.menuCheckStrictly = value ? true : false;
      } else if (type == 'dept') {
        this.form.deptCheckStrictly = value ? true : false;
      }
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加角色";
      this.$nextTick(function () {
        this.handleCheckedTreeExpand(true, "menu");
      });
    },
    /**
     * 角色状态修改
     */handleStatusChange: function handleStatusChange(row) {
      var _this2 = this;
      var text = row.state === 1 ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.name + '"角色吗？').then(function () {
        return changeRoleStatus(row.id, row.state);
      }).then(function () {
        _this2.msgSuccess(text + "成功");
      }).catch(function () {
        row.state = row.state === 0 ? 1 : 0;
      });
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      var _this3 = this;
      this.reset();
      var id = row.id || this.ids;
      getRole({
        roleId: id
      }).then(function (response) {
        _this3.form = response;
        _this3.open = true;
        _this3.$set(_this3.form, "menuCheckStrictly", true);
        _this3.$nextTick(function () {
          _this3.handleCheckedTreeExpand(true, "menu");
          var checkedKeys = response.menuIds;
          checkedKeys.forEach(function (v) {
            _this3.$nextTick(function () {
              _this3.$refs.menu.setChecked(v, true, false);
            });
          });
        });
        _this3.title = "修改角色";
      });
    },
    /** 分配用户操作 */
    handleAuthUser: function handleAuthUser(row) {
      var id = row.id;
      this.$router.push("/system/role-auth/user/" + id);
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var menuIds = _this4.getMenuAllCheckedKeys();
          _this4.form.menuIds = _this4.inroute.concat(_this4.btnprm).filter(function (item) {
            if (menuIds.includes(item.id)) return item;
          });
          if (_this4.form.id != undefined) {
            updateRole(_this4.form).then(function (res) {
              _this4.msgSuccess("修改成功");
              _this4.open = false;
              _this4.getList();
            });
          } else {
            addRole(_this4.form).then(function (res) {
              _this4.msgSuccess("新增成功");
              _this4.open = false;
              _this4.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this5 = this;
      var id = row.id || this.ids;
      this.$confirm('是否确认删除角色编号为"' + row.name + '"的数据项？').then(function () {
        return delRole(id);
      }).then(function () {
        _this5.getList();
        _this5.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('system/role/export', _objectSpread({}, this.queryParams), "role_".concat(new Date().getTime(), ".xlsx"));
    }
  }
};