import request from '@/utils/request';

// 查询用户列表
export function listUser(data) {
  return request({
    url: 'admin/inner/manageMember/getUserList',
    method: 'post',
    data: data
  });
}

//审核用户
export function auditUser(data) {
  return request({
    url: "admin/inner/manageMember/auditUser",
    method: 'put',
    data: data
  });
}
// 获取用户详情
export function getUser(id) {
  return request({
    url: "admin/inner/manageMember/getUserDetails",
    method: 'post',
    data: {
      uid: id
    }
  });
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/api/admin/saveUserDetails',
    method: 'post',
    data: data
  });
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/admin/inner/manageMember/saveUserDetails',
    method: 'post',
    data: data
  });
}

// 删除用户
export function delUser(id) {
  return request({
    url: 'admin/inner/manageMember/deleteUserInfo?uid=' + id,
    method: 'delete'
  });
}

// 用户密码重置
export function resetUserPwd(id, password) {
  var data = {
    id: id,
    password: password
  };
  return request({
    url: '/api/admin/resetUserPassword',
    method: 'post',
    data: data
  });
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  var data = {
    userId: userId,
    status: status
  };
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  });
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  });
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  });
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  var data = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  });
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  });
}

// 查询授权角色
export function getAuthRole(userId) {
  return request({
    url: '/system/user/authRole/' + userId,
    method: 'get'
  });
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: '/system/user/authRole',
    method: 'put',
    params: data
  });
}
// 清除谷歌设备
export function deleteGoogleSecret(query) {
  return request({
    url: 'admin/inner/manageMember/deleteGoogleSecret',
    method: 'delete',
    params: query
  });
}