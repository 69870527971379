import request from "@/utils/request";
export function getlicationList(query) {
  return request({
    url: 'admin/openapi/application/list',
    method: 'get',
    params: query
  });
}
export function addApplication(data) {
  return request({
    url: 'admin/openapi/application/add',
    method: 'post',
    data: data
  });
}
export function switchApp(query) {
  return request({
    url: 'admin/openapi/application/switchApp',
    method: 'put',
    params: query
  });
}
export function updateapplication(data) {
  return request({
    url: 'admin/openapi/application/update',
    method: 'post',
    data: data
  });
}