'use strict';

var watermark = {};
var setWatermark = function setWatermark(str) {
  var id = '123452384164';
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }
  var can = document.createElement('canvas');
  can.width = 300;
  can.height = 200;
  var cans = can.getContext('2d');
  cans.rotate(-15 * Math.PI / 180);
  cans.font = '14px Vedana';
  cans.fillStyle = '#E5E5E5'; // 水印的颜色
  cans.textAlign = 'left';
  cans.textBaseline = 'Middle';
  cans.fillText(str, can.width / 3, can.height / 2);
  var div = document.createElement('div');
  div.id = id;
  div.style.pointerEvents = 'none';
  div.style.top = '150px';
  div.style.left = '150px';
  div.style.position = 'fixed';
  div.style.zIndex = '10';
  div.style.width = document.documentElement.clientWidth - 100 + 'px';
  div.style.height = document.documentElement.clientHeight - 100 + 'px';
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
  document.body.appendChild(div);
  return id;
};

// 该方法只允许调用一次
watermark.set = function (str) {
  var id = setWatermark(str);
  // setInterval(() => {
  //   if (document.getElementById(id) === null) {
  //     id = setWatermark(str)
  //   }
  // }, 500)
  window.onresize = function () {
    setWatermark(str);
  };
};
export default watermark;