import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import commonData from "@/utils/commonData";
import { Message } from "element-ui";
export default {
  data: function data() {
    return {
      loading: true,
      tab: 1,
      redirectUri: ""
    };
  },
  created: function created() {
    var authCode = this.getUrlParam("authCode");
    var redirectUri = this.getUrlParam("redirectUri");
    this.redirectUri = redirectUri;
    if (redirectUri) {
      sessionStorage.setItem("redirectUri", redirectUri);
    }
    if (sessionStorage.getItem("redirectUri")) {
      if (authCode) {
        window.location = "".concat(decodeURIComponent(sessionStorage.getItem("redirectUri")), "?authCode=").concat(authCode);
        sessionStorage.removeItem("redirectUri");
      }
    } else {
      Message({
        message: "请输入重定向地址",
        type: 'error',
        duration: 3 * 1000
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    /**
     * 钉钉快捷登陆
     */
    window.DTFrameLogin({
      id: 'login_container',
      width: 240,
      height: 240
    }, {
      redirect_uri: encodeURIComponent(commonData.externalLogin),
      client_id: commonData.client_id,
      scope: 'openid',
      response_type: 'code',
      prompt: 'consent'
    }, function (loginResult) {
      _this.loading = false;
      var redirectUrl = loginResult.redirectUrl,
        authCode = loginResult.authCode,
        state = loginResult.state;
      // 这里可以直接进行重定向
      window.location.href = redirectUrl;
      // 也可以在不跳转页面的情况下，使用code进行授权
    }, function (errorMsg) {
      // 这里一般需要展示登录失败的具体原因
      console.log("Login Error: ".concat(errorMsg));
    });
    setTimeout(function () {
      _this.loading = false;
    }, 1200);
  }
};