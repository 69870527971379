var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "渠道名称", prop: "channel_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入渠道名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.channel_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "channel_name", $$v)
                  },
                  expression: "queryParams.channel_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用名称", prop: "appId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", filterable: "" },
                  model: {
                    value: _vm.queryParams.appId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "appId", $$v)
                    },
                    expression: "queryParams.appId",
                  },
                },
                _vm._l(_vm.appList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [1074],
                      expression: "[1074]",
                    },
                  ],
                  attrs: {
                    plain: "",
                    loading: _vm.oClickPackag,
                    disabled: _vm.multipleSelection.length > 0 ? false : true,
                    size: "mini",
                    type: "success",
                  },
                  on: { click: _vm.generation },
                },
                [_vm._v("一键生成apk ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _vm.oClickPackag
                ? _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "mini", type: "success" },
                      on: {
                        click: function ($event) {
                          _vm.oClickPackag = !_vm.oClickPackag
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables border",
          attrs: { data: _vm.list },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "渠道名称", align: "center", prop: "channel_name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.warningMs(scope.row)
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.warningMs(scope.row),
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning",
                              staticStyle: { color: "red" },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(scope.row.channel_name) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "应用", align: "center", prop: "app_id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.getAppName(scope.row.app_id)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "官方包版本",
              align: "center",
              prop: "channel_apk_version",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.channel_apk_version
                            ? scope.row.channel_apk_version
                            : "未知"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "下载链接", align: "center", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.download_url
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.download_url,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      plain: "",
                                      id: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText(
                                          scope.row.download_url
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("复制 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.download_url,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      plain: "",
                                      id: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.download(
                                          scope.row.download_url
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("下载 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  type: "info",
                                  size: "mini",
                                  plain: "",
                                  disabled: true,
                                  id: "text",
                                },
                              },
                              [_vm._v("暂未生成 ")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "MD5", align: "center", prop: "channel_md5" },
          }),
          _c("el-table-column", {
            attrs: { label: "app_store", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.app_store ? scope.row.app_store.name : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "备注", align: "center", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: { label: "创建日期", align: "center", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "更新日期", align: "center", prop: "updated_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [1074],
                            expression: "[1074]",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          loading: scope.row.loadingBtn,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.buildApp(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.loadingBtn ? "生成中" : "生成apk"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                          disabled: scope.row.download_url ? true : false,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.dsAppStore(scope.row)
                          },
                        },
                      },
                      [_vm._v("配置应用商店 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称", prop: "channel_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入渠道名称" },
                    model: {
                      value: _vm.form.channel_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channel_name", $$v)
                      },
                      expression: "form.channel_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用", prop: "app_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择凡骄应用" },
                      model: {
                        value: _vm.form.app_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "app_id", $$v)
                        },
                        expression: "form.app_id",
                      },
                    },
                    _vm._l(_vm.appList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置应用商店",
            visible: _vm.dsAppStoreopen,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dsAppStoreopen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.appStoreForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用商店", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.appStoreForm.appStoreId,
                        callback: function ($$v) {
                          _vm.$set(_vm.appStoreForm, "appStoreId", $$v)
                        },
                        expression: "appStoreForm.appStoreId",
                      },
                    },
                    _vm._l(_vm.appStoreList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitStoreopen },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dsAppStoreopen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }