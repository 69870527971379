import request from '@/utils/request';
export function getGroupList(query) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/group/list',
    method: 'get',
    params: query
  });
}
export function addGroup(data) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/group/add',
    method: 'post',
    data: data
  });
}
export function updategroup(data) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/group/update',
    method: 'put',
    data: data
  });
}
export function delGroup(query) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/group/del',
    method: 'delete',
    params: query
  });
}