import request from '@/utils/request';

//查询应用商店列表
export function getStoreConfig(query) {
  return request({
    url: 'admin/inner/application/storeConfig/list',
    method: 'get',
    params: query
  });
}
//新增应用商店
export function addStoreConfig(data) {
  return request({
    url: 'admin/inner/application/storeConfig/add',
    method: 'post',
    data: data
  });
}
//修改应用商店
export function updateStoreConfig(data) {
  return request({
    url: 'admin/inner/application/storeConfig/update',
    method: 'put',
    data: data
  });
}
//删除应用商店
export function delStoreConfig(query) {
  return request({
    url: 'admin/inner/application/storeConfig/del',
    method: 'delete',
    params: query
  });
}
//获取引用列表
export function getstoreList(query) {
  return request({
    url: 'admin/inner/application/storeConfig/storeList',
    method: 'get',
    params: query
  });
}