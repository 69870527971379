import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { channelRequestLog } from "@/api/system/log/informationFlow";
export default {
  name: "index",
  data: function data() {
    return {
      options: {
        install: '激活',
        register: '注册',
        recharge: '充值'
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 查询登录日志列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      channelRequestLog(this.addDateRange(this.queryParams, this.dateRange)).then(function (response) {
        console.log(response);
        _this.list = response.list;
        _this.total = response.total;
        _this.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 多选框选中数据 */handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.infoId;
      });
      this.multiple = !selection.length;
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('admin/inner/user/log', _objectSpread(_objectSpread({}, this.queryParams), {}, {
        export: true
      }), "logininfor_".concat(new Date().getTime(), ".xlsx"));
    }
  }
};