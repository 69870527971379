import request from "@/utils/request";
export function apisList(query) {
  return request({
    url: "admin/inner/openInstall/openApiList",
    method: "get",
    params: query
  });
}
export function addApis(data) {
  return request({
    url: "/api/applicationApis/add",
    method: "post",
    data: data
  });
}
export function delApis(query) {
  return request({
    url: "/api/applicationApis/del",
    method: "delete",
    params: query
  });
}
export function startAndStopApis(data) {
  return request({
    url: "/api/applicationApis/startAndStopApis",
    method: "put",
    data: data
  });
}
export function updateApis(data) {
  return request({
    url: "/api/applicationApis/update",
    method: "put",
    data: data
  });
}