import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getManageData } from "@/api/system/operationManagement/operatData";
import { parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      title: "",
      dialogTableVisible: false,
      detilsList: [],
      activeName: 'first',
      DateRange: [],
      arrList: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        type: "day"
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.DateRange[0] = parseTime(new Date().getTime() - 518400000, "{y}-{m}-{d}");
    this.DateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    this.getList();
  },
  methods: {
    clickDetil: function clickDetil(title, list) {
      this.title = title;
      this.detilsList = list.detail;
      this.dialogTableVisible = true;
    },
    deviceSide: function deviceSide(e) {
      this.queryParams.type = e;
      this.getList();
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getList();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 查询分组列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      getManageData(this.addDateRange(this.queryParams, this.DateRange, "defult")).then(function (res) {
        _this.list = res.list;
        if (res.list.length > 0) {
          var arr = [];
          for (var key in res.list[0]) {
            arr.push(key);
          }
          _this.arrList = arr;
        }
        _this.total = res.total;
        _this.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  },
  computed: {
    forMart: function forMart() {
      return function (val) {
        switch (val) {
          case "other":
            return "其他";
          case "total":
            return "合计";
          case val:
            return val;
        }
      };
    },
    formColor: function formColor() {
      return function (val) {
        if (val > 0) {
          return {
            color: "#67c23a"
          };
        } else if (val < 0) {
          return {
            color: "#f56c6c"
          };
        } else {
          return {
            color: "#87CEFA"
          };
        }
      };
    }
  }
};