import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { countSevenDayByChannel, getapplication, saveCost } from "@/api/system/extensionmant/myData";
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import util from "@/libs/util";
import { parseTime } from "@/utils/common";
import { MessageBox } from "element-ui";
export default {
  name: "index",
  data: function data() {
    return {
      costsList: [{
        lable: "全部",
        value: "all"
      }, {
        lable: "已设置",
        value: "true"
      }, {
        lable: "未设置",
        value: "false"
      }],
      // 保存进入编辑的cell
      clickCellMap: {},
      // 总条数
      total: 0,
      activeName: "consumption",
      dateRange: [],
      //日期
      pickerOption: {
        disabledDate: function disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近15天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      channel: [],
      options: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        user_id: util.cookies.get('uuid'),
        channelCode: "",
        is_input_costs: "all"
      },
      column: [],
      //合计数据
      count: {}
    };
  },
  created: function created() {
    var _this = this;
    this.dateRange[0] = parseTime(new Date().getTime() - 86400000 * 7, "{y}-{m}-{d}");
    this.dateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    getapplication(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
      var _res$list$, _res$list$2, _res$list$3;
      _this.options = res.list;
      _this.$set(_this.queryParams, "appId", {
        id: (_res$list$ = res.list[0]) === null || _res$list$ === void 0 ? void 0 : _res$list$.id,
        appName: (_res$list$2 = res.list[0]) === null || _res$list$2 === void 0 ? void 0 : _res$list$2.appName
      });
      _this.channel = res === null || res === void 0 ? void 0 : (_res$list$3 = res.list[0]) === null || _res$list$3 === void 0 ? void 0 : _res$list$3.channel;
      if (_this.queryParams.appId.id) {
        _this.getList();
      } else {
        _this.loading = false;
      }
    });
  },
  methods: {
    /**
     * 自定义合计
     * @param param
     * @returns {*[]}
     */getSummaries: function getSummaries(param) {
      var _this2 = this;
      var columns = param.columns;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if ((_this2.count[column.property] || _this2.count[column.property] === 0) && column.property !== "cost_month_amount" && column.property !== 'cost_month_retain_recharge_amount') {
          var data = _this2.count[column.property];
          if (_this2.activeName === "report" && column.property === "upload_amount") {
            sums[index] = data === 0 ? 0 : data / 100;
          } else {
            sums[index] = data;
          }
        } else {
          sums[index] = '-';
        }
      });
      return sums;
    },
    tableAddClass: function tableAddClass(_ref) {
      var row = _ref.row;
      if (row.cost_id == 'NULL') {
        return 'tr-red';
      } else {
        return '';
      }
    },
    handleClick: function handleClick() {
      if (this.queryParams.appId.id) {
        this.getList();
      }
    },
    changeApp: function changeApp(e) {
      this.channel = e.channel;
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getList();
    },
    htmlToExcel: function htmlToExcel() {
      var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '默认标题';
      var dom = "#".concat(this.activeName);
      var wb = XLSX.utils.table_to_book(document.querySelector(dom));
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      var blob = new Blob([wbout], {
        type: 'application/octet-stream'
      });
      FileSaver.saveAs(blob, "".concat(title, ".xlsx"));
    },
    headerClick: function headerClick(column, event) {
      var index = this.column.indexOf(column.id);
      if (index > -1) {
        this.column.splice(index, 1);
      } else {
        this.column.push(column.id);
      }
    },
    cellClassName: function cellClassName(_ref2) {
      var row = _ref2.row,
        column = _ref2.column,
        rowIndex = _ref2.rowIndex,
        columnIndex = _ref2.columnIndex;
      if (this.column.indexOf(column.id) > -1) {
        return "cellName";
      }
    },
    /** 查询应用商店列表 */getList: function getList() {
      var _this3 = this;
      this.loading = true;
      this.queryParams.app_id = this.queryParams.appId.id;
      this.queryParams.type = this.activeName;
      countSevenDayByChannel(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
        res.list.forEach(function (item) {
          item.inputDisable = item.cost_input_costs || item.cost_input_costs !== 'NULL' && item.cost_input_costs ? true : false;
        });
        _this3.count = res.count;
        _this3.list = res.list;
        _this3.total = res.total;
        _this3.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};