import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import setting from '@/setting.js';
export default {
  namespaced: true,
  state: {
    // 搜索面板激活状态
    active: false,
    // 快捷键
    hotkey: {
      open: setting.hotkey.search.open,
      close: setting.hotkey.search.close
    },
    // 所有可以搜索的页面
    pool: []
  },
  mutations: {
    /**
     * @description 切换激活状态
     * @param {Object} state state
     */toggle: function toggle(state) {
      state.active = !state.active;
    },
    /**
     * @description 设置激活模式
     * @param {Object} state state
     * @param {Boolean} active active
     */set: function set(state, active) {
      state.active = active;
    },
    /**
     * @description 初始化
     * @param {Object} state state
     * @param {Array} menu menu
     */init: function init(state, menu) {
      var pool = [];
      var push = function push(menu) {
        var titlePrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        menu.forEach(function (m) {
          if (m.children) {
            push(m.children, [].concat(_toConsumableArray(titlePrefix), [m.title]));
          } else {
            pool.push(_objectSpread(_objectSpread({}, m), {}, {
              fullTitle: [].concat(_toConsumableArray(titlePrefix), [m.title]).join(' / ')
            }));
          }
        });
      };
      push(menu);
      state.pool = pool;
    }
  }
};