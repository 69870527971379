import request from "@/utils/request";
export function getinappStorelist(query) {
  return request({
    url: 'admin/inner/official/appStore/list',
    method: 'get',
    params: query
  });
}
export function getKeysAppStore(query) {
  return request({
    url: 'admin/inner/official/appStore/getKeys',
    method: 'get',
    params: query
  });
}
export function generateMonitoringLink(query) {
  return request({
    url: '/admin/inner/official/appStore/monitoringLink',
    method: 'get',
    params: query
  });
}
export function createAppStore(data) {
  return request({
    url: 'admin/inner/official/appStore/create',
    method: 'post',
    data: data
  });
}
export function deleteAppStore(data) {
  return request({
    url: 'admin/inner/official/appStore/delete',
    method: 'post',
    data: data
  });
}
export function updateAppStore(data) {
  return request({
    url: 'admin/inner/official/appStore/update',
    method: 'post',
    data: data
  });
}