var commonData;
if (process.env.VUE_APP_API === '/dev-api') {
  /**
   * 测试
   */

  commonData = {
    client_id: "dinguits1gjjn2m4td2m",
    //钉钉key
    redirect_uri: "https://dev-dmp.3yakj.com/inLogin",
    //重定向地址
    externalLogin: "https://dev-dmp.3yakj.com/externalLogin",
    //第三方登录重定向地址
    encryption: 'qTkHVxvMSFJbMwg5'
  };
} else {
  /**
   * 正式
   */
  commonData = {
    client_id: "dinglntx7t94hv8xzgyi",
    redirect_uri: "https://dmp.3yakj.com/inLogin",
    externalLogin: "https://dmp.3yakj.com/externalLogin",
    encryption: 'qTkHVxvMSFJbMwg5'
  };
}
export default commonData;