import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listJobAll, hostList, tspjobstart, tspjobpause, jobresume, delJob, addJob, getscriptList, resumeBatch, stopBatch as _stopBatch } from "@/api/system/applicationconfig/dispatching";
import { mapState } from "vuex";
export default {
  name: "index",
  data: function data() {
    return {
      option: [{
        status: "run_status",
        label: "成功"
      }, {
        status: "job_run",
        label: "失败"
      }],
      options: [{
        status: 0,
        label: "未开始"
      }, {
        status: 1,
        label: "运行中"
      }, {
        status: 2,
        label: "已完成"
      }, {
        status: 3,
        label: "任务错误"
      }, {
        status: 4,
        label: "暂停"
      }],
      scriptList: [],
      dateRange: [],
      serves: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 定时任务表格数据
      jobList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 传入的表达式
      expression: "",
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        task_name: undefined,
        task_status: undefined,
        method_type: 1
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        path: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择执行策略",
          trigger: "blur"
        }],
        task_name: [{
          required: true,
          message: "任务名称不能为空",
          trigger: "blur"
        }],
        host: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        cron_expression: [{
          required: true,
          message: "请输入Cron表达式",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "请输入备注",
          trigger: "blur"
        }]
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('d2admin/user', ['info'])), {}, {
    forMart: function forMart(e) {
      return function (e) {
        var boole = e.task_status === 4 || e.task_status == 1;
        if (e.task_status == 1 && e.job_run === 'stop') {
          boole = false;
        }
        return boole;
      };
    }
  }),
  created: function created() {
    var _this = this;
    this.getList();
    /**
     * 服务器配置
     */
    hostList().then(function (response) {
      _this.serves = response;
    });
  },
  methods: {
    rowstyle: function rowstyle(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.remark.indexOf('process') !== -1) {
        return {
          background: "#F56C6C"
        };
      } else {
        return "";
      }
    },
    /**
     * 任务选择
     * @param e
     */change: function change(e) {
      this.reset();
      this.$set(this.form, "method_type", e);
    },
    /**
     * 恢复任务
     * @param e
     */handleresume: function handleresume(e) {
      var _this2 = this;
      jobresume({
        id: e.id
      }).then(function (res) {
        _this2.msgSuccess("恢复成功");
        _this2.getList();
      });
    },
    /**
     * 暂停任务
     * @param e
     */stopjob: function stopjob(e) {
      var _this3 = this;
      tspjobpause({
        id: e.id
      }).then(function (res) {
        _this3.msgSuccess("暂停成功");
        _this3.getList();
      });
    },
    /**
     * 开启任务
     * @param e
     */jobStart: function jobStart(e) {
      var _this4 = this;
      tspjobstart({
        id: e.id
      }).then(function (res) {
        _this4.msgSuccess("开启成功");
        _this4.getList();
      });
    },
    /** 查询定时任务列表 */getList: function getList() {
      var _this5 = this;
      this.loading = true;
      listJobAll(this.addDateRange(this.queryParams, this.dateRange)).then(function (response) {
        if (response) {
          _this5.jobList = response.data;
          _this5.total = response.total;
        }
        _this5.loading = false;
      });
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        jobId: undefined,
        task_name: undefined,
        jobGroup: undefined,
        host: undefined,
        method_type: 1
      };
      // this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /**
     * 多选框选中数据
     * @param selection
     */handleSelectionChange: function handleSelectionChange(selection) {
      var arr = [];
      selection.map(function (item) {
        arr.push(item.id);
      });
      this.ids = arr;
      this.multiple = !selection.length;
    },
    /**
     * 更多操作触发
     * @param command
     * @param row
     */handleCommand: function handleCommand(command, row) {
      switch (command) {
        case "deljob":
          this.handleDelete(row);
          break;
        case "resumejob":
          this.handleresume(row);
          break;
        default:
          break;
      }
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      var _this6 = this;
      /**
       * 脚本列表
       */
      getscriptList().then(function (response) {
        _this6.scriptList = response;
      });
      this.reset();
      this.open = true;
      this.$set(this.form, "type", 1);
      this.title = "添加任务";
    },
    /**
     * 一件停止
     */stopBatch: function stopBatch() {
      var _this7 = this;
      if (this.ids.length > 0) {
        this.loading = true;
        _stopBatch({
          ids: this.ids
        }).then(function (response) {
          var _response$Success;
          _this7.loading = false;
          var str = "\u6210\u529F".concat(response.Success ? (_response$Success = response.Success) === null || _response$Success === void 0 ? void 0 : _response$Success.length : '0', "\u6761\uFF0C\u5931\u8D25").concat(response.Fail ? response.Fail.length : '0', "\u6761");
          _this7.$notify({
            title: '提示',
            message: str
          });
          _this7.getList();
        }).catch(function (err) {
          _this7.loading = false;
        });
      }
    },
    /*** 一件开启*/recovery: function recovery() {
      var _this8 = this;
      if (this.ids.length > 0) {
        this.loading = true;
        resumeBatch({
          ids: this.ids
        }).then(function (response) {
          var _response$Success2;
          _this8.loading = false;
          var str = "\u6210\u529F".concat(response.Success ? (_response$Success2 = response.Success) === null || _response$Success2 === void 0 ? void 0 : _response$Success2.length : '0', "\u6761\uFF0C\u5931\u8D25").concat(response.Fail ? response.Fail.length : '0', "\u6761");
          _this8.$notify({
            title: '提示',
            message: str
          });
          _this8.getList();
        }).catch(function (err) {
          _this8.loading = false;
        });
        ;
      }
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = Object.assign({}, row);
      this.open = true;
      this.title = "修改任务";
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this9 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this9.form.jobId != undefined) {
            updateJob(_this9.form).then(function (response) {
              _this9.msgSuccess("修改成功");
              _this9.open = false;
              _this9.getList();
            });
          } else {
            _this9.form.is_group = 0;
            addJob(_this9.form).then(function (response) {
              _this9.msgSuccess("新增成功");
              _this9.open = false;
              _this9.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this10 = this;
      var jobIds = row.id || this.ids;
      this.$confirm('是否确认删除定时任务编号为"' + jobIds + '"的数据项？').then(function () {
        return delJob({
          id: jobIds
        });
      }).then(function () {
        _this10.getList();
        _this10.msgSuccess("删除成功");
      }).catch(function () {});
    }
  }
};