import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import util from '@/libs/util.js';
import request from '@/utils/request';
import { mapActions } from 'vuex';
export default {
  name: 'userInfo',
  data: function data() {
    var _this = this;
    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value === _this.userPasswordInfo.oldPassword) {
        callback(new Error('原密码与新密码一致'));
      } else {
        if (_this.userPasswordInfo.password !== '') {
          _this.$refs.userPasswordForm.validateField('password');
        }
        callback();
      }
    };
    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.userPasswordInfo.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      position: 'left',
      activeName: 'userInfo',
      userInfo: {
        username: '',
        phone: ''
      },
      clientId: util.cookies.get('client-id'),
      userInforules: {
        username: [{
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }],
        truename: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        phone: [{
          pattern: /^1[3|4|5|7|8]\d{9}$/,
          message: '请输入正确手机号'
        }]
      },
      userPasswordInfo: {
        oldPassword: '',
        newPassword: '',
        password: ''
      },
      passwordRules: {
        oldPassword: [{
          required: true,
          message: '请输入原密码',
          trigger: 'blur'
        }],
        newPassword: [{
          validator: validatePass,
          trigger: 'blur'
        }],
        password: [{
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.type = util.cookies.get('type');
    this.getCurrentUserInfo();
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['logout'])), {}, {
    /**
     * 获取当前用户信息
     */getCurrentUserInfo: function getCurrentUserInfo() {
      var _this2 = this;
      if (this.type === "0") {
        return request({
          url: 'admin/openapi/user/info',
          method: 'get',
          params: {}
        }).then(function (res) {
          _this2.$set(_this2.userInfo, "username", res.username);
          _this2.$set(_this2.userInfo, "truename", res.userInfo.truename);
          _this2.$set(_this2.userInfo, "phone", res.userInfo.phone);
        });
      } else {
        return request({
          url: 'admin/inner/user/info',
          method: 'get',
          params: {}
        }).then(function (res) {
          _this2.$set(_this2.userInfo, "username", res.username);
          _this2.$set(_this2.userInfo, "truename", res.userInfo.truename);
          _this2.$set(_this2.userInfo, "phone", res.userInfo.phone);
        });
      }
    },
    /**
     * 更新用户信息
     */updateInfo: function updateInfo() {
      var _this3 = this;
      this.$refs.userInfoForm.validate(function (valid) {
        if (valid) {
          if (_this3.type === "0") {
            request({
              url: 'admin/openapi/user/info',
              method: 'put',
              data: _this3.userInfo
            }).then(function (res) {
              _this3.$message.success('修改成功');
              _this3.getCurrentUserInfo();
            });
          } else {
            request({
              url: 'admin/inner/user/info',
              method: 'put',
              data: _this3.userInfo
            }).then(function (res) {
              _this3.$message.success('修改成功');
              _this3.getCurrentUserInfo();
            });
          }
        } else {
          // 校验失败
          // 登录表单校验失败
          _this3.$message.error('表单校验失败，请检查');
        }
      });
    },
    // tab切换
    handleClick: function handleClick(tab, event) {
      // if (tab.paneName === 'userInfo') {
      //   this.$refs.userPasswordForm.resetFields()
      // } else {
      //   this.$refs.userInfoForm.resetFields()
      // }
    },
    /**
     * 重新设置密码
     */settingPassword: function settingPassword() {
      var _this4 = this;
      this.$refs.userPasswordForm.validate(function (valid) {
        if (valid) {
          var userId = util.cookies.get('uuid');
          if (userId) {
            var params = JSON.parse(JSON.stringify(_this4.userPasswordInfo));
            if (_this4.type === "0") {
              request({
                url: 'admin/openapi/user/password',
                method: 'put',
                data: params
              }).then(function (res) {
                _this4.activeName = 'userInfo';
                _this4.$message.success('修改成功');
                _this4.logout({});
              });
            } else {
              request({
                url: 'admin/inner/user/password',
                method: 'put',
                data: params
              }).then(function (res) {
                _this4.activeName = 'userInfo';
                _this4.$message.success('修改成功');
                _this4.logout({});
              });
            }
          }
        } else {
          // 校验失败
          // 登录表单校验失败
          _this4.$message.error('表单校验失败，请检查');
        }
      });
    }
  })
};