import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getinfoFlowList, addinfoFlow, delChannel, updateinfoFlow, monitoringLink, debugging, getChannelPlatformList } from "@/api/system/extensionmant/flowConfig";
import { deepClone, copyLink } from "@/utils/common";
import { getinappStorelist } from "@/api/system/extensionmant/adReturn";
export default {
  name: "index",
  data: function data() {
    return {
      methodList: ["GET", "POST"],
      alignmentList: [{
        name: "",
        def: "",
        option: ""
      }],
      typeList: {},
      method: [],
      copyText: copyLink,
      callback: [],
      alignmentLoading: false,
      alignment: false,
      link: "",
      host: "",
      platform: {
        deviceName: "",
        id: ""
      },
      platList: ["ios", "android"],
      arr: [{
        key: "hash_idfa",
        value: ""
      }, {
        key: "hash_imei",
        value: ""
      }, {
        key: "callback_url",
        value: ""
      }],
      dialogVisible: false,
      statusOption: [{
        value: 0,
        label: '待联调',
        disabled: true
      }, {
        value: 1,
        label: '开启'
      }, {
        value: -1,
        label: '禁用'
      }],
      title: "",
      open: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        route: undefined
      },
      // 表单校验
      rules: {
        platform_name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注不能为空",
          trigger: "blur"
        }]
      },
      openInstallData: {},
      dataList: {},
      platformByApp: [{
        key: "ios"
      }, {
        key: "android"
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    getChannelPlatformList().then(function (res) {
      _this.typeList = res;
    });
  },
  methods: {
    oninputKey: function oninputKey() {
      var str = "";
      this.arr.forEach(function (item) {
        str += "&".concat(item.key, "=").concat(item.value);
      });
      this.link = this.host + str;
    },
    /**
     * 修改设备
     * @param e
     */changeEquipment: function changeEquipment(e) {
      var _this2 = this;
      monitoringLink(this.platform).then(function (res) {
        _this2.host = res.host;
        _this2.oninputKey();
      });
    },
    deleteInput: function deleteInput(index) {
      this.arr.splice(index, 1);
      this.oninputKey();
    },
    deleteAmInput: function deleteAmInput(index) {
      this.alignmentList.splice(index, 1);
    },
    addAmInput: function addAmInput() {
      this.alignmentList.push({
        name: "",
        def: "",
        option: ""
      });
    },
    addInput: function addInput() {
      this.arr.push({
        key: "",
        value: ""
      });
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
      this.form = {};
    },
    handleAlignment: function handleAlignment(id) {
      this.platform.id = id;
      this.alignment = true;
      this.debugg();
    },
    debugg: function debugg() {
      var _this3 = this;
      this.alignmentLoading = true;
      debugging({
        id: this.platform.id
      }).then(function (res) {
        _this3.callback = res.callback;
        _this3.method = res.method;
        _this3.alignmentLoading = false;
      });
    },
    /**
     * 检测链接弹框
     */theLink: function theLink(id) {
      this.dialogVisible = true;
      this.platform.id = id;
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.statusOption[1].disabled = row.status === 1 ? false : true;
      this.reset();
      this.form = deepClone(row);
      this.open = true;
      this.title = "修改";
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this4 = this;
      var id = row.id || this.ids;
      this.$confirm('是否确认删除"' + row.platform_name + '"的数据项？').then(function () {
        return delChannel({
          id: id
        });
      }).then(function () {
        _this4.getList();
        _this4.msgSuccess("删除成功");
      }).catch(function () {});
    },
    alsubmit: function alsubmit() {
      var _this5 = this;
      updateinfoFlow({
        id: this.platform.id,
        status: 1,
        params: this.alignmentList,
        key: this.form.type,
        method: this.form.method
      }).then(function (res) {
        _this5.msgSuccess("联调成功");
        _this5.alignment = false;
        _this5.getList();
      });
    },
    submit: function submit() {
      var _this6 = this;
      updateinfoFlow({
        id: this.platform.id,
        monitoringLink: this.link
      }).then(function (res) {
        _this6.msgSuccess("修改成功");
        _this6.dialogVisible = false;
        _this6.getList();
      });
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this7 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this7.form.id != undefined) {
            updateinfoFlow(_this7.form).then(function (res) {
              _this7.msgSuccess("修改成功");
              _this7.open = false;
              _this7.getList();
            });
          } else {
            addinfoFlow(_this7.form).then(function (res) {
              _this7.msgSuccess("新增成功");
              _this7.open = false;
              _this7.getList();
            });
          }
        }
      });
    },
    /** 查询渠道列表 */getList: function getList() {
      var _this8 = this;
      this.loading = true;
      getinfoFlowList(this.queryParams).then(function (res) {
        if (res.data) {
          _this8.list = res.data;
          _this8.total = res.total;
        }
        _this8.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};