//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import detail from "./detailslog";
import list from "./list";
import perion from "./persion";
export default {
  data: function data() {
    return {
      id: ""
    };
  },
  components: {
    detail: detail,
    list: list,
    perion: perion
  },
  created: function created() {
    if (this.$route.params.appId) {
      this.id = this.$route.params.appId;
    }
  }
};