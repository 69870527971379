import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import localeMixin from '@/locales/mixin.js';
import { getCaptcha as _getCaptcha, register } from "@/api/login";
import util from "@/libs/util";
export default {
  mixins: [localeMixin],
  data: function data() {
    var _this = this;
    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.formLogin.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var checkEmail = function checkEmail(rule, value, callback) {
      var mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error('邮箱不能为空'));
      }
      setTimeout(function () {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的邮箱格式'));
        }
      }, 100);
    };
    return {
      image_base: "",
      // 表单
      formLogin: {
        username: '',
        password: ''
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          validator: checkEmail,
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        checkPass: [{
          required: true,
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['load'])), {}, {
    /**
     * 去登陆
     */inlogin: function inlogin() {
      this.$router.push("/login");
    },
    /**
     * @description 提交表单
     */submit: function submit() {
      var _this2 = this;
      var that = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          register({
            email: that.formLogin.username,
            password: that.formLogin.password,
            cap: that.formLogin.cap
          }).then(function (res) {
            util.cookies.set('uuid', res.id);
            util.cookies.set('type', res.type);
            util.cookies.set('token', res.token.accessToken);
            _this2.$store.dispatch("d2admin/user/set", {
              name: res.username,
              user_id: res.id
            }, {
              root: true
            });
            _this2.load();
            _this2.$router.replace('/');
            _this2.msgSuccess("注册成功");
          }).catch(function (err) {
            _this2.getCaptcha();
          });
        } else {
          // 登录表单校验失败
          _this2.$message.error('表单校验失败，请检查');
        }
      });
    },
    /**
     * 获取验证码
     */getCaptcha: function getCaptcha() {
      var _this3 = this;
      _getCaptcha().then(function (res) {
        _this3.image_base = res.cap;
      });
    }
  }),
  /**
   * 获取验证码
   */created: function created() {
    this.$store.dispatch('d2admin/db/databaseClear'), this.getCaptcha();
  }
};