import request from "@/utils/request";
export function getChannelGroupByUid() {
  return request({
    url: '/admin/inner/channel/getChannelGroupByUid',
    method: 'post'
  });
}
export function getList(query) {
  return request({
    url: '/admin/inner/channel/listMy',
    method: 'get',
    params: query
  });
}
export function getListAll(query) {
  return request({
    url: 'admin/inner/channel/list',
    method: 'get',
    params: query
  });
}
export function addChannel(data) {
  return request({
    url: 'admin/inner/channel/add',
    method: 'post',
    data: data
  });
}
export function delChannel(query) {
  return request({
    url: 'admin/inner/channel/del',
    method: 'delete',
    params: query
  });
}
export function detailsChannel(query) {
  return request({
    url: 'admin/inner/channel/details',
    method: 'get',
    params: query
  });
}
export function consumptiondata(query) {
  return request({
    url: 'admin/inner/channel/threeDetails',
    method: 'get',
    params: query
  });
}
export function build(data) {
  return request({
    url: 'admin/inner/channel/executeMakeApk',
    method: 'post',
    data: data
  });
}
export function getchannelGroup() {
  return request({
    url: 'admin/inner/channel/channelGroup',
    method: 'get'
  });
}

/**
 * 友盟数据
 * @param data
 * @returns {*}
 * @constructor
 */
export function UmengData(data) {
  return request({
    url: 'admin/inner/application/app/getSummaryData',
    method: 'post',
    data: data
  });
}

/**
 * 上报数据
 * @param data
 * @returns {*}
 */
export function escalationDetails(query) {
  return request({
    url: 'admin/inner/channel/escalationDetails',
    method: 'get',
    params: query
  });
}