import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["checkBalloon", "borderBalloon"],
  data: function data() {
    return {
      // 存放粘贴进来的数字
      pasteResult: [],
      input: ['', '', '', '', '', '']
    };
  },
  methods: {
    keydown: function keydown(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (e.key === 'Backspace') {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, '');
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus();
            this.$set(this.input, index - 1, '');
          }
        }
      } else if (e.key === 'Delete') {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, '');
        } else {
          if (el.nextElementSibling) {
            this.$set(this.input, index = 1, '');
          }
        }
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === 'Home') {
        el.parentElement.children[0] && el.parentElement.children[0].focus();
      } else if (e.key === 'End') {
        el.parentElement.children[this.input.length - 1] && el.parentElement.children[this.input.length - 1].focus();
      } else if (e.key === 'ArrowLeft') {
        if (el.previousElementSibling) {
          el.previousElementSibling.focus();
        }
      } else if (e.key === 'ArrowRight') {
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === 'ArrowUp') {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.key === 'ArrowDown') {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      } else if (e.key === 'Enter') {
        this.$emit('clickVcation');
      }
    },
    keyup: function keyup(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (/Digit|Numpad/i.test(e.code) && e.code !== 'NumpadEnter') {
        this.$set(this.input, index, e.code.replace(/Digit|Numpad/i, ''));
        el.nextElementSibling && el.nextElementSibling.focus();
        if (index === 5) {
          if (this.input.join('').length === 6) {
            // document.activeElement.blur();
            this.$emit('complete', this.input);
          }
        }
      } else {
        if (this.input[index] === '') {
          this.$set(this.input, index, '');
        }
      }
    },
    mousewheel: function mousewheel(e) {
      var index = e.target.dataset.index;
      if (e.wheelDelta > 0) {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.wheelDelta < 0) {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      } else if (e.key === 'Enter') {
        if (this.input.join('').length === 6) {
          document.activeElement.blur();
          this.$emit('complete', this.input);
        }
      }
    },
    paste: function paste(e) {
      var _this = this;
      // 当进行粘贴时
      e.clipboardData.items[0].getAsString(function (str) {
        if (str.toString().length === 6) {
          _this.pasteResult = str.split('');
          document.activeElement.blur();
          _this.$emit('complete', _this.input);
        }
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    // 等待dom渲染完成，在执行focus,否则无法获取到焦点
    this.$nextTick(function () {
      _this2.$refs.firstinput.focus();
    });
  }
};