var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _vm._m(0),
    _c("div", { staticClass: "irBWyp" }, [
      _c("div", [
        _c("div", { staticClass: "tabs-nav" }, [
          _c(
            "div",
            {
              staticClass: "tabs-item",
              on: {
                click: function ($event) {
                  return _vm.loginClick(1)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tabs-item-text",
                  style: { color: _vm.tab == 2 ? "#ADADAD" : "#040D2B" },
                },
                [
                  _vm._v("扫码快速登录 "),
                  _c("div", { class: [{ active: _vm.tab == 1 }] }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tabs-item",
              on: {
                click: function ($event) {
                  return _vm.loginClick(2)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tabs-item-text",
                  style: { color: _vm.tab == 2 ? "#040D2B" : "#ADADAD" },
                },
                [
                  _vm._v("账号密码登录 "),
                  _c("div", { class: [{ active: _vm.tab == 2 }] }),
                ]
              ),
            ]
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === 1,
                expression: "tab===1",
              },
            ],
            staticClass: "tab-content",
          },
          [
            _c("div", { staticClass: "alipay-text" }, [
              _vm._v("钉钉扫码快速登录"),
            ]),
            _vm._m(1),
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { id: "login_container" },
            }),
            _vm._m(2),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === 2,
                expression: "tab===2",
              },
            ],
            staticClass: "login-iframe",
          },
          [
            _c(
              "el-form",
              {
                ref: "loginForm",
                attrs: {
                  "label-position": "top",
                  rules: _vm.rules,
                  model: _vm.formLogin,
                  size: "default",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        "prefix-icon": "el-icon-user",
                        placeholder: "请输入用户名",
                      },
                      model: {
                        value: _vm.formLogin.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLogin, "username", $$v)
                        },
                        expression: "formLogin.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "password",
                        "prefix-icon": "el-icon-lock",
                        "show-password": "",
                        placeholder: "请输入密码",
                      },
                      model: {
                        value: _vm.formLogin.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLogin, "password", $$v)
                        },
                        expression: "formLogin.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "captcha" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { type: "text", placeholder: "请输入验证码" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.formLogin.cap,
                          callback: function ($$v) {
                            _vm.$set(_vm.formLogin, "cap", $$v)
                          },
                          expression: "formLogin.cap",
                        },
                      },
                      [
                        _c("template", { slot: "append" }, [
                          _c("img", {
                            staticClass: "login-code",
                            staticStyle: { height: "37px" },
                            attrs: { src: _vm.image_base },
                            on: { click: _vm.getCaptcha },
                          }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.verVisible
                  ? _c("div", { staticClass: "verVisible" }, [
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: { click: _vm.closeVer },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            padding: "20px",
                            "box-sizing": "border-box",
                          },
                        },
                        [
                          _vm.verification.codeUrl
                            ? [
                                _c("el-alert", {
                                  attrs: {
                                    title:
                                      "请绑定谷歌验证器" + _vm.seconds + "s",
                                    type: "warning",
                                    center: "",
                                    "show-icon": "",
                                    closable: false,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "200px",
                                      margin: "10px 0",
                                      position: "relative",
                                    },
                                    on: { click: _vm.refresh },
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: { src: _vm.verification.codeUrl },
                                    }),
                                    _vm.seconds === 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "module-qrcode-expired-cover",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "module-qrcode-expired-icon el-icon-refresh-right",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "module-qrcode-expired-tip",
                                              },
                                              [_vm._v("二维码已失效，点击刷新")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [_vm._v("请输入谷歌双因素验证码")]
                          ),
                          _c("VerificationCode", {
                            attrs: {
                              checkBalloon: _vm.checkBalloon,
                              borderBalloon: _vm.borderBalloon,
                            },
                            on: {
                              complete: _vm.complete,
                              clickVcation: _vm.clickVcation,
                            },
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "button-login",
                    attrs: { size: "default" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("登录")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "third-party-login-wrap" }, [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticClass: "footer-item" }, [
            _c(
              "div",
              {
                staticClass: "ram-login-text",
                staticStyle: { color: "#194DEE" },
                on: { click: _vm.login },
              },
              [_vm._v("返回主账号登陆")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "footer-item" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    width: "300",
                    trigger: "click",
                  },
                  model: {
                    value: _vm.visible,
                    callback: function ($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-place",
                      placeholder: "请输入跳转地址",
                    },
                    model: {
                      value: _vm.redirectUri,
                      callback: function ($$v) {
                        _vm.redirectUri = $$v
                      },
                      expression: "redirectUri",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "right",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.JumpUri },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ram-login-text",
                      staticStyle: { color: "#194DEE" },
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_vm._v("登录至第三方")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marketing-wrapper" }, [
      _c("img", { attrs: { src: "/image/theme/star/1.png" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-icon-item" }, [
      _c("i", { staticClass: "dingding iconfont" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alipay-texts" }, [
      _c("span", [_vm._v("未注册用户在扫描后将为您注册新账号")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }