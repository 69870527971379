import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apisList, updateApis, delApis, addApis, startAndStopApis } from "@/api/system/applicationconfig/channeldata";
export default {
  name: "index",
  data: function data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 定时任务表格数据
      jobList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        name: undefined,
        jobGroup: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        path: [{
          required: true,
          message: "请选择api路径",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择执行策略",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "APis名称不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  props: {
    appId: {
      type: Number,
      default: 0
    }
  },
  created: function created() {
    if (this.appId) {
      this.getList();
    }
  },
  methods: {
    /**
     * 开启关闭
     * @param row
     */handleStatusChange: function handleStatusChange(row) {
      var _this = this;
      var text = row.status === 1 ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.name + '"角色吗？').then(function () {
        return startAndStopApis({
          id: row.id,
          status: row.status
        });
      }).then(function () {
        _this.msgSuccess(text + "成功");
      }).catch(function () {
        row.status = row.status === 0 ? 1 : 0;
      });
    },
    /** 查询渠道数据源*/getList: function getList() {
      var _this2 = this;
      this.loading = true;
      this.queryParams.appId = this.appId;
      apisList(this.queryParams).then(function (response) {
        _this2.jobList = response;
        // this.total = response.total;
        _this2.loading = false;
      });
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        name: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /**
     * 多选框选中数据
     * @param selection
     */handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加Apis";
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = Object.assign({}, row);
      this.open = true;
      this.title = "修改Apis";
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this3 = this;
      this.form.appId = this.appId;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this3.form.id != undefined) {
            updateApis(_this3.form).then(function (response) {
              _this3.msgSuccess("修改成功");
              _this3.open = false;
              _this3.getList();
            });
          } else {
            addApis(_this3.form).then(function (response) {
              _this3.msgSuccess("新增成功");
              _this3.open = false;
              _this3.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this4 = this;
      var jobIds = row.id || this.ids;
      this.$confirm('是否确认删除定时任务编号为"' + jobIds + '"的数据项？').then(function () {
        return delApis({
          id: jobIds
        });
      }).then(function () {
        _this4.getList();
        _this4.msgSuccess("删除成功");
      }).catch(function () {});
    }
  }
};