//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Advertising from "@/views/system/extensionmant/extension/Advertising";
import H5 from "@/views/system/extensionmant/extension/H5";
export default {
  name: "index",
  data: function data() {
    return {
      activeName: "H5"
    };
  },
  components: {
    Advertising: Advertising,
    H5: H5
  }
};