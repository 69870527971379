var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24, xs: 24 } },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSearch,
                      expression: "showSearch",
                    },
                  ],
                  ref: "queryForm",
                  attrs: {
                    model: _vm.queryParams,
                    size: "small",
                    inline: true,
                    "label-width": "68px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名称", prop: "username" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: { placeholder: "请输入用户名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.queryParams.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "username", $$v)
                          },
                          expression: "queryParams.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { placeholder: "用户状态", clearable: "" },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.status,
                            attrs: { label: item.label, value: item.status },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: { click: _vm.resetQuery },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { border: "", data: _vm.userList },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "头像", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-avatar", {
                              attrs: {
                                fit: "cover",
                                size: "large",
                                src: scope.row.avatar_url,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名称",
                      align: "center",
                      prop: "username",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", align: "center", prop: "truename" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "角色", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.forMart(scope.row.roleId)) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "手机号码",
                      align: "center",
                      prop: "phone",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      align: "center",
                      prop: "created_at",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === -1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("冻结"),
                                ])
                              : _vm._e(),
                            scope.row.status === 0
                              ? _c("el-tag", [_vm._v("待审核")])
                              : _vm._e(),
                            scope.row.status === 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("启用"),
                                ])
                              : _vm._e(),
                            scope.row.status === 3
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("未通过"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "备注", align: "center", prop: "remark" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "220px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.userId !== 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [1009],
                                            expression: "[1009]",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-edit",
                                          disabled:
                                            scope.row.status === 1
                                              ? false
                                              : true,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleUpdate(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [1011],
                                            expression: "[1011]",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-s-check",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCheck(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("审核 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [1010],
                                            expression: "[1010]",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleGoogle(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("Google ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [15],
                                            expression: "[15]",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-d-arrow-right",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.details(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("详情 ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户名称", prop: "username" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户名称",
                              maxlength: "30",
                            },
                            model: {
                              value: _vm.form.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "username", $$v)
                              },
                              expression: "form.username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户姓名", prop: "truename" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户姓名",
                              maxlength: "30",
                            },
                            model: {
                              value: _vm.form.truename,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "truename", $$v)
                              },
                              expression: "form.truename",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "phone" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入手机号码",
                              maxlength: "30",
                            },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色", prop: "roleId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择角色" },
                              model: {
                                value: _vm.form.roleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "roleId", $$v)
                                },
                                expression: "form.roleId",
                              },
                            },
                            _vm._l(_vm.roleOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      !_vm.form.id
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "用户密码", prop: "password" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入用户密码",
                                  type: "password",
                                  maxlength: "20",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }