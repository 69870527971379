import request from "@/utils/request";

//官方渠道列表
export function getList(query) {
  return request({
    url: 'admin/inner/official/list',
    method: 'get',
    params: query
  });
}
//创建官方渠道
export function createOfficial(data) {
  return request({
    url: 'admin/inner/official/create',
    method: 'post',
    data: data
  });
}
//修改官方渠道
export function updateOfficial(data) {
  return request({
    url: 'admin/inner/official/update',
    method: 'post',
    data: data
  });
}
//删除官方渠道
export function deleteOfficial(data) {
  return request({
    url: 'admin/inner/official/delete',
    method: 'post',
    data: data
  });
}
//打包apk
export function officialMake(data) {
  return request({
    url: 'admin/inner/official/executeMakeApk',
    method: 'post',
    data: data
  });
}
export function updateAppStore(data) {
  return request({
    url: '/admin/inner/official/updateAppStore',
    method: 'post',
    data: data
  });
}

//应用列表
export function APPlist() {
  return request({
    url: 'admin/inner/official/appList',
    method: 'get'
  });
}