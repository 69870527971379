import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getGroupList, addGroup, updategroup, delGroup } from "@/api/system/applicationconfig/grop";
export default {
  name: "index",
  data: function data() {
    return {
      title: "",
      open: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        route: undefined
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getRowKeys: function getRowKeys(row) {
      return row.name + row.id;
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = Object.assign({}, row);
      this.open = true;
      this.title = "修改";
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.form = {};
      this.open = true;
      this.title = "添加";
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this = this;
      var id = row.id;
      this.$confirm('是否确认删除？').then(function () {
        return delGroup({
          id: id
        });
      }).then(function () {
        _this.getList();
        _this.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this2.form.id != undefined) {
            updategroup(_this2.form).then(function (res) {
              _this2.msgSuccess("修改成功");
              _this2.open = false;
              _this2.getList();
            });
          } else {
            addGroup(_this2.form).then(function (res) {
              _this2.msgSuccess("新增成功");
              _this2.open = false;
              _this2.getList();
            });
          }
        }
      });
    },
    /** 查询分组列表 */getList: function getList() {
      var _this3 = this;
      this.loading = true;
      getGroupList(this.queryParams).then(function (res) {
        _this3.list = res.list;
        _this3.total = res.total;
        _this3.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};