//修改官方渠道
import request from "@/utils/request";

/**
 * 通过用户或App获取渠道数据
 * @param query
 * @returns {*}
 */
export function countByAppAndUser(query) {
  return request({
    url: 'admin/inner/channel/countByAppAndUser',
    method: 'get',
    params: query
  });
}
/**
 * 获取所有用户（员工）
 * @param query
 * @returns {*}
 */
export function staffUser() {
  return request({
    url: 'admin/inner/channel/staffUser',
    method: 'get'
  });
}