import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.link.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getListAll, addChannel, delChannel, detailsChannel, build, consumptiondata, getchannelGroup, UmengData, escalationDetails } from "@/api/system/extensionmant/extension";
import { getappListAll } from "@/api/system/applicationconfig/applist";
import QRCode from 'qrcodejs2';
import { getinfoFlowList } from "@/api/system/extensionmant/flowConfig";
import * as echarts from 'echarts';
import { mapState } from 'vuex';
import { copyLink, parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      copyText: copyLink,
      //渠道分组
      channelSub: [],
      activeName: 'second',
      //渠道code
      channel_code: [],
      //多选
      multipleSelection: [],
      //日期
      pickerOption: {
        shortcuts: [{
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 日期范围
      dateRange: [],
      reportDataRange: [],
      consumptionDateRange: [],
      umengDataRange: [],
      //二维码
      qrCodeVisible: false,
      //切换Android iOS All
      index: 0,
      umindex: 0,
      data_profile: ["All", "Android", "iOS"],
      //appid
      options: [],
      //信息流配置
      option: [],
      //弹框添加修改
      title: "",
      //弹框显示隐藏
      open: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        channelName: "",
        channelGroup: "",
        appId: "",
        channelCode: ""
      },
      // 表单校验
      rules: {
        channelName: [{
          required: true,
          message: "渠道名称不能为空",
          trigger: "blur"
        }],
        channelGroup: [{
          required: true,
          message: "渠道分组不能为空",
          trigger: "blur"
        }],
        appId: [{
          required: true,
          message: "appId不能为空",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }]
      },
      //渠道推广数据显示隐藏
      drawer: false,
      // openInstall 数据
      openInstallData: {},
      dataList: {},
      //渠道名
      channelName: "",
      //友盟图表
      UmengList: {},
      //友盟数据
      UmengCount: {},
      //消费数据
      consumptionCount: {},
      //消费图表
      consumptionList: {},
      //上报数据
      reportData: {
        recharge: {},
        amount: {}
      },
      //上报图表
      reportList: {},
      detilsQuery: {
        excludeDuplication: 0
      },
      chartList: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('d2admin/user', ['info'])), {}, {
    warningMs: function warningMs() {
      return function (e) {
        if (!e.channel_apk_version) {
          return "当前版本未知,建议重新生成apk";
        }
        var data = this.options.filter(function (item) {
          return item.id === e.app_id;
        });
        if (data.length > 0) {
          data[0].channel_apk_version = e.channel_apk_version;
          if (data[0].channel_apk_version === data[0].app_apk_channel_version) {
            return false;
          } else {
            return "\u5F53\u524D\u6E20\u9053\u5305V".concat(data[0].channel_apk_version, " \u6E20\u9053\u57FA\u51C6\u5305V").concat(data[0].app_apk_channel_version, "\u5EFA\u8BAE\u91CD\u65B0\u751F\u6210apk");
          }
        } else {
          return false;
        }
      };
    }
  }),
  created: function created() {
    var _this = this;
    this.getList();
    getappListAll({
      status: 1
    }).then(function (res) {
      _this.options = res.list;
    });
    getinfoFlowList({
      status: 1
    }).then(function (res) {
      _this.option = res.data;
    });
    getchannelGroup().then(function (res) {
      _this.channelSub = res.data;
    });
    var startTime = parseTime(new Date().getTime() - 518400000, "{y}-{m}-{d}");
    var endTime = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    this.umengDataRange[0] = startTime;
    this.umengDataRange[1] = endTime;
    this.dateRange[0] = startTime;
    this.dateRange[1] = endTime;
    this.consumptionDateRange[0] = startTime;
    this.consumptionDateRange[1] = endTime;
    this.reportDataRange[0] = startTime;
    this.reportDataRange[1] = endTime;
  },
  methods: {
    handleClick: function handleClick(tab) {
      if (tab.index == 0) {
        this.$nextTick(function () {
          this.getconsumptiondetils();
        });
      } else if (tab.index == 1) {
        this.$nextTick(function () {
          this.getdetils();
        });
      } else if (tab.index == 2) {
        this.$nextTick(function () {
          this.getUmengDatadetils();
        });
      } else if (tab.index == 3) {
        this.$nextTick(function () {
          this.getescalationDetails();
        });
      }
    },
    /**
     * 打包生成apk
     * @param e
     */buildApp: function buildApp(e) {
      var _this2 = this;
      e.loadingBtn = true;
      build({
        channel_code: e.channel_code
      }).then(function (res) {
        var _res$;
        e.loadingBtn = false;
        e.download_url = (_res$ = res[0]) === null || _res$ === void 0 ? void 0 : _res$.download_url;
        _this2.msgSuccess("生成成功");
        _this2.getList();
      }).catch(function (err) {
        e.loadingBtn = false;
      });
    },
    /**
     * openinstall图表
     */chartShow: function chartShow() {
      var myChart = echarts.init(document.querySelector(".chart"));
      var data = {};
      switch (this.index) {
        case 0:
          data = this.chartList.all;
          break;
        case 1:
          data = this.chartList.android;
          break;
        case 2:
          data = this.chartList.ios;
          break;
      }
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: name
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.date
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '访问量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.visit
        }, {
          name: '点击量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.click
        }, {
          name: '安装数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.install
        }, {
          name: '注册数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.register
        }, {
          name: '平均1日后留存数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.survive_d1
        }, {
          name: '平均7日后留存数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.survive_d7
        }, {
          name: '平均30日后留存数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: data.survive_d30
        }]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    /**
     * 消费数据图表
     */consumptionChartShow: function consumptionChartShow() {
      var myChart = echarts.init(document.querySelector(".consumptionChart"));
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: name
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.consumptionList.date
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '充值人数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.recharge_number
        }, {
          name: '充值金额',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.recharge_amount
        }, {
          name: '首充人数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.first_recharge_number
        }, {
          name: '首充金额',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.first_recharge_amount
        }, {
          name: '消费人数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.consume_number
        }, {
          name: '消费金额',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.consume_amount
        }, {
          name: '退款人数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.refund_number_subsidy
        }, {
          name: '退款金额',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.refund_amount_subsidy
        }, {
          name: '注册人数',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.consumptionList.register_count
        }]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    /**
     * 友盟数据
     */umengChartShow: function umengChartShow() {
      var myChart = echarts.init(document.querySelector(".UmengData"));
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: name
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.UmengList.date
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '每日新增用户',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.UmengList.newUsers
        }, {
          name: '每日活跃用户',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.UmengList.activityUsers
        }, {
          name: '每日累计用户',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.UmengList.totalUsers
        }, {
          name: '每日启动用户',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.UmengList.launches
        }]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    /**
     * 上报数据图表
     */reportChartShow: function reportChartShow() {
      var myChart = echarts.init(document.querySelector(".reportData"));
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: name
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.reportList.date
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '每日注册量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.register
        }, {
          name: 'android安装量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.download.android
        }, {
          name: 'ios安装量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.download.ios
        }, {
          name: '每日充值笔数(有设备标识)',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.recharge_marked
        }, {
          name: '每日充值笔数(无设备标识)',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.recharge_noMark
        }, {
          name: '每日充值金额(有设备标识)',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.amount_marked
        }, {
          name: '每日充值金额(无设备标识)',
          type: 'line',
          stack: 'Total',
          smooth: true,
          showSymbol: false,
          data: this.reportList.amount_noMark
        }]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getdetils();
    },
    changeDateConsumption: function changeDateConsumption() {
      this.getconsumptiondetils();
    },
    changeDateumengDataRange: function changeDateumengDataRange() {
      this.getUmengDatadetils();
    },
    changeDatereportDataRange: function changeDatereportDataRange() {
      this.getescalationDetails();
    },
    /**
     *排除重复
     */excludeRepeat: function excludeRepeat() {
      this.detilsQuery.excludeDuplication === 0 ? this.detilsQuery.excludeDuplication = 1 : this.detilsQuery.excludeDuplication = 0;
      this.getdetils();
    },
    /**
     * 生成二维码
     * @param url
     */qrCodeGeneration: function qrCodeGeneration(url) {
      this.qrCodeVisible = true;
      this.$nextTick(function () {
        document.getElementById("qrCodeUrl").innerHTML = "";
        var qrCodeUrl = new QRCode("qrCodeUrl", {
          width: 200,
          height: 200,
          text: url,
          colorDark: "#409EFF",
          colorLight: "#fff"
        });
      });
    },
    /**
     * 下载链接
     */download: function download(str) {
      window.location.href = str;
    },
    /**
     * 切换应用
     * @param e
     */change: function change(e) {
      this.index = e;
      this.chartShow();
      switch (e) {
        case 0:
          this.dataList = this.openInstallData.all;
          break;
        case 1:
          this.dataList = this.openInstallData.android;
          break;
        case 2:
          this.dataList = this.openInstallData.ios;
          break;
      }
    },
    umChange: function umChange(e) {
      this.umindex = e;
      this.detilsQuery.uKey = e === 0 ? this.detilsQuery.uAndriodKey : this.detilsQuery.uiOSKey;
      this.$nextTick(function () {
        this.getUmengDatadetils();
      });
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加渠道";
      this.form = {};
      this.$set(this.form, "channelGroup", this.info.name);
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this3 = this;
      var id = row.id || this.ids;
      this.$confirm('是否确认删除渠道"' + row.channel_name + '"的数据项？').then(function () {
        return delChannel({
          id: id
        });
      }).then(function () {
        _this3.getList();
        _this3.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /**
     * 详情抽屉 请求接口
     * @param row
     */handleDataView: function handleDataView(row) {
      this.UmengList = {};
      this.UmengCount = {};
      this.openInstallData = {};
      this.dataList = {};
      this.consumptionCount = {};
      this.consumptionList = {};
      this.reportData = {
        recharge: {},
        amount: {}
      };
      this.reportList = {};
      this.activeName = "second";
      this.index = 0;
      this.umindex = 0;
      this.drawer = true;
      this.detilsQuery.channelCode = row.channel_code;
      this.detilsQuery.appId = row.app_id;
      this.channelName = row.channel_name;
      this.detilsQuery.uKey = row.application.uAndriodKey;
      this.detilsQuery.uAndriodKey = row.application.uAndriodKey;
      this.detilsQuery.uiOSKey = row.application.uiOSKey;
      this.getconsumptiondetils();
    },
    /**
     * openinstall数据
     */getdetils: function getdetils() {
      var _this4 = this;
      detailsChannel(this.addDateRange(this.detilsQuery, this.dateRange, "defult")).then(function (res) {
        _this4.openInstallData = res.count;
        _this4.dataList = res.count.all;
        var chartList = {
          all: {
            click: [],
            date: [],
            install: [],
            register: [],
            survive_d1: [],
            survive_d7: [],
            survive_d30: [],
            visit: []
          },
          android: {
            click: [],
            date: [],
            install: [],
            register: [],
            survive_d1: [],
            survive_d7: [],
            survive_d30: [],
            visit: []
          },
          ios: {
            click: [],
            date: [],
            install: [],
            register: [],
            survive_d1: [],
            survive_d7: [],
            survive_d30: [],
            visit: []
          }
        };
        res.list.all.forEach(function (item) {
          chartList.all.click.push(item.click), chartList.all.install.push(item.install), chartList.all.survive_d1.push(item.survive_d1), chartList.all.survive_d7.push(item.survive_d7), chartList.all.survive_d30.push(item.survive_d30), chartList.all.register.push(item.register), chartList.all.visit.push(item.visit), chartList.all.date.push(item.date.substring(0, 10));
        });
        res.list.android.forEach(function (item) {
          chartList.android.click.push(item.click), chartList.android.install.push(item.install), chartList.android.survive_d1.push(item.survive_d1), chartList.android.survive_d7.push(item.survive_d7), chartList.android.survive_d30.push(item.survive_d30), chartList.android.register.push(item.register), chartList.android.visit.push(item.visit), chartList.android.date.push(item.date.substring(0, 10));
        });
        res.list.ios.forEach(function (item) {
          chartList.ios.click.push(item.click), chartList.ios.install.push(item.install), chartList.ios.survive_d1.push(item.survive_d1), chartList.ios.survive_d7.push(item.survive_d7), chartList.ios.survive_d30.push(item.survive_d30), chartList.android.register.push(item.register), chartList.ios.visit.push(item.visit), chartList.ios.date.push(item.date.substring(0, 10));
        });
        _this4.chartList = chartList;
        _this4.chartShow();
      });
    },
    /**
     * 消费数据
     */getconsumptiondetils: function getconsumptiondetils() {
      var _this5 = this;
      consumptiondata(this.addDateRange({
        channelCode: this.detilsQuery.channelCode
      }, this.consumptionDateRange, "defult")).then(function (res) {
        _this5.consumptionCount = res.count;
        _this5.consumptionList = res.list;
        _this5.consumptionChartShow();
      });
    },
    /**
     *友盟数据
     */getUmengDatadetils: function getUmengDatadetils() {
      var _this6 = this;
      UmengData(this.addDateRange({
        appkey: this.detilsQuery.uKey,
        periodType: "daily"
      }, this.umengDataRange, "defult")).then(function (res) {
        _this6.UmengList = res.list;
        _this6.UmengCount = res.count;
        _this6.umengChartShow();
      });
    },
    /**
     * 上报数据
     */getescalationDetails: function getescalationDetails() {
      var _this7 = this;
      escalationDetails(this.addDateRange({
        channelCode: this.detilsQuery.channelCode
      }, this.reportDataRange, "defult")).then(function (res) {
        var amount_marked = [];
        var amount_noMark = [];
        res.list.amount_marked.forEach(function (item) {
          amount_marked.push(item ? item / 1000 : 0);
        });
        res.list.amount_noMark.forEach(function (item) {
          amount_noMark.push(item ? item / 1000 : 0);
        });
        res.list.amount_marked = amount_marked;
        res.list.amount_noMark = amount_noMark;
        _this7.reportList = res.list;
        _this7.reportData = res.count;
        _this7.reportChartShow();
      });
    },
    /** 提交按钮 */submitForm: function submitForm() {
      var _this8 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this8.form.id != undefined) {} else {
            addChannel(_this8.form).then(function (res) {
              _this8.msgSuccess("新增成功");
              _this8.open = false;
              _this8.getList();
            });
          }
        }
      });
    },
    /** 查询渠道列表 */getList: function getList() {
      var _this9 = this;
      this.loading = true;
      getListAll(this.queryParams).then(function (res) {
        if (res.data) {
          res.data.forEach(function (item) {
            item.link = "".concat(location.origin, "/channel?channel_code=").concat(item.channel_code, "&key=").concat(item.application.key, "&type=").concat(item.type);
            item.loadingBtn = false;
          });
          _this9.list = res.data;
          _this9.total = res.total;
        }
        _this9.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};