//修改官方渠道
import request from "@/utils/request";
export function countByAppAndUser(query) {
  return request({
    url: 'admin/inner/application/getAppUserBaseData',
    method: 'get',
    params: query
  });
}
export function appOfficialChannelListByAppId(query) {
  return request({
    url: 'admin/inner/application/appOfficialChannelListByAppId',
    method: 'get',
    params: query
  });
}