var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择应用",
                    filterable: "",
                    "value-key": "id",
                  },
                  on: { change: _vm.changeApp },
                  model: {
                    value: _vm.queryParams.appId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "appId", $$v)
                    },
                    expression: "queryParams.appId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.appName, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道", prop: "channelCode" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择渠道",
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.queryParams.channelCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "channelCode", $$v)
                    },
                    expression: "queryParams.channelCode",
                  },
                },
                _vm._l(_vm.channel, function (item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户", prop: "user_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择用户", filterable: "" },
                  model: {
                    value: _vm.queryParams.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "user_id", $$v)
                    },
                    expression: "queryParams.user_id",
                  },
                },
                _vm._l(_vm.userData, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.username, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "投入成本", prop: "user_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", filterable: "" },
                  model: {
                    value: _vm.queryParams.is_input_costs,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "is_input_costs", $$v)
                    },
                    expression: "queryParams.is_input_costs",
                  },
                },
                _vm._l(_vm.costsList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.lable, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOption,
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-download",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      _vm.htmlToExcel(new Date())
                    },
                  },
                },
                [_vm._v("导出 ")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch, className: _vm.activeName },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "T+1统计数据", name: "consumption" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tables",
                  attrs: {
                    data: _vm.list,
                    border: "",
                    "cell-class-name": _vm.cellClassName,
                    "row-class-name": _vm.tableAddClass,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    id: "consumption",
                  },
                  on: { "header-click": _vm.headerClick },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "时间",
                      align: "center",
                      prop: "date",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "应用/渠道名称",
                      align: "center",
                      prop: "app_name",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.app_name) +
                                "/" +
                                _vm._s(scope.row.channel_name) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "渠道名称",
                      prop: "channel_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "首充金额",
                      prop: "cost_first_recharge_amount",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "首充笔数",
                      prop: "cost_first_recharge_number",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "当日充值金额(月留存)",
                        prop: "cost_before_month_two_day_amount",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "item" }, [
                                _c("div", { staticClass: "item__txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.cost_before_month_two_day_amount
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 当日充值金额(月留存) "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "当月新增用户在当天的充值",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "当日充值金额(老用户)",
                        prop: "cost_before_month_recharge_amount",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "item" }, [
                                _c("div", { staticClass: "item__txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      scope.row
                                        .cost_before_month_recharge_amount
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 当日充值金额(老用户) "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "当月之前所有用户在当天的充值",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "当日充值金额(新用户)",
                        prop: "cost_recharge_amount",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "item" }, [
                                _c("div", { staticClass: "item__txt" }, [
                                  _vm._v(
                                    _vm._s(scope.row.cost_recharge_amount)
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 当日充值金额(新用户) "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "当日注册用户在当天的充值",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "当日总充值",
                        prop: "cost_today_sum_amount",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "item" }, [
                                _c("div", { staticClass: "item__txt" }, [
                                  _vm._v(
                                    _vm._s(scope.row.cost_today_sum_amount)
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 当日总充值 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "所有用户在当日的充值",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "充值笔数",
                      prop: "cost_recharge_number",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "注册人数",
                      prop: "cost_register_count",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "月度新用户充值",
                        prop: "cost_month_amount",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "item" }, [
                                _c("div", { staticClass: "item__txt" }, [
                                  _vm._v(_vm._s(scope.row.cost_month_amount)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 月度新用户充值 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "当月注册的用户在当月的总充值",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "月度老用户充值",
                        prop: "cost_month_retain_recharge_amount",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "item" }, [
                                _c("div", { staticClass: "item__txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      scope.row
                                        .cost_month_retain_recharge_amount
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 月度老用户充值 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "当月之前所有用户在当月之前的充值",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "上报数据", name: "report" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tables",
                  attrs: {
                    data: _vm.list,
                    border: "",
                    "cell-class-name": _vm.cellClassName,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    id: "report",
                  },
                  on: { "header-click": _vm.headerClick },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "时间",
                      align: "center",
                      prop: "date",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名称",
                      align: "center",
                      prop: "username",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "应用名称",
                      align: "center",
                      prop: "app_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道名称",
                      align: "center",
                      prop: "channel_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "充值金额",
                      prop: "upload_amount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.upload_amount === 0
                                    ? 0
                                    : scope.row.upload_amount / 100
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "安装量",
                      prop: "upload_download",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "充值笔数",
                      prop: "upload_recharge",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "注册量",
                      prop: "upload_register",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "openinstall数据", name: "openinstall" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tables",
                  attrs: {
                    data: _vm.list,
                    border: "",
                    "cell-class-name": _vm.cellClassName,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    id: "openinstall",
                  },
                  on: { "header-click": _vm.headerClick },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "时间",
                      align: "center",
                      prop: "date",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "应用名称",
                      align: "center",
                      prop: "app_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "渠道名称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.channel_name) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android点击量",
                      prop: "android_click",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios点击量",
                      prop: "ios_click",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android安装量",
                      prop: "android_install",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios安装量",
                      prop: "ios_install",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android注册量",
                      prop: "android_register",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios注册量",
                      prop: "ios_register",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android1日留存率",
                      prop: "android_survive_d1",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios1日留存率",
                      prop: "ios_survive_d1",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android7日留存率",
                      prop: "android_survive_d7",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios7日留存率",
                      prop: "ios_survive_d7",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android30日留存率",
                      prop: "android_survive_d30",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios30日留存率",
                      prop: "ios_survive_d30",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "android访问量",
                      prop: "android_visit",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "ios访问量",
                      prop: "ios_visit",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }