var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "platform_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.platform_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "platform_name", $$v)
                  },
                  expression: "queryParams.platform_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增 ")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", align: "center", prop: "platform_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === -1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("不可用"),
                        ])
                      : _vm._e(),
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("可用"),
                        ])
                      : _vm._e(),
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleAlignment(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("联调 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "备注", align: "center", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "监测链接",
              align: "center",
              prop: "monitoring_link",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.monitoring_link
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.monitoring_link,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  size: "mini",
                                  type: "success",
                                  plain: "",
                                  id: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyText(
                                      scope.row.monitoring_link
                                    )
                                  },
                                },
                              },
                              [_vm._v("复制 ")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.theLink(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("生成监测链接 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作日期", align: "center", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("div", { staticClass: "elAlert" }, [
            _vm._v(
              " 添加信息流配置后须进行联调才可使用，该操作请联系<凡骄-数据中心>。渠道名称应该以信息流平台开头如（baidu_123）。 "
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称", prop: "platform_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称如(baidu_XXX)" },
                    model: {
                      value: _vm.form.platform_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "platform_name", $$v)
                      },
                      expression: "form.platform_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "akey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入百度营销akey" },
                    model: {
                      value: _vm.form.akey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "akey", $$v)
                      },
                      expression: "form.akey",
                    },
                  }),
                ],
                1
              ),
              _vm.form.id
                ? _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusOption, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: item.label,
                              value: item.value,
                              disabled: item.disabled,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "监测链接",
            width: "35%",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 6 } },
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.changeEquipment },
                              model: {
                                value: _vm.platform.deviceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.platform, "deviceName", $$v)
                                },
                                expression: "platform.deviceName",
                              },
                            },
                            _vm._l(_vm.platList, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.arr, function (item, index) {
            return _c(
              "el-row",
              { key: index, staticClass: "prompt", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 11 } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入参数", disabled: index < 3 },
                      on: { input: _vm.oninputKey },
                      model: {
                        value: item.key,
                        callback: function ($$v) {
                          _vm.$set(item, "key", $$v)
                        },
                        expression: "item.key",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { position: "relative" },
                    attrs: { span: 12 },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入占位符" },
                      on: { input: _vm.oninputKey },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                    _vm.arr.length > 1
                      ? _c("i", {
                          staticClass: "el-icon-remove-outline addImg",
                          on: {
                            click: function ($event) {
                              return _vm.deleteInput(index)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.arr.length - 1 === index
                      ? _c("i", {
                          staticClass: "el-icon-circle-plus-outline addImg",
                          staticStyle: { right: "-30px" },
                          on: { click: _vm.addInput },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.link
            ? _c("el-alert", { attrs: { title: _vm.link, type: "success" } })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "联调", width: "35%", visible: _vm.alignment },
          on: {
            "update:visible": function ($event) {
              _vm.alignment = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.alignmentLoading,
                  expression: "alignmentLoading",
                },
              ],
              staticStyle: { "margin-bottom": "10px" },
            },
            _vm._l(_vm.callback, function (item) {
              return _c("div", [_vm._v(_vm._s(item.callback_url))])
            }),
            0
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "渠道类型", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            _vm._l(_vm.typeList, function (item, key) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.type === "baidu"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "akey", prop: "akey" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入akey" },
                                model: {
                                  value: _vm.form.akey,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "akey", $$v)
                                  },
                                  expression: "form.akey",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.form.type === "other"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "请求方式", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择参数执行方法" },
                                  model: {
                                    value: _vm.form.method,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "method", $$v)
                                    },
                                    expression: "form.method",
                                  },
                                },
                                _vm._l(_vm.methodList, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.alignmentList, function (item, index) {
            return _vm.form.type === "other"
              ? _c(
                  "el-row",
                  { key: index, staticClass: "prompt", attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入上报链接参数" },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择参数执行方法" },
                            model: {
                              value: item.def,
                              callback: function ($$v) {
                                _vm.$set(item, "def", $$v)
                              },
                              expression: "item.def",
                            },
                          },
                          _vm._l(_vm.method, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { position: "relative" },
                        attrs: { span: 8 },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入平台对应参数" },
                          model: {
                            value: item.option,
                            callback: function ($$v) {
                              _vm.$set(item, "option", $$v)
                            },
                            expression: "item.option",
                          },
                        }),
                        _vm.alignmentList.length > 1
                          ? _c("i", {
                              staticClass: "el-icon-remove-outline addImg",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteAmInput(index)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.alignmentList.length - 1 === index
                          ? _c("i", {
                              staticClass: "el-icon-circle-plus-outline addImg",
                              staticStyle: { right: "-30px" },
                              on: { click: _vm.addAmInput },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e()
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "left" },
                  attrs: { type: "primary" },
                  on: { click: _vm.debugg },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.callback.length > 0,
                  },
                  on: { click: _vm.alsubmit },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.alignment = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }