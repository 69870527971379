import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { list } from "@/api/system/log/operlog";
import { listUser } from "@/api/system/user";
import { getopenuser } from "@/api/system/openApi/user";
export default {
  name: "Operlog",
  data: function data() {
    return {
      statusList: [{
        name: 100,
        value: 100
      }, {
        name: 101,
        value: 101
      }, {
        name: 200,
        value: 200
      }, {
        name: 304,
        value: 304
      }, {
        name: 400,
        value: 400
      }, {
        name: 403,
        value: 403
      }, {
        name: 404,
        value: 404
      }, {
        name: 410,
        value: 410
      }, {
        name: 500,
        value: 500
      }],
      routes: [],
      options: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        userId: [],
        route: undefined,
        type: 1
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    /**
     * 获取内部用户
     */
    listUser({
      page: 1,
      size: 999
    }).then(function (res) {
      _this.options = _this.options.concat(res.userList.data);
    });
    /**
     * 获取外部用户列表
     */
    getopenuser().then(function (res) {
      _this.options = _this.options.concat(res.data);
    });
  },
  methods: {
    /** 查询登录日志 */getList: function getList() {
      var _this2 = this;
      this.loading = true;
      list(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (response) {
        _this2.list = response.data.data;
        _this2.total = response.data.total;
        _this2.routes = response.routes;
        _this2.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 多选框选中数据 */handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.operId;
      });
      this.multiple = !selection.length;
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('admin/inner/user/log', _objectSpread(_objectSpread({}, this.queryParams), {}, {
        export: true
      }), "operlog_".concat(new Date().getTime(), ".xlsx"));
    }
  },
  computed: {
    farmat: function farmat() {
      return function (val) {
        for (var prop in this.routes) {
          if (prop === val) {
            return this.routes[prop].desc;
          }
        }
        return val;
      };
    }
  }
};