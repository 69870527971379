import request from '@/utils/request';

//获取外部部路由

export function getUserMenusList() {
  return request({
    url: 'admin/openapi/user/menus',
    method: 'get'
  });
}
//获取内部路由
export function getUserMenusinnerList() {
  return request({
    url: 'admin/inner/user/menus',
    method: 'get'
  });
}

//钉钉登录
export function inddlogin(data) {
  return request({
    url: '/admin/inner/fastLogin',
    method: 'post',
    data: data
  });
}
//注册
export function register(data) {
  return request({
    url: 'admin/openapi/fastLogin',
    method: 'post',
    data: data
  });
}

//外部登录
export function outLogin(data) {
  return request({
    url: 'admin/openapi/login',
    method: 'post',
    data: data
  });
}
//内部登录
export function innerLogin(data) {
  return request({
    url: 'admin/inner/login',
    method: 'post',
    data: data
  });
}
export function googLogin(data) {
  return request({
    url: 'admin/inner/googleLogin',
    method: 'post',
    data: data
  });
}
//获取验证码
export function getCaptcha() {
  return request({
    url: 'admin/common/captcha',
    method: 'get'
  });
}
//绑定谷歌设备
export function getGoogleQrCode(query) {
  return request({
    url: 'admin/inner/getGoogleQrCode',
    method: 'get',
    params: query
  });
}
//刷新token
export function refreshToken() {
  return request({
    url: '/admin/inner/refreshToken',
    method: 'post'
  });
}