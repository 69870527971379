var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      directives: [
        {
          name: "hasPermi",
          rawName: "v-hasPermi",
          value: [1054],
          expression: "[1054]",
        },
      ],
      attrs: { effect: "dark", content: "大屏", placement: "bottom" },
    },
    [
      _c(
        "el-button",
        {
          staticClass: "d2-mr btn-text can-hover",
          attrs: { type: "text" },
          on: { click: _vm.largescreen },
        },
        [
          _c("d2-icon", {
            staticStyle: { "font-size": "16px" },
            attrs: { name: "window-restore" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }