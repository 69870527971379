//
//
//
//
//
//
//
//
//
//

import { getUserDetails } from "@/api/system/openApi/applicationList";
export default {
  name: "persion",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      info: {},
      queryParams: {}
    };
  },
  created: function created() {
    if (this.id) {
      this.queryParams.id = parseInt(this.id);
      this.getList();
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      getUserDetails(this.queryParams).then(function (res) {
        _this.info = res;
      });
    }
  }
};