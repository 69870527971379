import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QRCode from "qrcodejs2";
import { monitor } from "@/api/channel";
export default {
  name: "channel",
  data: function data() {
    return {
      bgUrl: "",
      machineType: ""
    };
  },
  methods: {
    //监听打开该项目的系统
    detectDeviceType: function detectDeviceType() {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = '//web.cdn.openinstall.io/openinstall.js';
      s.addEventListener('load', function () {
        var data = OpenInstall.parseUrlParams();
        new OpenInstall({
          appKey: data.key,
          //appkey参数配置,需要自行替换对应的appkey
          channelCode: data.channel_code,
          onready: function onready() {
            var m = this,
              button = document.getElementById("downloadButton");
            m.schemeWakeup();
            if (data.clickid) {
              monitor({
                service: data.platform_name,
                platform: 'android',
                callback_url: data.clickid
              });
            }
            button.onclick = function () {
              m.wakeupOrInstall();
              return false;
            };
            if (data.type === "1") {
              m.wakeupOrInstall();
              return false;
            }
          }
        }, data);
      }, false);
      document.head.appendChild(s);
    }
  },
  mounted: function mounted() {
    this.detectDeviceType();
  },
  created: function created() {
    var machineType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
    machineType === "Mobile" ? this.bgUrl = require('@/assets/images/channle.png') : this.bgUrl = require('@/assets/images/pcchannle.png');
    this.machineType = machineType;
    this.$nextTick(function () {
      document.getElementById("qrCodeUrl").innerHTML = "";
      var qrCodeUrl = new QRCode("qrCodeUrl", {
        width: 200,
        height: 200,
        text: location.href,
        colorDark: "#000000",
        colorLight: "#fff"
      });
    });
  }
};