import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import html2canvas from "html2canvas";
export default {
  name: "RightToolbar",
  data: function data() {
    return {
      // 显隐数据
      value: [],
      // 弹出层标题
      title: "显示/隐藏",
      // 是否显示弹出层
      open: false
    };
  },
  props: {
    className: {
      type: String,
      default: ""
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array
    }
  },
  created: function created() {
    // 显隐列初始默认隐藏列
    for (var item in this.columns) {
      if (this.columns[item].visible === false) {
        this.value.push(parseInt(item));
      }
    }
  },
  methods: {
    //屏幕截图、保存图片到相册
    handleExport: function handleExport() {
      var htmlDom = document.getElementById(this.className); // 你要截图的元素  可直接是img
      var FileSaver = require("file-saver");
      this.$nextTick(function () {
        html2canvas(htmlDom, {
          scale: 1,
          x: htmlDom.offsetLeft,
          //传入x和y长度，拒绝空白图片
          y: htmlDom.offsetTop,
          allowTaint: false,
          //允许污染
          taintTest: true,
          //在渲染前测试图片
          useCORS: true,
          //使用跨域(当allowTaint为true时这段代码没什么用)
          background: "#fff"
        }).then(function (canvas) {
          // getBase64Image（imgurl）
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(canvas.msToBlob(), "渠道数据.png");
          } else {
            canvas.toBlob(function (blob) {
              FileSaver.saveAs(blob, "渠道数据.png");
            });
          }
        });
      });
    },
    // 搜索
    toggleSearch: function toggleSearch() {
      this.$emit("update:showSearch", !this.showSearch);
    },
    // 刷新
    refresh: function refresh() {
      this.$emit("queryTable");
    },
    // 右侧列表元素变化
    dataChange: function dataChange(data) {
      for (var item in this.columns) {
        var key = this.columns[item].key;
        this.columns[item].visible = !data.includes(key);
      }
    },
    // 打开显隐列dialog
    showColumn: function showColumn() {
      this.open = true;
    }
  }
};