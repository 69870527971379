var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        effect: "dark",
        content: _vm.active ? "退出全屏" : "全屏",
        placement: "bottom",
      },
    },
    [
      _c(
        "el-button",
        {
          staticClass: "d2-mr btn-text can-hover",
          attrs: { type: "text" },
          on: { click: _vm.toggle },
        },
        [
          _vm.active
            ? _c("d2-icon", { attrs: { name: "compress" } })
            : _c("d2-icon", {
                staticStyle: { "font-size": "16px" },
                attrs: { name: "arrows-alt" },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }