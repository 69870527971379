import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import request from "@/utils/request";
import { list } from "@/api/system/log/operlog";
import util from '@/libs/util.js';
import { linksList } from "@/api/system/links";
import { mapState } from "vuex";
use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);
export default {
  name: 'workbench',
  components: {
    VChart: VChart
  },
  provide: _defineProperty({}, THEME_KEY, 'westeros'),
  data: function data() {
    return {
      userInfo: {
        userInfo: {}
      },
      activities: [],
      projects: [],
      navigators: [{
        name: '首页',
        icon: 'home',
        route: {
          name: 'index'
        },
        color: 'rgb(31, 218, 202)'
      }],
      chartData: {
        columns: ['日期', '销售额'],
        rows: [{
          日期: '1月1日',
          销售额: 123
        }, {
          日期: '1月2日',
          销售额: 1223
        }, {
          日期: '1月3日',
          销售额: 2123
        }, {
          日期: '1月4日',
          销售额: 4123
        }, {
          日期: '1月5日',
          销售额: 3123
        }, {
          日期: '1月6日',
          销售额: 7123
        }]
      },
      option: {
        textStyle: {
          fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
        },
        title: {
          text: 'Traffic Sources',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['Direct', 'Email', 'Ad Networks', 'Video Ads', 'Search Engines']
        },
        series: [{
          name: 'Traffic Sources',
          type: 'pie',
          radius: '55%',
          center: ['50%', '60%'],
          data: [{
            value: 335,
            name: 'Direct'
          }, {
            value: 310,
            name: 'Email'
          }, {
            value: 234,
            name: 'Ad Networks'
          }, {
            value: 135,
            name: 'Video Ads'
          }, {
            value: 1548,
            name: 'Search Engines'
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      },
      queryParams: {
        page: 1,
        size: 7
      },
      logList: [],
      loading: false,
      per_page: 10
    };
  },
  created: function created() {
    var _this = this;
    this.type = util.cookies.get('type');
    var arr = [{
      id: 4,
      name: '员工管理',
      icon: 'user',
      route: {
        name: 'user'
      },
      color: 'rgb(225, 133, 37)'
    }, {
      id: 3,
      name: '角色管理',
      icon: 'users',
      route: {
        name: 'role'
      },
      color: 'rgb(191, 12, 44)'
    }, {
      id: 5,
      name: 'openApis管理',
      icon: 'user',
      route: {
        name: 'openApi/user'
      },
      color: 'rgb(63, 178, 127)'
    }, {
      id: 7,
      name: '应用列表',
      icon: 'reorder',
      route: {
        name: 'applicationconfig/applist'
      },
      color: 'rgb(191, 12, 44)'
    }, {
      id: 10,
      name: '日志管理',
      icon: 'database',
      route: {
        name: 'system/log/operlog'
      },
      color: 'rgb(0, 216, 255)'
    }];
    if (this.type === "2") {
      this.navigators = this.navigators.concat(arr);
    } else {
      this.navigators = this.navigators.concat(arr.filter(function (item) {
        return _this.menuData.includes(item.id);
      }));
    }
    if (this.type === "1" || this.type === "2") {
      request({
        url: 'admin/inner/user/info',
        method: 'get'
      }).then(function (res) {
        _this.userInfo = res;
      });
      this.getlog();
      if (this.type === "1") {
        /**
         * 内部用户没有首页连接权限不请求接口
         */
        if (this.menuData.includes(2)) {
          linksList().then(function (res) {
            _this.projects = res.data.data;
          });
        }
      } else {
        linksList().then(function (res) {
          _this.projects = res.data.data;
        });
      }
    }
  },
  methods: {
    getlog: function getlog() {
      var _this2 = this;
      this.loading = true;
      list(this.queryParams).then(function (response) {
        _this2.logList = response.data.data;
        _this2.routes = response.routes;
        _this2.loading = false;
      });
    },
    dateState: function dateState() {
      var date = new Date();
      if (date.getHours() >= 6 && date.getHours() < 12) {
        return "上午好";
      } else if (date.getHours() >= 12 && date.getHours() < 18) {
        return "下午好";
      } else {
        return "晚上好";
      }
    },
    gotoRoute: function gotoRoute(route) {
      this.$router.push(route);
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState("d2admin/user", ["menuData"])), {}, {
    farmat: function farmat() {
      return function (val) {
        for (var prop in this.routes) {
          if (prop === val) {
            return this.routes[prop].desc;
          }
        }
        return val;
      };
    }
  })
};