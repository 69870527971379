var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOption,
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择类型",
                    filterable: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.queryParams.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "type", $$v)
                    },
                    expression: "queryParams.type",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.key, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: { data: _vm.list, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "广告组ID", prop: "adGroupId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "orgId", prop: "orgId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "campaignId", prop: "campaignId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "上报时间", prop: "adGroupId" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.parseTime(scope.row.time)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "点击时间", prop: "clickDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(scope.row.clickDate)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "转换类型",
              prop: "conversionType",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "地区", prop: "countryOrRegion" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "keywordId", prop: "keywordId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "adId", prop: "adId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "idfa", prop: "idfa" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "包名", prop: "pkg" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", prop: "type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.type === "register" ? "注册" : "激活"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.user_idfa ? scope.row.user_idfa.user_id : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }