//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {apisList} from "@/api/outsystem/apis";

export default {
  name: "index",
  data: function data() {
    return {
      apiList: [],
      dateRange: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        name: undefined,
        jobGroup: undefined,
        status: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**
     * 获取引用列表
     */getList: function getList() {
      this.loading = true;
      // apisList(this.addDateRange(this.queryParams)).then(response => {
      //   this.apiList = response.data.list;
      //   this.total = response.data.total;
      //   this.loading = false;
      // });
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        name: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};