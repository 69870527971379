var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-box" }, [
    _c(
      "div",
      {
        class: [
          "input-content",
          _vm.checkBalloon ? "" : "animation",
          _vm.borderBalloon ? "" : "redBorder",
        ],
        on: {
          keydown: _vm.keydown,
          keyup: _vm.keyup,
          paste: _vm.paste,
          mousewheel: _vm.mousewheel,
        },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.input[0],
              expression: "input[0]",
              modifiers: { trim: true, number: true },
            },
          ],
          ref: "firstinput",
          attrs: {
            max: "9",
            min: "0",
            maxlength: "1",
            "data-index": "0",
            oninput: "if(value.length>1)value=value.slice(1)",
            type: "number",
          },
          domProps: { value: _vm.input[0] },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.input, 0, _vm._n($event.target.value.trim()))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.input[1],
              expression: "input[1]",
              modifiers: { trim: true, number: true },
            },
          ],
          attrs: {
            max: "9",
            min: "0",
            maxlength: "1",
            "data-index": "1",
            oninput: "if(value.length>1)value=value.slice(1)",
            type: "number",
          },
          domProps: { value: _vm.input[1] },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.input, 1, _vm._n($event.target.value.trim()))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.input[2],
              expression: "input[2]",
              modifiers: { trim: true, number: true },
            },
          ],
          attrs: {
            max: "9",
            min: "0",
            maxlength: "1",
            "data-index": "2",
            oninput: "if(value.length>1)value=value.slice(1)",
            type: "number",
          },
          domProps: { value: _vm.input[2] },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.input, 2, _vm._n($event.target.value.trim()))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.input[3],
              expression: "input[3]",
              modifiers: { trim: true, number: true },
            },
          ],
          attrs: {
            max: "9",
            min: "0",
            maxlength: "1",
            "data-index": "3",
            oninput: "if(value.length>1)value=value.slice(1)",
            type: "number",
          },
          domProps: { value: _vm.input[3] },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.input, 3, _vm._n($event.target.value.trim()))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.input[4],
              expression: "input[4]",
              modifiers: { trim: true, number: true },
            },
          ],
          attrs: {
            max: "9",
            min: "0",
            maxlength: "1",
            "data-index": "4",
            oninput: "if(value.length>1)value=value.slice(1)",
            type: "number",
          },
          domProps: { value: _vm.input[4] },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.input, 4, _vm._n($event.target.value.trim()))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim.number",
              value: _vm.input[5],
              expression: "input[5]",
              modifiers: { trim: true, number: true },
            },
          ],
          attrs: {
            max: "9",
            min: "0",
            maxlength: "1",
            "data-index": "5",
            oninput: "if(value.length>1)value=value.slice(1)",
            type: "number",
          },
          domProps: { value: _vm.input[5] },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.input, 5, _vm._n($event.target.value.trim()))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }