var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "h5渠道", name: "H5" } },
            [
              _c("el-alert", { attrs: { type: "success", closable: false } }, [
                _c("div", [
                  _vm._v(
                    "生成渠道code相关联的渠道H5推广链接，可通过渠道号打包apk。选择渠道信息流平台数据会归因于广告推广平台， 当前页面可查询渠道T+1数据报表"
                  ),
                ]),
              ]),
              _vm.activeName === "H5" ? _c("H5") : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "广告平台渠道", name: "Advertising" } },
            [
              _c("el-alert", { attrs: { type: "success", closable: false } }, [
                _c("div", [
                  _vm._v(
                    " 对接openinstall（对接多种主流广告平台，提供独立的第三方统计报表，同时为广告平台反馈安装激活事件， 以优化广告的投放效果）生成前请前往openinstall添加广告平台渠道，并与广告平台联调完成即可。 "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://developer.openinstall.io/972053152/app-channel-management",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "https://developer.openinstall.io/972053152/app-channel-management"
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm.activeName === "Advertising" ? _c("Advertising") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }