var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增 ")]
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 1.5 } }, [
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  class: ["btn", _vm.chanleIndex === 1 ? "active" : ""],
                  staticStyle: { "border-right": "0" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deviceSide(1)
                    },
                  },
                },
                [_vm._v("安卓")]
              ),
              _c(
                "button",
                {
                  class: ["btn", _vm.chanleIndex === 2 ? "active" : ""],
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deviceSide(2)
                    },
                  },
                },
                [_vm._v("ios")]
              ),
            ]),
          ]),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.dataList },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "事件ID", align: "center", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "事件名称",
              align: "center",
              prop: "name",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "统计次数",
              align: "center",
              prop: "count",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "显示名称",
              align: "center",
              prop: "displayName",
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "uiOSKey", prop: "uiOSKey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入uiOSKey", disabled: "" },
                    model: {
                      value: _vm.form.uiOSKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "uiOSKey", $$v)
                      },
                      expression: "form.uiOSKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "uAndriodKey", prop: "uAndriodKey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入uAndriodKey", disabled: "" },
                    model: {
                      value: _vm.form.uAndriodKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "uAndriodKey", $$v)
                      },
                      expression: "form.uAndriodKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "事件名", prop: "eventName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入事件名" },
                    model: {
                      value: _vm.form.eventName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "eventName", $$v)
                      },
                      expression: "form.eventName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "事件显示名", prop: "eventDisplayName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入事件显示名" },
                    model: {
                      value: _vm.form.eventDisplayName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "eventDisplayName", $$v)
                      },
                      expression: "form.eventDisplayName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "eventType" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.form.eventType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "eventType", $$v)
                        },
                        expression: "form.eventType",
                      },
                    },
                    [_vm._v("true")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.form.eventType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "eventType", $$v)
                        },
                        expression: "form.eventType",
                      },
                    },
                    [_vm._v("false")]
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" eventType "),
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              " true 计算事件（数值型），用于统计数值型变量的累计值、均值及分布。false 计数事件（字符串型），用于统计字符串型变量的消息数及触发设备数。 "
                            ),
                          ]
                        ),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }