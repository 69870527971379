var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.apiList, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "应用名称",
              align: "center",
              prop: "application.name",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "app_id", align: "center", prop: "app_id" },
          }),
          _c("el-table-column", {
            attrs: { label: "api名称", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "路径", align: "center", prop: "path" },
          }),
          _c("el-table-column", {
            attrs: { label: "备注", align: "center", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "应用数据源", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 1
                      ? _c("div", [_vm._v("渠道数据源")])
                      : scope.row.type === 2
                      ? _c("div", [_vm._v("业务数据源")])
                      : _c("div", [_vm._v("维表数据源")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }