import request from "@/utils/request";
export function getMemberAppList(query) {
  return request({
    url: "admin/inner/userApp/list",
    method: "get",
    params: query
  });
}
export function updateMemberAppStatus(data) {
  return request({
    url: "admin/inner/userApp/updateMemberAppStatus",
    method: "put",
    data: data
  });
}
export function getuserAppList(query) {
  return request({
    url: "admin/inner/userApp/userAppList",
    method: "get",
    params: query
  });
}
export function getUserDetails(query) {
  return request({
    url: "admin/inner/manageUser/getUserDetails",
    method: "get",
    params: query
  });
}