var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用", prop: "appId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择应用",
                    filterable: "",
                    "value-key": "id",
                  },
                  on: { change: _vm.changeApp },
                  model: {
                    value: _vm.queryParams.appId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "appId", $$v)
                    },
                    expression: "queryParams.appId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.appName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "市场渠道", prop: "channel_code" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择市场渠道",
                    filterable: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.queryParams.channel_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "channel_code", $$v)
                    },
                    expression: "queryParams.channel_code",
                  },
                },
                _vm._l(_vm.channel, function (item) {
                  return _c("el-option", {
                    key: item.channel_code,
                    attrs: {
                      label: item.channel_name,
                      value: item.channel_code,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户ID", prop: "user_id" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.queryParams.user_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "user_id", $$v)
                  },
                  expression: "queryParams.user_id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOption,
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: { data: _vm.list, border: "", id: "consumption" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "日期", prop: "create_date" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "包名", prop: "pkg" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "渠道", prop: "channel_code" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户ID", prop: "user_id" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "客服端Ip", prop: "client_ip" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "oaid", prop: "oaid" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "hash_idfa", prop: "hash_idfa" },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }