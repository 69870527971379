export default {
  namespaced: true,
  state: {
    inroute: [{
      id: 1,
      path: '',
      title: '系统管理',
      icon: 'briefcase',
      name: '系统管理',
      sort: 1,
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: null,
      is_btn: false,
      perms: ""
    }, {
      id: 2,
      path: '/links',
      title: '首页链接',
      icon: 'link',
      name: '首页链接',
      sort: 2,
      is_link: false,
      component: 'system/Links',
      component_name: 'links',
      status: true,
      cache: false,
      visible: true,
      parent: 1,
      is_btn: false,
      perms: "/admin/inner/links/list"
    }, {
      id: 3,
      path: '/role',
      title: '角色管理',
      icon: 'users',
      name: '角色管理',
      sort: 3,
      is_link: false,
      component: 'system/role',
      component_name: 'role',
      status: true,
      cache: false,
      visible: true,
      parent: 1,
      is_btn: false,
      perms: "/admin/inner/manageRole/getAdminRoleList"
    }, {
      id: 4,
      path: '/user',
      title: '员工管理',
      icon: 'user',
      name: '员工管理',
      sort: 4,
      is_link: false,
      component: 'system/user',
      component_name: 'user',
      status: true,
      cache: false,
      visible: true,
      parent: 1,
      is_btn: false,
      perms: "/admin/inner/manageMember/getUserList"
    }, {
      id: 5,
      path: '',
      title: 'OpenAPIs管理',
      icon: 'audio-description',
      name: 'OpenAPIs管理',
      sort: 5,
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: null,
      is_btn: false,
      perms: ""
    }, {
      id: 6,
      path: '/openApi/user',
      title: '用户管理',
      icon: 'user',
      name: '用户管理',
      sort: 6,
      is_link: false,
      component: 'system/openApi/user',
      component_name: 'openApi/user',
      status: true,
      cache: false,
      visible: true,
      parent: 5,
      is_btn: false,
      perms: "/admin/inner/manageUser/getUserList"
    }, {
      id: 7,
      path: '/openApi/applicationList',
      title: '应用列表',
      icon: 'reorder',
      name: '应用列表',
      sort: 7,
      is_link: false,
      component: 'system/openApi/applicationList',
      component_name: 'openApi/applicationList',
      status: true,
      cache: false,
      visible: true,
      parent: 5,
      is_btn: false,
      perms: "/admin/inner/userApp/list"
    }, {
      id: 8,
      path: '',
      title: '应用配置',
      icon: 'cubes',
      name: '应用配置',
      sort: 8,
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: null,
      is_btn: false,
      perms: ""
    }, {
      id: 9,
      path: '/application/config',
      title: '应用配置列表',
      icon: 'barcode',
      name: '应用配置列表',
      sort: 8,
      is_link: false,
      component: 'system/applicationconfig/applist',
      component_name: 'applicationconfig/applist',
      status: true,
      cache: false,
      visible: true,
      parent: 8,
      is_btn: false,
      perms: "/admin/inner/application/getApplicationList"
    }, {
      id: 10,
      path: '',
      title: '日志管理',
      icon: 'database',
      name: '日志管理',
      sort: 10,
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: "",
      is_btn: false,
      perms: "/admin/inner/user/log"
    }, {
      id: 11,
      path: '/system/log/logininfor',
      title: '登录日志',
      icon: 'pencil-square-o',
      name: '登录日志',
      sort: 11,
      is_link: false,
      component: 'system/log/logininfor',
      component_name: 'system/log/logininfor',
      status: true,
      cache: false,
      visible: true,
      parent: 10,
      is_btn: false,
      perms: "/admin/inner/user/log"
    }, {
      id: 12,
      path: '/system/log/operlog',
      title: '操作日志',
      icon: 'pencil-square-o',
      name: '操作日志',
      sort: 12,
      is_link: false,
      component: 'system/log/operlog',
      component_name: 'system/log/operlog',
      status: true,
      cache: false,
      visible: true,
      parent: 10,
      is_btn: false,
      perms: "/admin/inner/user/log"
    }, {
      id: 13,
      path: '/applist/details/:appId',
      title: '任务',
      icon: 'barcode',
      name: '任务',
      sort: 0,
      is_link: false,
      component: 'system/applicationconfig/applist/details',
      component_name: 'details',
      status: true,
      cache: false,
      visible: false,
      parent: 9,
      is_btn: false,
      perms: ""
    }, {
      id: 14,
      path: '',
      title: '推广管理',
      icon: "crop",
      name: '推广管理',
      sort: 14,
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: null,
      is_btn: false,
      perms: ""
    }, {
      id: 15,
      path: '/system/detailslog/:id',
      title: '日志详情',
      icon: 'podcast',
      name: '日志详情',
      sort: 0,
      is_link: false,
      component: 'system/detailslog',
      component_name: 'detailslog',
      status: true,
      cache: false,
      visible: false,
      parent: 4,
      is_btn: false,
      perms: "/admin/inner/manageMember/getUserDetails"
    }, {
      id: 99,
      path: '',
      title: '调度管理',
      icon: 'object-ungroup',
      name: '调度管理',
      sort: 99,
      is_link: false,
      component: 'system/dispatching',
      component_name: 'system/dispatching',
      status: true,
      cache: false,
      visible: true,
      parent: "",
      is_btn: false,
      perms: "/admin/inner/tspManage/list"
    }, {
      id: 17,
      path: '/system/dispatching',
      title: '常规任务调度',
      icon: 'object-ungroup',
      name: '常规任务调度',
      sort: 99,
      is_link: false,
      component: 'system/dispatching',
      component_name: 'system/dispatching',
      status: true,
      cache: false,
      visible: true,
      parent: 99,
      is_btn: false,
      perms: "/admin/inner/tspManage/list"
    }, {
      id: 16,
      path: '/user/details/:appId',
      title: '详情',
      icon: 'barcode',
      name: '详情',
      sort: 0,
      is_link: false,
      component: 'system/openApi/user/details',
      component_name: 'userdetails',
      status: true,
      cache: false,
      visible: false,
      parent: 6,
      is_btn: false,
      perms: "/admin/inner/manageUser/getUserDetails"
    }, {
      id: 18,
      path: '/system/mylog',
      title: '我的日志',
      icon: 'podcast',
      name: '我的日志',
      sort: 900,
      is_link: false,
      component: 'system/mylog',
      component_name: 'system/mylog',
      status: true,
      cache: false,
      visible: true,
      parent: "",
      is_btn: false,
      perms: "/admin/inner/user/log"
    }, {
      id: 19,
      path: '/application/appStore',
      title: '应用商店配置',
      icon: 'barcode',
      name: '应用商店配置',
      sort: 9,
      is_link: false,
      component: 'system/applicationconfig/appStore',
      component_name: 'applicationconfig/appStore',
      status: true,
      cache: false,
      visible: true,
      parent: 8,
      is_btn: false,
      perms: "/admin/inner/application/storeConfig/list"
    }, {
      id: 20,
      path: '/system/extensionmant/extension',
      title: '我的渠道',
      icon: "adn",
      name: '我的渠道',
      sort: 15,
      is_link: false,
      component: 'system/extensionmant/extension',
      component_name: 'system/extensionmant/extension',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/channel/listMy"
    }, {
      id: 21,
      path: '/system/extensionmant/flowConfig',
      title: '信息流配置',
      icon: "sitemap",
      name: '信息流配置',
      sort: 16,
      is_link: false,
      component: 'system/extensionmant/flowConfig',
      component_name: 'system/extensionmant/flowConfig',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/channel/infoFlow/list"
    }, {
      id: 22,
      path: '/system/extensionmant/officialChannel',
      title: '官方渠道',
      icon: "institution",
      name: '官方渠道',
      sort: 17,
      is_link: false,
      component: 'system/extensionmant/officialChannel',
      component_name: 'system/extensionmant/officialChannel',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/official/list"
    }, {
      id: 23,
      path: '/system/extensionmant/channelManagement',
      title: '渠道列表',
      icon: 'map',
      name: '渠道列表',
      sort: 18,
      is_link: false,
      component: 'system/extensionmant/channelManagement',
      component_name: 'system/extensionmant/channelManagement',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/channel/list"
    }, {
      id: 24,
      path: '/system/log/exceptionLog',
      title: '渠道上报错误日志',
      icon: 'pencil-square-o',
      name: '渠道上报错误日志',
      sort: 13,
      is_link: false,
      component: 'system/log/exceptionLog',
      component_name: 'system/log/exceptionLog',
      status: true,
      cache: false,
      visible: true,
      parent: 10,
      is_btn: false,
      perms: "/admin/inner/channel/infoFlow/reportErrorLog"
    }, {
      id: 25,
      path: '/applist/umevent/:uAndriodKey/:uiOSKey',
      title: '友盟事件',
      icon: 'barcode',
      name: '友盟事件',
      sort: 0,
      is_link: false,
      component: 'system/applicationconfig/applist/umEvent',
      component_name: 'event',
      status: true,
      cache: false,
      visible: false,
      parent: 9,
      is_btn: false,
      perms: "/admin/inner/application/event/list"
    }, {
      id: 26,
      path: '',
      title: '运营管理',
      icon: 'opera',
      name: '运营管理',
      sort: 19,
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: "",
      is_btn: false,
      perms: ""
    }, {
      id: 27,
      path: '/operationManagement/basicData',
      title: '基础数据',
      icon: 'bar-chart',
      name: '基础数据',
      sort: 20,
      is_link: false,
      component: 'system/operationManagement/basicData',
      component_name: 'basicData',
      status: true,
      cache: false,
      visible: true,
      parent: 26,
      is_btn: false,
      perms: ""
    }, {
      id: 28,
      path: '/operationManagement/eventData',
      title: '事件数据',
      icon: 'bar-chart',
      name: '事件数据',
      sort: 21,
      is_link: false,
      component: 'system/operationManagement/eventData',
      component_name: 'eventData',
      status: true,
      cache: false,
      visible: true,
      parent: 26,
      is_btn: false,
      perms: ""
    }, {
      id: 29,
      path: '/extensionmant/myData',
      title: '我的数据',
      icon: 'barcode',
      name: '我的数据',
      sort: 0,
      is_link: false,
      component: 'system/extensionmant/myData',
      component_name: 'myData',
      status: true,
      cache: false,
      visible: false,
      parent: 20,
      is_btn: false,
      perms: "/admin/inner/channel/countSevenDayByChannel"
    }, {
      id: 30,
      path: '/system/extensionmant/channelDM',
      title: '渠道数据管理',
      icon: 'bar-chart-o',
      name: '渠道数据管理',
      is_link: false,
      component: 'system/extensionmant/channelDM',
      component_name: 'system/extensionmant/channelDM',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/channel/countByAppAndUser"
    }, {
      id: 31,
      path: '/system/log/monitoringAddress',
      title: '监测地址日志',
      icon: 'pencil-square-o',
      name: '监测地址日志',
      is_link: false,
      component: 'system/log/monitoringAddress',
      component_name: 'system/log/monitoringAddress',
      status: true,
      cache: false,
      visible: true,
      parent: 10,
      is_btn: false,
      perms: "/admin/inner/channel/informationMonitorUrlList"
    }, {
      id: 32,
      path: '/applist/ownevent/:appId/:appName',
      title: '自有事件',
      name: '自有事件',
      icon: 'barcode',
      is_link: false,
      component: 'system/applicationconfig/applist/ownEvent',
      component_name: 'ownEvent',
      status: true,
      cache: false,
      visible: false,
      parent: 9,
      is_btn: false,
      perms: "/admin/inner/report/event/list"
    }, {
      id: 33,
      path: '/application/clearingConfig',
      title: '清算类目配置',
      icon: 'barcode',
      name: '清算类目配置',
      sort: 10,
      is_link: false,
      component: 'system/applicationconfig/clearingConfig',
      component_name: 'applicationconfig/clearingConfig',
      status: true,
      cache: false,
      visible: true,
      parent: 8,
      is_btn: false,
      perms: "/admin/inner/application/clearTypeConfig/list"
    }, {
      id: 17,
      path: '/system/taskSchedul',
      title: '分组任务调度',
      icon: 'object-ungroup',
      name: '分组任务调度',
      sort: 100,
      is_link: false,
      component: 'system/taskSchedul',
      component_name: 'system/taskSchedul',
      status: true,
      cache: false,
      visible: true,
      parent: 99,
      is_btn: false,
      perms: "/admin/inner/new_tsp_manage/list"
    }, {
      id: 34,
      path: '/application/group',
      title: '类目分组配置',
      icon: 'barcode',
      name: '类目分组配置',
      sort: 10,
      is_link: false,
      component: 'system/applicationconfig/group',
      component_name: 'applicationconfig/group',
      status: true,
      cache: false,
      visible: true,
      parent: 8,
      is_btn: false,
      perms: "/admin/inner/application/clearTypeConfig/group/list"
    }, {
      id: 35,
      path: '/operationManagement/operatData',
      title: '经营数据',
      icon: 'bar-chart',
      name: '经营数据',
      is_link: false,
      component: 'system/operationManagement/operatData',
      component_name: 'operatData',
      status: true,
      cache: false,
      visible: true,
      parent: 26,
      is_btn: false,
      perms: "/admin/inner/operate/manageData"
    }, {
      id: 36,
      path: '/system/log/informationFlow',
      title: '信息流上报请求日志',
      icon: 'pencil-square-o',
      name: '信息流上报请求日志',
      is_link: false,
      component: 'system/log/informationFlow',
      component_name: 'informationFlow',
      status: true,
      cache: false,
      visible: true,
      parent: 10,
      is_btn: false,
      perms: "/admin/inner/channel/infoFlow/channelRequestLog"
    }, {
      id: 37,
      path: '/system/extensionmant/appliedData',
      title: '应用数据统计',
      icon: 'apple',
      name: '应用数据统计',
      is_link: false,
      component: 'system/extensionmant/appliedData',
      component_name: 'appliedData',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/application/getAppUserBaseData"
    }, {
      id: 38,
      path: '/system/extensionmant/recharge',
      title: '用户充值明细',
      icon: 'money',
      name: '用户充值明细',
      is_link: false,
      component: 'system/extensionmant/recharge',
      component_name: 'recharge',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/application/getAppUserOrderBaseData"
    }, {
      id: 39,
      path: '/system/extensionmant/adReturn',
      title: '应用商店广告回传配置',
      icon: 'sitemap',
      name: '应用商店广告回传配置',
      is_link: false,
      component: 'system/extensionmant/adReturn',
      component_name: 'adReturn',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/official/appStore/list"
    }, {
      id: 40,
      path: '/system/extensionmant/registerData',
      title: '用户注册明细',
      icon: 'registered',
      name: '用户注册明细',
      is_link: false,
      component: 'system/extensionmant/registerData',
      component_name: 'recharge',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/application/getAppUserRegisterData"
    }, {
      id: 41,
      path: '/extensionmant/asa',
      title: 'ASA报表统计',
      icon: 'apple',
      name: 'ASA报表统计',
      is_link: false,
      component: 'system/extensionmant/asa',
      component_name: 'asa',
      status: true,
      cache: false,
      visible: true,
      parent: 14,
      is_btn: false,
      perms: "/admin/inner/channel/appleAds/getCampaigns"
    }, {
      id: 42,
      path: '/extensionmant/asa/AttributionRecord/:campaignId/:keywordId?',
      title: '归因记录',
      icon: 'barcode',
      name: '归因记录',
      sort: 0,
      is_link: false,
      component: 'system/extensionmant/asa/AttributionRecord',
      component_name: 'AttributionRecord',
      status: true,
      cache: false,
      visible: false,
      parent: 41,
      is_btn: false,
      perms: "/admin/inner/channel/appleAds/getAppleAdsReportLogList"
    }],
    // outroute
    // inroute
    outroute: [{
      id: 500,
      path: '',
      title: '应用管理',
      icon: 'cubes',
      name: '应用管理',
      is_link: false,
      component: '',
      component_name: '',
      status: true,
      cache: false,
      visible: true,
      parent: null,
      is_btn: false
    }, {
      id: 501,
      path: '/outsystem/applist',
      title: '用户应用列表',
      icon: 'barcode',
      name: '用户应用列表',
      is_link: false,
      component: 'outsystem/applist',
      component_name: 'applist',
      status: true,
      cache: false,
      visible: true,
      parent: 500,
      is_btn: false
    }, {
      id: 502,
      path: '/system/mylog',
      title: '我的日志',
      icon: 'podcast',
      name: '我的日志',
      is_link: false,
      component: 'system/mylog',
      component_name: 'system/mylog',
      status: true,
      cache: false,
      visible: true,
      parent: "",
      is_btn: false,
      perms: "/admin/inner/user/log"
    }],
    btnprm: [{
      id: 1000,
      perms: "/admin/inner/manageRole/saveAdminRoleInfo",
      name: '新增',
      parent: 3,
      is_btn: true
    }, {
      id: 1001,
      perms: "/admin/inner/manageRole/updateAdminRoleInfo",
      name: '修改',
      parent: 3,
      is_btn: true
    }, {
      id: 1002,
      perms: "/admin/inner/manageRole/resetRoleState",
      name: '修改状态',
      parent: 3,
      is_btn: true
    }, {
      id: 1003,
      perms: "/admin/inner/manageRole/getAdminRoleDetails",
      name: '详情',
      parent: 3,
      is_btn: true
    }, {
      id: 1004,
      perms: "/admin/inner/manageRole/delAdminRole",
      name: '删除',
      parent: 3,
      is_btn: true
    }, {
      id: 1009,
      perms: "/admin/inner/manageMember/saveUserDetails",
      parent: 4,
      name: '修改',
      is_btn: true
    }, {
      id: 1010,
      perms: "/admin/inner/manageMember/deleteUserInfo",
      parent: 4,
      name: '删除',
      is_btn: true
    }, {
      id: 1011,
      perms: "/admin/inner/manageMember/auditUser",
      parent: 4,
      name: '审核',
      is_btn: true
    }, {
      id: 1013,
      perms: "/admin/inner/manageUser/delUser",
      parent: 6,
      name: '删除',
      is_btn: true
    }, {
      id: 1014,
      perms: "/admin/inner/userApp/updateMemberAppStatus",
      parent: 7,
      name: '修改',
      is_btn: true
    }, {
      id: 1015,
      perms: "/api/admin/getApplicationList",
      parent: 7,
      name: '查询',
      is_btn: true
    }, {
      id: 1016,
      perms: "/admin/inner/application/addApplication",
      parent: 9,
      name: '新增',
      is_btn: true
    }, {
      id: 1017,
      perms: "/admin/inner/application/delApplication",
      parent: 9,
      name: '删除',
      is_btn: true
    }, {
      id: 1018,
      perms: "/admin/inner/application/updateApplication",
      parent: 9,
      name: '修改',
      is_btn: true
    }, {
      id: 1019,
      perms: "",
      parent: 9,
      name: '详情按钮',
      is_btn: true
    }, {
      id: 1020,
      perms: "/admin/inner/tsp/job/list",
      parent: 9,
      name: '任务list',
      is_btn: true
    }, {
      id: 1021,
      perms: "/admin/inner/tsp/job/add",
      parent: 9,
      name: '新增任务',
      is_btn: true
    }, {
      id: 1022,
      perms: "/admin/inner/tsp/job/start",
      parent: 9,
      name: '开启任务',
      is_btn: true
    }, {
      id: 1023,
      perms: "/admin/inner/tsp/job/pause",
      parent: 9,
      name: '暂停任务',
      is_btn: true
    }, {
      id: 1024,
      perms: "/admin/inner/tsp/job/remove",
      parent: 9,
      name: '移除任务',
      is_btn: true
    }, {
      id: 1025,
      perms: "/admin/inner/tsp/job/resume",
      parent: 9,
      name: '重启任务',
      is_btn: true
    }, {
      id: 1026,
      perms: "/admin/inner/tsp/hostList",
      parent: 9,
      name: '主机list',
      is_btn: true
    }, {
      id: 1027,
      perms: "/admin/inner/openInstall/openApiList",
      parent: 9,
      name: '渠道List',
      is_btn: true
    }, {
      id: 1028,
      perms: "/admin/inner/tsp/scriptList",
      parent: 9,
      name: '服务列表',
      is_btn: true
    }, {
      id: 1029,
      perms: "/admin/inner/links/save",
      parent: 2,
      name: '添加',
      is_btn: true
    }, {
      id: 1030,
      perms: "/admin/inner/links/update",
      parent: 2,
      name: '修改',
      is_btn: true
    }, {
      id: 1031,
      perms: "/admin/inner/links/del",
      parent: 2,
      name: '删除',
      is_btn: true
    }, {
      id: 1032,
      perms: "/admin/inner/manageUser/saveUser",
      parent: 6,
      name: '添加用户',
      is_btn: true
    }, {
      id: 1033,
      perms: "/admin/inner/manageUser/resetPassword",
      parent: 6,
      name: '重置密码',
      is_btn: true
    }, {
      id: 1034,
      perms: "/admin/inner/userApp/userAppList",
      parent: 6,
      name: '用户列表',
      is_btn: true
    }, {
      id: 1036,
      perms: "/admin/inner/tsp/job/add",
      parent: 17,
      name: '新增任务',
      is_btn: true
    }, {
      id: 1041,
      perms: "/admin/inner/tsp/hostList",
      parent: 17,
      name: '主机list',
      is_btn: true
    }, {
      id: 1042,
      perms: "/admin/inner/openInstall/openApiList",
      parent: 17,
      name: '渠道List',
      is_btn: true
    }, {
      id: 1043,
      perms: "/admin/inner/tsp/scriptList",
      parent: 17,
      name: '服务列表',
      is_btn: true
    }, {
      id: 1044,
      perms: "/admin/inner/channel/add",
      parent: 20,
      name: '添加',
      is_btn: true
    }, {
      id: 1045,
      perms: "/admin/inner/channel/del",
      parent: 20,
      name: '删除',
      is_btn: true
    }, {
      id: 1046,
      perms: "/admin/inner/channel/details",
      parent: 20,
      name: '详情',
      is_btn: true
    }, {
      id: 1047,
      perms: "/admin/inner/channel/executeMakeApk",
      parent: 20,
      name: '生成apk',
      is_btn: true
    }, {
      id: 1048,
      perms: "/admin/inner/application/storeConfig/add",
      parent: 19,
      name: '新增',
      is_btn: true
    }, {
      id: 1049,
      perms: "/admin/inner/application/storeConfig/update",
      parent: 19,
      name: '修改',
      is_btn: true
    }, {
      id: 1050,
      perms: "/admin/inner/application/storeConfig/del",
      parent: 19,
      name: '删除',
      is_btn: true
    }, {
      id: 1051,
      perms: "/admin/inner/application/storeConfig/del",
      parent: 13,
      name: '应用商店爬取添加',
      is_btn: true
    }, {
      id: 1052,
      perms: "/admin/inner/application/storeConfig/del",
      parent: 13,
      name: '应用商店爬取开启',
      is_btn: true
    }, {
      id: 1053,
      perms: "/admin/inner/application/storeConfig/del",
      parent: 13,
      name: '应用商店爬取列表',
      is_btn: true
    }, {
      id: 1054,
      perms: "/admin/common/kanban/view",
      parent: "",
      name: '数据大屏',
      is_btn: true
    }, {
      id: 1055,
      perms: "/admin/inner/application/storeConfig/job/list",
      parent: 9,
      name: '应用配置列表',
      is_btn: true
    }, {
      id: 1056,
      perms: "/admin/inner/application/storeConfig/job/add",
      parent: 9,
      name: '新增爬取任务',
      is_btn: true
    }, {
      id: 1057,
      perms: "/admin/inner/application/storeConfig/job/start",
      parent: 9,
      name: '开启爬取任务',
      is_btn: true
    }, {
      id: 1058,
      perms: "/admin/inner/channel/appList",
      parent: 20,
      name: '应用列表',
      is_btn: true
    }, {
      id: 1059,
      perms: "/admin/inner/tspManage/job/pause",
      parent: 17,
      name: '暂停',
      is_btn: true
    }, {
      id: 1060,
      perms: "/admin/inner/tspManage/job/remove",
      parent: 17,
      name: '删除',
      is_btn: true
    }, {
      id: 1061,
      perms: "/admin/inner/tspManage/job/resume",
      parent: 17,
      name: '恢复',
      is_btn: true
    }, {
      id: 1062,
      perms: "/admin/inner/tspManage/job/start",
      parent: 17,
      name: '开启',
      is_btn: true
    }, {
      id: 1063,
      perms: "/admin/inner/application/storeConfig/storeList",
      parent: 19,
      name: '获取应用商店列表',
      is_btn: true
    }, {
      id: 1064,
      perms: "/admin/inner/tspManage/job/add",
      parent: 17,
      name: '新增',
      is_btn: true
    }, {
      id: 1065,
      perms: "/admin/inner/tsp/scriptList",
      parent: 17,
      name: '脚本列表',
      is_btn: true
    }, {
      id: 1066,
      perms: "/admin/inner/tsp/hostList",
      parent: 17,
      name: '服务器列表',
      is_btn: true
    }, {
      id: 1067,
      perms: "/admin/inner/channel/infoFlow/add",
      parent: 21,
      name: '添加',
      is_btn: true
    }, {
      id: 1068,
      perms: "/admin/inner/channel/infoFlow/del",
      parent: 21,
      name: '删除',
      is_btn: true
    }, {
      id: 1069,
      perms: "/admin/inner/channel/infoFlow/update",
      parent: 21,
      name: '修改',
      is_btn: true
    }, {
      id: 1070,
      perms: "/admin/inner/official/list",
      parent: 22,
      name: '官方渠道列表',
      is_btn: true
    }, {
      id: 1071,
      perms: "/admin/inner/official/create",
      parent: 22,
      name: '新增',
      is_btn: true
    }, {
      id: 1072,
      perms: "/admin/inner/official/update",
      parent: 22,
      name: '修改',
      is_btn: true
    }, {
      id: 1073,
      perms: "/admin/inner/official/delete",
      parent: 22,
      name: '删除',
      is_btn: true
    }, {
      id: 1074,
      perms: "/admin/inner/official/executeMakeApk",
      parent: 22,
      name: '生成apk',
      is_btn: true
    }, {
      id: 1075,
      perms: "/admin/inner/channel/add",
      parent: 23,
      name: '添加',
      is_btn: true
    }, {
      id: 1076,
      perms: "/admin/inner/channel/del",
      parent: 23,
      name: '删除',
      is_btn: true
    }, {
      id: 1077,
      perms: "/admin/inner/channel/details",
      parent: 23,
      name: '详情',
      is_btn: true
    }, {
      id: 1078,
      perms: "/admin/inner/channel/executeMakeApk",
      parent: 23,
      name: '生成apk',
      is_btn: true
    }, {
      id: 1080,
      perms: "/admin/inner/channel/threeDetails",
      parent: 20,
      name: '详情消费数据',
      is_btn: true
    }, {
      id: 1081,
      perms: "/admin/inner/channel/threeDetails",
      parent: 23,
      name: '详情消费数据',
      is_btn: true
    }, {
      id: 1082,
      perms: "/admin/inner/channel/channelGroup",
      parent: 23,
      name: '渠道分组查询',
      is_btn: true
    }, {
      id: 1083,
      perms: "/admin/inner/channel/appList",
      parent: 23,
      name: '应用列表',
      is_btn: true
    }, {
      id: 1084,
      perms: "/admin/inner/application/event/create",
      parent: 25,
      name: '创建事件',
      is_btn: true
    }, {
      id: 1085,
      perms: "/admin/inner/application/app/getSummaryData",
      parent: 23,
      name: '友盟数据',
      is_btn: true
    }, {
      id: 1086,
      perms: "/admin/inner/application/app/getSummaryData",
      parent: 20,
      name: '友盟数据',
      is_btn: true
    }, {
      id: 1087,
      perms: "/admin/inner/operate/realTimeCount",
      parent: 27,
      name: '运营数据',
      is_btn: true
    }, {
      id: 1088,
      perms: "/admin/inner/operate/allChannel",
      parent: 27,
      name: '渠道列表',
      is_btn: true
    }, {
      id: 1090,
      perms: "/admin/inner/channel/appList",
      parent: 28,
      name: '应用列表',
      is_btn: true
    }, {
      id: 1091,
      perms: "/admin/inner/application/event/list",
      parent: 28,
      name: '事件列表',
      is_btn: true
    }, {
      id: 1092,
      perms: "/admin/inner/application/event/getAllDataByEvent",
      parent: 28,
      name: '事件统计数据',
      is_btn: true
    }, {
      id: 1093,
      perms: "/admin/inner/operate/allApp",
      parent: 27,
      name: '应用列表',
      is_btn: true
    }, {
      id: 1094,
      perms: "/admin/inner/channel/escalationDetails",
      parent: 20,
      name: '上报数据',
      is_btn: true
    }, {
      id: 1095,
      perms: "/admin/inner/channel/escalationDetails",
      parent: 23,
      name: '上报数据',
      is_btn: true
    }, {
      id: 1096,
      perms: "/admin/inner/new_tsp_manage/startBatch",
      parent: 17,
      name: '一键开启',
      is_btn: true
    }, {
      id: 1097,
      perms: "/apkMultipartUpload",
      parent: 9,
      name: '分片上传apk',
      is_btn: true
    }, {
      id: 1098,
      perms: "/admin/inner/official/appList",
      parent: 22,
      name: '应用列表',
      is_btn: true
    }, {
      id: 1099,
      perms: "/admin/inner/manageMember/deleteGoogleSecret",
      parent: 4,
      name: '清除谷歌设备',
      is_btn: true
    }, {
      id: 1101,
      perms: "/admin/inner/channel/staffUser",
      parent: 30,
      name: '用户列表',
      is_btn: true
    }, {
      id: 1102,
      perms: "/admin/inner/channel/myAppWithChannelCode",
      parent: 30,
      name: '应用渠道列表',
      is_btn: true
    }, {
      id: 1100,
      perms: "/admin/inner/channel/myAppWithChannelCode",
      parent: 29,
      name: '应用渠道列表',
      is_btn: true
    }, {
      id: 1101,
      perms: "/admin/inner/channel/infoFlow/monitoringLink",
      parent: 21,
      name: '获取检测连接前缀',
      is_btn: true
    }, {
      id: 1102,
      perms: "/admin/inner/channel/infoFlow/debugging",
      parent: 21,
      name: '联调',
      is_btn: true
    }, {
      id: 1103,
      perms: "/admin/inner/channel/saveCost",
      parent: 29,
      name: '设置成本',
      is_btn: true
    }, {
      id: 1105,
      perms: "/admin/inner/report/event/save",
      parent: 32,
      name: '添加事件',
      is_btn: true
    }, {
      id: 1107,
      perms: "/admin/inner/application/applicationSwitch",
      parent: 9,
      name: '停启用',
      is_btn: true
    }, {
      id: 1108,
      perms: "/admin/inner/application/approvedAndCopyBaseApk",
      parent: 9,
      name: '更新apk',
      is_btn: true
    }, {
      id: 1109,
      perms: "/admin/inner/report/event/appEventList",
      parent: 28,
      name: '自有事件应用列表',
      is_btn: true
    }, {
      id: 1110,
      perms: "/admin/inner/report/event/ownEventData",
      parent: 28,
      name: '自有事件统计数据',
      is_btn: true
    }, {
      id: 1111,
      perms: "/admin/inner/application/clearTypeConfig/add",
      parent: 33,
      name: '添加',
      is_btn: true
    }, {
      id: 1112,
      perms: "/admin/inner/application/clearTypeConfig/update",
      parent: 33,
      name: '修改',
      is_btn: true
    }, {
      id: 1113,
      perms: "/admin/inner/application/clearTypeConfig/del",
      parent: 33,
      name: '删除',
      is_btn: true
    }, {
      id: 1114,
      perms: "/admin/inner/new_tsp_manage/list",
      parent: 17,
      name: '任务列表',
      is_btn: true
    }, {
      id: 1115,
      perms: "/admin/inner/new_tsp_manage/add",
      parent: 17,
      name: '添加任务',
      is_btn: true
    }, {
      id: 1116,
      perms: "/admin/inner/new_tsp_manage/addSonJob",
      parent: 17,
      name: '添加子任务',
      is_btn: true
    }, {
      id: 1117,
      perms: "/admin/inner/new_tsp_manage/startJob",
      parent: 17,
      name: '开启任务',
      is_btn: true
    }, {
      id: 1118,
      perms: "/admin/inner/new_tsp_manage/resumeJob",
      parent: 17,
      name: '恢复任务',
      is_btn: true
    }, {
      id: 1119,
      perms: "/admin/inner/new_tsp_manage/removeJob",
      parent: 17,
      name: '删除任务',
      is_btn: true
    }, {
      id: 1120,
      perms: "/admin/inner/new_tsp_manage/pauseJob",
      parent: 17,
      name: '暂停任务',
      is_btn: true
    }, {
      id: 1121,
      perms: "/admin/inner/tsp/hostList",
      parent: 17,
      name: '服务器配置',
      is_btn: true
    }, {
      id: 1122,
      perms: "/admin/inner/tsp/scriptList",
      parent: 17,
      name: '脚本列表',
      is_btn: true
    }, {
      id: 1123,
      perms: "/admin/inner/application/clearTypeConfig/group/list",
      parent: 33,
      name: '分组列表',
      is_btn: true
    }, {
      id: 1124,
      perms: "/admin/inner/application/clearTypeConfig/group/add",
      parent: 34,
      name: '添加分组',
      is_btn: true
    }, {
      id: 1125,
      perms: "/admin/inner/application/clearTypeConfig/group/update",
      parent: 34,
      name: '修改分组',
      is_btn: true
    }, {
      id: 1126,
      perms: "/admin/inner/application/clearTypeConfig/group/del",
      parent: 34,
      name: '删除分组',
      is_btn: true
    }, {
      id: 1127,
      perms: "/admin/inner/new_tsp_manage/stopBatch",
      parent: 17,
      name: '一键停止',
      is_btn: true
    }, {
      id: 1128,
      perms: "/admin/inner/application/apkUpload",
      parent: 9,
      name: '上传apk',
      is_btn: true
    }, {
      id: 1129,
      perms: "/admin/inner/channel/infoFlow/getChannelPlatformList",
      parent: 21,
      name: '第三方推广平台列表',
      is_btn: true
    }, {
      id: 1130,
      perms: "/admin/inner/application/getAppUserBaseData",
      parent: 37,
      name: '应用列表',
      is_btn: true
    }, {
      id: 1131,
      perms: "/admin/inner/application/appOfficialChannelListByAppId",
      parent: 37,
      name: '市场渠道列表',
      is_btn: true
    }, {
      id: 1132,
      perms: "/admin/inner/channel/infoFlow/clearReportErrorLog",
      parent: 24,
      name: '一键清空',
      is_btn: true
    }, {
      id: 1133,
      perms: "/admin/inner/channel/getChannelGroupByUid",
      parent: 20,
      name: '渠道分组',
      is_btn: true
    }, {
      id: 1134,
      perms: "/admin/inner/application/appChannelListByAppId",
      parent: 38,
      name: '渠道列表',
      is_btn: true
    }, {
      id: 1135,
      perms: "/admin/inner/official/appStore/getKeys",
      parent: 39,
      name: '配置key列表',
      is_btn: true
    }, {
      id: 1136,
      perms: "/admin/inner/official/appStore/create",
      parent: 39,
      name: '新增',
      is_btn: true
    }, {
      id: 1137,
      perms: "/admin/inner/official/appStore/update",
      parent: 39,
      name: '修改',
      is_btn: true
    }, {
      id: 1138,
      perms: "/admin/inner/official/appStore/delete",
      parent: 39,
      name: '删除',
      is_btn: true
    }, {
      id: 1139,
      perms: "/admin/inner/official/appStore/getKeys",
      parent: 22,
      name: '配置key列表',
      is_btn: true
    }, {
      id: 1140,
      perms: "/admin/inner/official/updateAppStore",
      parent: 22,
      name: '配置应用商店',
      is_btn: true
    }, {
      id: 1140,
      perms: "/admin/inner/channel/appleAds/getAClsInfo",
      parent: 41,
      name: '配置应用',
      is_btn: true
    }, {
      id: 1140,
      perms: "/admin/inner/channel/appleAds/getSearchTermsList",
      parent: 42,
      name: '搜索词列表',
      is_btn: true
    }]
  },
  mutations: {
    setOutroute: function setOutroute() {},
    setInroute: function setInroute() {},
    setbtnprm: function setbtnprm(state, data) {
      state.btnprm = data;
    }
  }
};