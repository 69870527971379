import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, auditUser, deleteGoogleSecret } from "@/api/system/user";
export default {
  name: "User",
  data: function data() {
    var validatePhone = function validatePhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      //用户状态
      options: [{
        status: -1,
        label: "禁用"
      }, {
        status: 1,
        label: "启用"
      }, {
        status: 0,
        label: "待审核"
      }, {
        status: 3,
        label: "未通过"
      }],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      userList: null,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 默认密码
      initPassword: undefined,
      // 日期范围
      dateRange: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "label"
      },
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        username: undefined,
        status: undefined,
        deptId: undefined
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: "用户名称不能为空",
          trigger: "blur"
        }, {
          min: 2,
          max: 20,
          message: '用户名称长度必须介于 2 和 20 之间',
          trigger: 'blur'
        }],
        truename: [{
          required: true,
          message: "用户姓名不能为空",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "用户密码不能为空",
          trigger: "blur"
        }, {
          min: 5,
          max: 20,
          message: '用户密码长度必须介于 5 和 20 之间',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          validator: validatePhone,
          trigger: 'blur'
        }],
        roleId: [{
          required: true,
          message: "请选择角色",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**
     * 操作日志
     * @param e
     */details: function details(e) {
      this.$router.push("/system/detailslog/" + e.id);
    },
    /**
     * 更多操作触发
     * @param command
     * @param row
     */handleCommand: function handleCommand(command, row) {
      switch (command) {
        case "handleResetPwd":
          this.handleResetPwd(row);
          break;
        default:
          break;
      }
    },
    /** 查询用户列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      listUser(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
        _this.userList = res.userList.data;
        _this.total = res.userList.total;
        _this.roleOptions = res.roleList;
        _this.loading = false;
      });
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        userId: undefined,
        deptId: undefined,
        username: undefined,
        nickName: undefined,
        password: undefined,
        phone: undefined,
        sex: undefined,
        status: "0",
        roleId: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加用户";
      this.form.password = this.initPassword;
    },
    /**
     * 审核操作
     * @param row
     */handleCheck: function handleCheck(row) {
      var _this2 = this;
      var data = {
        uid: row.id
      };
      this.$prompt('此操作将该修用户状态, 请输入备注!', '提示', {
        confirmButtonText: '通过',
        cancelButtonText: '拒绝',
        type: 'warning',
        distinguishCancelAndClose: true,
        beforeClose: function beforeClose(action, instance, done) {
          if (action === 'cancel') {
            data.status = 3;
            data.remark = instance.inputValue;
            auditUser(data).then(function () {
              _this2.$message({
                type: 'info',
                message: '审核不通过'
              });
              done();
              _this2.getList();
            });
          } else if (action === "confirm") {
            data.status = 1;
            data.remark = instance.inputValue;
            auditUser(data).then(function () {
              _this2.$message({
                type: 'success',
                message: '审核通过!'
              });
              done();
              _this2.getList();
            });
          } else {
            done();
          }
        }
      }).catch();
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      var _this3 = this;
      this.reset();
      getUser(row.id).then(function (response) {
        _this3.form = response;
        _this3.open = true;
        _this3.title = "修改用户";
        _this3.form.password = "";
      });
    },
    /** 重置密码按钮操作 */handleResetPwd: function handleResetPwd(row) {
      var _this4 = this;
      this.$prompt('请输入"' + row.username + '"的新密码', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputPattern: /^.{5,20}$/,
        inputErrorMessage: "用户密码长度必须介于 5 和 20 之间"
      }).then(function (_ref) {
        var value = _ref.value;
        resetUserPwd(row.id, value).then(function () {
          _this4.msgSuccess("修改成功，新密码是：" + value);
        });
      }).catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this5 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this5.form.id != undefined) {
            updateUser(_this5.form).then(function () {
              _this5.msgSuccess("修改成功");
              _this5.open = false;
              _this5.getList();
            });
          } else {
            addUser(_this5.form).then(function () {
              _this5.msgSuccess("新增成功");
              _this5.open = false;
              _this5.getList();
            });
          }
        }
      });
    },
    handleGoogle: function handleGoogle(row) {
      var _this6 = this;
      var userIds = row.id;
      this.$confirm('是否确认清除谷歌绑定设备"' + row.username + '？').then(function () {
        return deleteGoogleSecret({
          id: userIds
        });
      }).then(function () {
        _this6.getList();
        _this6.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this7 = this;
      var userIds = row.id || this.ids;
      this.$confirm('是否确认删除用户"' + row.username + '"的数据项？').then(function () {
        return delUser(userIds);
      }).then(function () {
        _this7.getList();
        _this7.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('system/role/export', _objectSpread({}, this.queryParams), "role_".concat(new Date().getTime(), ".xlsx"));
    }
  },
  computed: {
    forMart: function forMart() {
      return function (val) {
        var _data$;
        var data = this.roleOptions.filter(function (item) {
          return item.id === val;
        });
        return (_data$ = data[0]) === null || _data$ === void 0 ? void 0 : _data$.name;
      };
    }
  }
};