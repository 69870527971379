//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { eventList, createEvent } from "@/api/system/applicationconfig/event";
import { parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      chanleIndex: 1,
      dateRange: [],
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 定时任务表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 传入的表达式
      expression: "",
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      },
      // 表单参数
      form: {
        eventType: true
      },
      // 表单校验
      rules: {
        uiOSKey: [{
          required: true,
          message: "请输入uiOSKey",
          trigger: "blur"
        }],
        uAndriodKey: [{
          required: true,
          message: "请输入uAndriodKey",
          trigger: "blur"
        }],
        eventName: [{
          required: true,
          message: "请输入事件名",
          trigger: "blur"
        }],
        eventDisplayName: [{
          required: true,
          message: "请输入事件显示名",
          trigger: "blur"
        }],
        eventType: [{
          required: true,
          message: "请选择eventType",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.dateRange[0] = parseTime(new Date().getTime() - 86400000 * 6, "{y}-{m}-{d}");
    this.dateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    if (this.$route.params.uiOSKey && this.$route.params.uAndriodKey) {
      this.form.uiOSKey = this.$route.params.uiOSKey;
      this.form.uAndriodKey = this.$route.params.uAndriodKey;
      this.queryParams.appkey = this.$route.params.uAndriodKey;
      this.getList();
    }
  },
  methods: {
    /**
     * 切换终端
     * @param e
     */deviceSide: function deviceSide(e) {
      this.chanleIndex = e;
      if (e === 1) {
        this.queryParams.appkey = this.form.uAndriodKey;
      } else if (e === 2) {
        this.queryParams.appkey = this.form.uiOSKey;
      }
      this.queryParams.page = 1;
      this.getList();
    },
    /** 查询事件列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      eventList(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (response) {
        _this.dataList = response.eventInfo;
        _this.total = response.totalPage * 10;
        _this.loading = false;
      });
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.form.appkey = this.queryParams.appkey;
      this.title = "添加事件";
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this2.form.id != undefined) {} else {
            createEvent(_this2.form).then(function (response) {
              _this2.msgSuccess("新增成功");
              _this2.open = false;
              _this2.getList();
            });
          }
        }
      });
    }
  }
};