//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAppleAdsReportLogList } from "@/api/system/extensionmant/asa";
import { parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      options: [{
        key: "注册",
        value: 'register'
      }, {
        key: "激活",
        value: 'activation'
      }],
      total: 0,
      dateRange: [],
      //日期
      pickerOption: {
        disabledDate: function disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近15天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      },
      column: []
    };
  },
  created: function created() {
    this.queryParams.campaignId = this.$route.params.campaignId ? parseInt(this.$route.params.campaignId) : "";
    this.queryParams.keywordId = this.$route.params.keywordId ? parseInt(this.$route.params.keywordId) : "";
    this.dateRange[0] = parseTime(new Date().getTime() - 86400000 * 1, "{y}-{m}-{d}");
    this.dateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    this.getList();
  },
  methods: {
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getList();
    },
    /** 查询 */getList: function getList() {
      var _this = this;
      this.loading = true;
      getAppleAdsReportLogList(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
        _this.list = res.list;
        _this.total = res.total;
        _this.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    }
  }
};