import _toConsumableArray from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _createForOfIteratorHelper from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { uniqueId } from 'lodash';
import { getUserMenusinnerList, getUserMenusList } from '@/api/login';
import XEUtils from 'xe-utils';
import { frameInRoutes } from '@/router/routes';
import store from "@/store";
import util from '@/libs/util.js';
import { compare } from "@/utils/common";
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */
function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
}
export var menuHeader = supplementPath([]);

// 请求菜单数据,用于解析路由和侧边栏菜单
export var getMenu = function getMenu() {
  var sortMenu = store.state.d2admin.routelist.inroute.sort(compare("sort", true));
  var menuData = [];
  var type = util.cookies.get("type");
  if (type === "0") {
    //外部路由
    return getUserMenusList().then(function (res) {
      // 设置动态路由
      menuData = store.state.d2admin.routelist.outroute;
      return JSON.parse(JSON.stringify(menuData));
    });
  } else if (type === "1") {
    //内部路由
    return getUserMenusinnerList().then(function (res) {
      var btnprm = [];
      // 设置动态路由
      menuData = sortMenu.filter(function (item) {
        if (res.menus.includes(item.id)) {
          btnprm.push(item.id);
        }
        return res.menus.includes(item.id);
      });
      store.state.d2admin.routelist.btnprm.map(function (item) {
        if (res.btns.includes(item.id)) {
          btnprm.push(item.id);
        }
      });
      store.commit("d2admin/user/setPermissions", btnprm);
      store.commit("d2admin/user/menuData", res.menus);
      return JSON.parse(JSON.stringify(menuData));
    });
  } else if (type === "2") {
    //超级管理员
    menuData = sortMenu;
    store.commit("d2admin/user/menuData", menuData);
    store.commit("d2admin/user/setPermissions", ["*:*:*"]);
    return new Promise(function (resolve) {
      resolve(JSON.parse(JSON.stringify(menuData)));
    });
  }
};

/**
 * 校验路由是否有效
 */
export var checkRouter = function checkRouter(menuData) {
  var result = [];
  var _iterator = _createForOfIteratorHelper(menuData),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      try {
        if (item.path !== '' && item.component) {
          _import(item.component);
        }
        result.push(item);
      } catch (err) {
        console.log("\u5BFC\u5165\u83DC\u5355\u9519\u8BEF\uFF0C\u4F1A\u5BFC\u81F4\u9875\u9762\u65E0\u6CD5\u8BBF\u95EE\uFF0C\u8BF7\u68C0\u67E5\u6587\u4EF6\u662F\u5426\u5B58\u5728\uFF1A".concat(item.component));
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return result;
};
/**
 * 将获取到的后端菜单数据,解析为前端路由
 */
export var handleRouter = function handleRouter(menuData) {
  var result = [];
  var _iterator2 = _createForOfIteratorHelper(menuData),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var item = _step2.value;
      if (item.path !== '' && item.component) {
        var obj = {
          path: item.path,
          name: item.component_name,
          component: _import(item.component),
          meta: {
            title: item.name,
            auth: true,
            cache: item.cache === 1
          }
        };
        result.push(obj);
      } else {
        if (item.is_link === 0) {
          delete item.path;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  frameInRoutes[0].children = [].concat(result);
  return frameInRoutes;
};

/**
 * 将前端的侧边菜单进行处理
 */
export var handleAsideMenu = function handleAsideMenu(menuData) {
  // 将列表数据转换为树形数据
  var data = XEUtils.toArrayTree(menuData, {
    parentKey: 'parent',
    strict: true
  });
  var menu = [{
    path: '/index',
    title: '首页',
    icon: 'home'
  }].concat(_toConsumableArray(data));
  return supplementPath(menu);
};