var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择应用",
                    filterable: "",
                    "value-key": "id",
                  },
                  on: { change: _vm.changeApp },
                  model: {
                    value: _vm.queryParams.appId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "appId", $$v)
                    },
                    expression: "queryParams.appId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.appName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "市场渠道", prop: "channel_code" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择市场渠道",
                    filterable: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.queryParams.channel_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "channel_code", $$v)
                    },
                    expression: "queryParams.channel_code",
                  },
                },
                _vm._l(_vm.channel, function (item) {
                  return _c("el-option", {
                    key: item.channel_code,
                    attrs: {
                      label: item.channel_name,
                      value: item.channel_code,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备", prop: "app_device" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择市场渠道",
                    filterable: "",
                    clearable: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.queryParams.app_device,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "app_device", $$v)
                    },
                    expression: "queryParams.app_device",
                  },
                },
                _vm._l(_vm.appDeviceList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOption,
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-download",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      _vm.htmlToExcel(new Date())
                    },
                  },
                },
                [_vm._v("导出 ")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: {
            data: _vm.list,
            border: "",
            getSumRol: "",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
            id: "consumption",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "时间",
              prop: "date",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "应用/设备",
              prop: "application.name",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.application.name) +
                        "/ " +
                        _vm._s(scope.row.app_device) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "市场渠道", prop: "channel_code" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "首充金额",
              prop: "first_recharge_amount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "首充笔数",
              prop: "first_recharge_frequency",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "当日充值金额(月留存)",
                prop: "before_month_two_day_amount",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item__txt" }, [
                          _vm._v(_vm._s(scope.row.before_month_two_day_amount)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 当日充值金额(月留存) "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "当月新增用户在当天的充值",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "当日充值金额(老用户)",
                prop: "before_month_recharge_amount",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item__txt" }, [
                          _vm._v(
                            _vm._s(scope.row.before_month_recharge_amount)
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 当日充值金额(老用户) "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "当月之前所有用户在当天的充值",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "当日充值金额(新用户)",
                prop: "recharge_amount",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item__txt" }, [
                          _vm._v(_vm._s(scope.row.recharge_amount)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 当日充值金额(新用户) "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "当日注册用户在当天的充值",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "当日总充值",
                prop: "today_sum_amount",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item__txt" }, [
                          _vm._v(_vm._s(scope.row.today_sum_amount)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 当日总充值 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "所有用户在当日的充值",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "充值笔数",
              prop: "recharge_frequency",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "注册人数",
              prop: "register_count",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "月度新用户充值",
                prop: "month_amount",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item__txt" }, [
                          _vm._v(_vm._s(scope.row.month_amount)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 月度新用户充值 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "当月注册的用户在当月的总充值",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "月度老用户充值",
                prop: "month_retain_recharge_amount",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item__txt" }, [
                          _vm._v(
                            _vm._s(scope.row.month_retain_recharge_amount)
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 月度老用户充值 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "当月之前所有用户在当月之前的充值",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }