import request from "@/utils/request";

/**
 * 上传apk
 */
export function uploadFile(data) {
  return request({
    url: 'admin/inner/application/apkUpload',
    method: 'post',
    data: data
  });
}

/**
 * 查询应用带分页
 * @param query
 * @returns {*}
 */
export function getapplication(query) {
  return request({
    url: "admin/inner/application/getApplicationList",
    method: "get",
    params: query
  });
}

/**
 * 查询全部应用
 * @param data
 * @returns {*}
 */
export function getappListAll(query) {
  return request({
    url: "admin/inner/channel/appList",
    method: "get",
    params: query
  });
}
export function addapplication(data) {
  return request({
    url: "admin/inner/application/addApplication",
    method: "post",
    data: data
  });
}
export function updateapplication(data) {
  return request({
    url: "admin/inner/application/updateApplication",
    method: "put",
    data: data
  });
}
export function delApplication(query) {
  return request({
    url: "admin/inner/application/delApplication",
    method: "delete",
    params: query
  });
}
export function applicationSwitch(data) {
  return request({
    url: "admin/inner/application/applicationSwitch",
    method: "put",
    data: data
  });
}
export function approvedAndCopyBaseApk(data) {
  return request({
    url: "admin/inner/application/approvedAndCopyBaseApk",
    method: "POST",
    data: data
  });
}