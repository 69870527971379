import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAllDataByEvent, getappList, geteventList, getappEventList, ownEventData } from "@/api/system/operationManagement/eventData";
import { parseTime, debounce } from "@/utils/common";
import * as echarts from "echarts";
export default {
  name: "index",
  data: function data() {
    return {
      eventList: [],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          // 禁止选择未来日期（不能选择今天）
          return time.getTime() > Date.now();
        },
        multiple: true,
        checkStrictly: true
      },
      queryFrom: {
        startTime: parseTime(new Date(new Date().setDate(new Date().getDate())), "{y}-{m}-{d}"),
        app_id: "",
        event_id: []
      },
      ownEventList: [],
      eventIndex: 1,
      dataKey: {},
      chanleIndex: 1,
      //时间
      DateRange: [],
      loading: true,
      dataEventList: [1],
      appList: [],
      chanleList: [],
      queryParams: {
        appkey: "",
        page: 1,
        size: 100,
        eventNames: []
      },
      test: debounce(this.getByEvent, 1000)
    };
  },
  created: function created() {
    this.DateRange[0] = parseTime(new Date().getTime() - 518400000, "{y}-{m}-{d}");
    this.DateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    this.getapp();
  },
  methods: {
    handleChange: function handleChange(val) {
      this.eventList = val.events;
      this.queryFrom.app_id = val.id;
      this.queryFrom.event_id = [val.events[0].id];
      this.dataEventList = [];
      this.getownEventData();
    },
    /**
     * 查询
     * @param e
     */getownEventData: function getownEventData() {
      var _this = this;
      if (this.queryFrom.event_id.length > 0 && this.queryFrom.startTime) {
        ownEventData(this.queryFrom).then(function (res) {
          var arr = [];
          for (var key in res.list) {
            if (key !== "hour") {
              arr.push({
                data: res.list[key],
                name: key,
                dates: res.list["hour"],
                time: res.list["hour"][0].slice(0, 10)
              });
            }
          }
          _this.dataEventList = arr;
          _this.renderChart();
        });
      }
    },
    /**
     * 切换事件
     * @param e
     */eventSwitch: function eventSwitch(e) {
      this.eventIndex = e;
      if (e === 1) {
        this.getevent();
      } else if (e === 2) {
        this.getownEvent();
        this.dataEventList = [];
      }
    },
    /**
     * 切换终端
     * @param e
     */deviceSide: function deviceSide(e) {
      this.chanleIndex = e;
      if (e === 1) {
        this.queryParams.appkey = this.dataKey.uAndriodKey;
      } else if (e === 2) {
        this.queryParams.appkey = this.dataKey.uiOSKey;
      }
      this.getevent();
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      if (this.eventIndex === 1) {
        this.getevent();
      } else {
        this.getownEventData();
      }
    },
    /**
     * 切换应用
     * @param e
     */changeApplist: function changeApplist(e) {
      this.queryParams.appkey = this.chanleIndex === 1 ? this.dataKey.uAndriodKey : this.dataKey.uiOSKey;
      this.$set(this.queryParams, "eventNames", []);
      this.dataEventList = [];
      this.getevent();
    },
    /**
     * 切换事件
     */changeEvent: function changeEvent() {
      this.test();
    },
    changeEventOwn: function changeEventOwn() {
      this.getownEventData();
    },
    /**
     * 获取应用列表
     */getapp: function getapp() {
      var _this2 = this;
      getappList().then(function (res) {
        _this2.appList = res.list;
        _this2.queryParams.appkey = res === null || res === void 0 ? void 0 : res.list[0].uAndriodKey;
        _this2.dataKey = res === null || res === void 0 ? void 0 : res.list[0];
        _this2.getevent();
      });
    },
    /**
     * 获取事件列表
     */getevent: function getevent() {
      var _this3 = this;
      geteventList(this.addDateRange(this.queryParams, this.DateRange, "defult")).then(function (res) {
        _this3.chanleList = res.eventInfo;
        _this3.$set(_this3.queryParams, "eventNames", [res === null || res === void 0 ? void 0 : res.eventInfo[0].name]);
        _this3.getByEvent();
      });
    },
    /**
     * 获取自有事件列表
     */getownEvent: function getownEvent() {
      var _this4 = this;
      getappEventList(this.addDateRange(this.queryParams, this.DateRange, "defult")).then(function (res) {
        _this4.ownEventList = res.list;
        _this4.queryFrom.item = res.list[0];
        _this4.queryFrom.app_id = res.list[0].id;
        _this4.queryFrom.event_id = [res.list[0].events[0].id];
        _this4.eventList = res.list[0].events;
        _this4.getownEventData();
      });
    },
    /**
     * 获取事件详情数据
     */getByEvent: function getByEvent() {
      var _this5 = this;
      getAllDataByEvent(this.queryParams).then(function (res) {
        _this5.dataEventList = res;
        _this5.loading = false;
        _this5.renderChart();
      });
    },
    /**
     * 渲染图表
     */renderChart: function renderChart() {
      var that = this;
      this.$nextTick(function () {
        that.dataEventList.forEach(function (item, index) {
          (function () {
            var discount = document.getElementById('discount' + index);
            var name = discount.dataset.name;
            var myChart = echarts.init(discount);
            var option = {
              legend: {
                show: true,
                data: [name],
                bottom: 0,
                left: "center",
                itemWidth: 20,
                itemHeight: 2,
                icon: "rect"
              },
              tooltip: {
                trigger: 'axis',
                backgroundColor: "#000000",
                textStyle: {
                  color: "#fff"
                }
              },
              grid: {
                left: '3%',
                right: '3%',
                bottom: '10%',
                top: "10%",
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: item.dates,
                axisTick: {
                  alignWithLabel: true,
                  lineStyle: {
                    color: "#CCCCCC"
                  }
                }
              },
              yAxis: {
                type: 'value',
                splitLine: {
                  lineStyle: {
                    type: "dashed"
                  }
                }
              },
              series: [{
                data: item.data,
                type: 'line',
                name: name,
                symbol: "circle",
                symbolSize: 6,
                itemStyle: {
                  color: "#5291FF" //线的颜色以及头部显示的颜色
                },

                lineStyle: {
                  width: 1.5
                }
              }]
            };
            option && myChart.setOption(option);
            window.addEventListener("resize", function () {
              myChart.resize();
            });
          })();
          (function () {
            var strip = document.getElementById('strip' + index);
            var name = strip.dataset.name;
            var myChart = echarts.init(strip);
            var option = {
              legend: {
                show: true,
                data: [name],
                bottom: 0,
                left: "center",
                itemWidth: 20,
                itemHeight: 2,
                icon: "rect"
              },
              tooltip: {
                trigger: 'axis',
                backgroundColor: "#000000",
                textStyle: {
                  color: "#fff"
                },
                borderWidth: 0
              },
              grid: {
                left: '3%',
                right: '3%',
                bottom: '10%',
                top: "10%",
                containLabel: true
              },
              xAxis: [{
                type: 'category',
                data: item.dates,
                axisTick: {
                  alignWithLabel: true,
                  lineStyle: {
                    color: "#CCCCCC"
                  }
                }
              }],
              yAxis: [{
                type: 'value'
              }],
              series: [{
                name: name,
                type: 'bar',
                data: item.data,
                itemStyle: {
                  color: "#5291FF" //线的颜色以及头部显示的颜色
                },

                barWidth: "20%"
              }]
            };
            option && myChart.setOption(option);
            window.addEventListener("resize", function () {
              myChart.resize();
            });
          })();
        });
      });
    },
    /**
     * 重置
     */resetQuery: function resetQuery() {
      this.getapp();
      this.eventIndex = 1;
    }
  }
};