//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BusinessData from "@/views/system/applicationconfig/applist/businessdata";
import ChannelData from "@/views/system/applicationconfig/applist/channeldata";
import AppConfiguration from "@/views/system/applicationconfig/applist/appConfiguration";
export default {
  data: function data() {
    return {
      appId: ""
    };
  },
  components: {
    BusinessData: BusinessData,
    ChannelData: ChannelData,
    AppConfiguration: AppConfiguration
  },
  created: function created() {
    if (this.$route.params.appId) {
      this.appId = parseInt(this.$route.params.appId);
    }
  }
};