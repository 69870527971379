var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.queryParams.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "name", $$v)
                  },
                  expression: "queryParams.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: "请选择审核状态", clearable: "" },
                  model: {
                    value: _vm.queryParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "status", $$v)
                    },
                    expression: "queryParams.status",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.status,
                    attrs: { label: item.label, value: item.status },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table",
          attrs: { border: "", data: _vm.appList },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "50", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { type: "selection", width: "50", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "应用名称",
              align: "center",
              prop: "name",
              fixed: "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "应用ID", align: "center", prop: "id", fixed: "" },
          }),
          _c("el-table-column", {
            attrs: { label: "openInstall配置", prop: "apiKey", width: "128" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.apiKey,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.apiKey ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.apiKey ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.apiKey)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.apiKey ? "apiKey" : "apiKey") +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.key,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer", margin: "8px 0" },
                            attrs: {
                              type: scope.row.key ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.key ? false : true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.key ? "appKey" : "appKey") + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.apiSecret,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.apiSecret ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.apiSecret ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.apiSecret)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.apiSecret ? "apiSecret" : "apiSecret"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "友盟配置",
              align: "center",
              prop: "apiKey",
              width: "128",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.uiOSKey,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.uiOSKey ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.uiOSKey ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.uiOSKey)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.uiOSKey ? "uiOSKey" : "uiOSKey"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.uApiKey,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              "margin-top": "8px",
                            },
                            attrs: {
                              type: scope.row.uApiKey ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.uApiKey ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.uApiKey)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.uApiKey ? "uApiKey" : "uApiKey"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.uAndriodKey,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer", margin: "8px 0" },
                            attrs: {
                              type: scope.row.uAndriodKey ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.uAndriodKey ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.uAndriodKey)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.uAndriodKey
                                  ? "uAndriodKey"
                                  : "uAndriodKey"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.apiSecret,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.uApiSecurity ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.uApiSecurity ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.apiSecret)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.uApiSecurity
                                  ? "uApiSecurity"
                                  : "uApiSecurity"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "cos基准包配置",
              align: "center",
              prop: "apiKey",
              width: "182",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.cos_official_dir,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.cos_official_dir
                                ? "success"
                                : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.cos_official_dir
                                ? false
                                : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.cos_official_dir)
                              },
                            },
                          },
                          [_vm._v("官方包路径 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.cos_datum_dir,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              "margin-top": "8px",
                            },
                            attrs: {
                              type: scope.row.cos_datum_dir
                                ? "success"
                                : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.cos_datum_dir ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.cos_datum_dir)
                              },
                            },
                          },
                          [_vm._v("官方基准包路径 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.cos_channel_dir,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer", margin: "8px 0" },
                            attrs: {
                              type: scope.row.cos_channel_dir
                                ? "success"
                                : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.cos_channel_dir
                                ? false
                                : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.cos_channel_dir)
                              },
                            },
                          },
                          [_vm._v("官方渠道包路径 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.cos_channel_base_dir,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.cos_channel_base_dir
                                ? "success"
                                : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.cos_channel_base_dir
                                ? false
                                : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(
                                  scope.row.cos_channel_base_dir
                                )
                              },
                            },
                          },
                          [_vm._v("渠道基准包路径 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            { attrs: { label: "官方基准包（最新版）", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: "apkMD5",
                  align: "center",
                  prop: "app_apk_md5",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "包名",
                  align: "center",
                  prop: "app_apk_name",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "版本",
                  align: "center",
                  prop: "app_apk_version",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { color: "#409EFF" } }, [
                          _vm._v(_vm._s(scope.row.app_apk_version)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "版本信息",
                  align: "center",
                  prop: "app_apk_expand",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "渠道基准包（稳定版）", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: "包名",
                  align: "center",
                  prop: "app_channel_apk_name",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "版本",
                  align: "center",
                  prop: "app_apk_channel_version",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { color: "#5daf34" } }, [
                          _vm._v(_vm._s(scope.row.app_apk_channel_version)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 2 },
                      on: {
                        change: function ($event) {
                          return _vm.handleStatusChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "180",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-wrap": "wrap",
                          "justify-content": "space-between",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "has-permi",
                                rawName: "v-has-permi",
                                value: [1097],
                                expression: "[1097]",
                              },
                            ],
                            ref: "uploadFile",
                            staticClass: "upload-demo",
                            attrs: {
                              action: "*",
                              "http-request": function (file) {
                                return _vm.Uploadfiles(file, scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  icon: "el-icon-upload2",
                                  disabled: scope.row.loadingBtn,
                                  loading: scope.row.loadingBtn,
                                },
                              },
                              [_vm._v("上传 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [25],
                                expression: "[25]",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-d-arrow-right",
                              disabled: scope.row.uKey ? false : true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.eventClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("友盟事件 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [32],
                                expression: "[32]",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-d-arrow-right",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.ownClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("自有事件 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [1019],
                                expression: "[1019]",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-d-arrow-right",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCommand(scope.row)
                              },
                            },
                          },
                          [_vm._v("任务 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [1108],
                                expression: "[1108]",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-refresh",
                              loading: scope.row.updateLoad,
                              disabled: scope.row.app_apk_md5 ? false : true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateApk(scope.row)
                              },
                            },
                          },
                          [_vm._v("更新渠道基准包 ")]
                        ),
                      ],
                      1
                    ),
                    scope.row.loadingBtn
                      ? _c("el-progress", {
                          attrs: { percentage: scope.row.uploadProgress },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            size: "50%",
            "custom-class": "demo-drawer",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "140px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "应用名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入应用名" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "包名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "多个包名用英文;号隔开" },
                        model: {
                          value: _vm.form.source_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "source_name", $$v)
                          },
                          expression: "form.source_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "apiKey", prop: "apiKey" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入openinstall apiKey" },
                        model: {
                          value: _vm.form.apiKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "apiKey", $$v)
                          },
                          expression: "form.apiKey",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" apiKey "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写openinstall对应应用的apikey ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "apiSecret", prop: "apiSecret" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入openinstall apiSecret" },
                        model: {
                          value: _vm.form.apiSecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "apiSecret", $$v)
                          },
                          expression: "form.apiSecret",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" apiSecret "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写openinstall对应应用的appsecret ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "appKey", prop: "key" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入openinstall appKey" },
                        model: {
                          value: _vm.form.key,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "key", $$v)
                          },
                          expression: "form.key",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" appKey "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写openinstall对应应用的appkey ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "uiOSKey", prop: "uiOSKey" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入友盟uiOSKey" },
                        model: {
                          value: _vm.form.uiOSKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uiOSKey", $$v)
                          },
                          expression: "form.uiOSKey",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" uiOSKey "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写友盟对应应用的uiOSKey ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "uAndriodKey", prop: "uAndriodKey" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入友盟uAndriodKey" },
                        model: {
                          value: _vm.form.uAndriodKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uAndriodKey", $$v)
                          },
                          expression: "form.uAndriodKey",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" uAndriodKey "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写友盟对应应用的uAndriodKey ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "uApiKey", prop: "uApiKey" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入友盟uApiKey" },
                        model: {
                          value: _vm.form.uApiKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uApiKey", $$v)
                          },
                          expression: "form.uApiKey",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" uApiKey "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写友盟对应应用的uApiKey ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "uApiSecurity", prop: "uApiSecurity" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入友盟uApiSecurity" },
                        model: {
                          value: _vm.form.uApiSecurity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uApiSecurity", $$v)
                          },
                          expression: "form.uApiSecurity",
                        },
                      }),
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" uApiSecurity "),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(" 请填写友盟对应应用的uApiSecurity ")]
                            ),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "cos官方基准包路径" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入cos官方基准包路径" },
                        model: {
                          value: _vm.form.cos_datum_dir,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cos_datum_dir", $$v)
                          },
                          expression: "form.cos_datum_dir",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "cos渠道基准包路径" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入cos渠道基准包路径" },
                        model: {
                          value: _vm.form.cos_channel_base_dir,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cos_channel_base_dir", $$v)
                          },
                          expression: "form.cos_channel_base_dir",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "cos渠道包路径" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入cos渠道包路径" },
                        model: {
                          value: _vm.form.cos_channel_dir,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cos_channel_dir", $$v)
                          },
                          expression: "form.cos_channel_dir",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "cos官方包路径" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "cos官方包路径" },
                        model: {
                          value: _vm.form.cos_official_dir,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cos_official_dir", $$v)
                          },
                          expression: "form.cos_official_dir",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "demo-drawer__footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }