import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { linksList, uploadFile, delLinks, linksSave, updateLinks } from "@/api/system/links";
export default {
  name: "User",
  data: function data() {
    return {
      hideUploadEdit: false,
      fileList: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      nameList: null,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        link: [{
          required: true,
          message: "链接地址不能为空",
          trigger: "blur"
        }],
        describe: [{
          required: true,
          message: "描述不能为空",
          trigger: "blur"
        }],
        icon: [{
          required: true,
          message: "请上传图标",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 上传之前*/beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif';
      ;
      if (!isJPG) {
        this.$message.error('上传头像图片png/jpg格式!');
      }
      return isJPG;
    },
    /** 删除文件*/handleRemove: function handleRemove(file, fileList) {
      this.form.icon = null;
      this.form.icon ? this.hideUploadEdit = true : this.hideUploadEdit = false;
    },
    /** 上传操作*/Uploadfiles: function Uploadfiles(param) {
      var _this = this;
      var formData = new FormData();
      formData.append('image', param.file);
      uploadFile(formData).then(function (res) {
        _this.msgSuccess("上传成功");
        _this.$set(_this.form, "icon", res.path);
        _this.form.icon ? _this.hideUploadEdit = true : _this.hideUploadEdit = false;
      }).catch(function (err) {
        var uid = param.file.uid; // 关键作用代码，去除文件列表失败文件
        var idx = _this.$refs.uploadFile.uploadFiles.findIndex(function (item) {
          return item.uid === uid;
        }); // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
        _this.$refs.uploadFile.uploadFiles.splice(idx, 1); // 关键作用代码，去除文件列表失败文件
      });
    },
    /** 查询外链列表 */getList: function getList() {
      var _this2 = this;
      this.loading = true;
      linksList(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
        _this2.nameList = res.data.data;
        _this2.total = res.data.total;
        _this2.loading = false;
      });
    },
    /** 取消按钮*/cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**表单重置*/reset: function reset() {
      this.form = {
        describe: undefined,
        icon: undefined,
        link: undefined,
        name: undefined
      };
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.fileList = [];
      this.hideUploadEdit = false;
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = Object.assign({}, row);
      this.form.icon ? this.hideUploadEdit = true : this.hideUploadEdit = false;
      this.open = true;
      this.title = "修改";
      if (this.form.icon) {
        this.fileList = [{
          url: this.form.icon,
          name: this.form.name
        }];
      }
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this3.form.id != undefined) {
            updateLinks(_this3.form).then(function (res) {
              _this3.msgSuccess("修改成功");
              _this3.open = false;
              _this3.getList();
            });
          } else {
            linksSave(_this3.form).then(function (res) {
              _this3.msgSuccess("新增成功");
              _this3.open = false;
              _this3.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this4 = this;
      var userIds = row.id || this.ids;
      this.$confirm('是否确认删除用户"' + row.name + '"的数据项？').then(function () {
        return delLinks(userIds);
      }).then(function () {
        _this4.getList();
        _this4.msgSuccess("删除成功");
      }).catch(function () {});
    }
  }
};