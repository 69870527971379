var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "80px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "渠道名称", prop: "channelName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入渠道名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.channelName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "channelName", $$v)
                  },
                  expression: "queryParams.channelName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用名称", prop: "appId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", filterable: "" },
                  model: {
                    value: _vm.queryParams.appId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "appId", $$v)
                    },
                    expression: "queryParams.appId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道code", prop: "channelCode" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入渠道code", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.channelCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "channelCode", $$v)
                  },
                  expression: "queryParams.channelCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [1044],
                      expression: "[1044]",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [1047],
                      expression: "[1047]",
                    },
                  ],
                  attrs: {
                    plain: "",
                    loading: _vm.oClickPackag,
                    disabled: _vm.multipleSelection.length > 0 ? false : true,
                    size: "mini",
                    type: "success",
                  },
                  on: { click: _vm.generation },
                },
                [_vm._v("一键生成apk ")]
              ),
              _vm.oClickPackag
                ? _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "mini", type: "success" },
                      on: {
                        click: function ($event) {
                          _vm.oClickPackag = !_vm.oClickPackag
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [29],
                      expression: "[29]",
                    },
                  ],
                  attrs: { plain: "", size: "mini", type: "success" },
                  on: { click: _vm.myData },
                },
                [_vm._v("我的数据 ")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "渠道名称", align: "center", prop: "channel_name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.warningMs(scope.row)
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.warningMs(scope.row),
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning",
                              staticStyle: { color: "red" },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(scope.row.channel_name) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "应用名称",
              align: "center",
              prop: "application.name",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "渠道code",
                align: "center",
                prop: "channel_code",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: scope.row.channel_code,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                type: scope.row.channel_code
                                  ? "success"
                                  : "info",
                                size: "mini",
                                plain: "",
                                disabled: scope.row.channel_code ? false : true,
                                id: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(scope.row.channel_code)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.channel_code
                                    ? "点击复制"
                                    : "暂未生成"
                                ) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 渠道code "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        "popper-class": "test",
                        effect: "dark",
                        content:
                          "为每个推广渠道指定的唯一编号，app安装后可通过相应的android/iOS api获取此编号",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "包版本",
              align: "center",
              prop: "channel_apk_version",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.channel_apk_version
                            ? scope.row.channel_apk_version
                            : "未知"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "渠道分组",
              align: "center",
              prop: "channel_group",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: { label: "推广链接", align: "center", width: "130px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: scope.row.link,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    size: "mini",
                                    disabled: scope.row.link ? false : true,
                                    type: scope.row.link ? "success" : "info",
                                    plain: "",
                                    id: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyText(scope.row.link)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.link ? "点击复制" : "暂未生成"
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("img", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              src: require("@/assets/images/qrCode.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.qrCodeGeneration(scope.row.link)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 推广链接 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content:
                          "渠道的推广H5落地页链接，任何通过此链接而来的安装均归因于本渠道，不受平台场景限制，同时可在T+1渠道报表中实时查看统计数据。",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { label: "下载链接", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.download_url,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: scope.row.download_url ? "success" : "info",
                              size: "mini",
                              plain: "",
                              disabled: scope.row.download_url ? false : true,
                              id: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(scope.row.download_url)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.download_url ? "点击复制" : "暂未生成"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "唤起方式", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(scope.row.type === 1 ? "自动触发" : "点击触发")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "信息流平台", align: "center", width: "130px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.info_flow
                      ? _c("el-tag", { attrs: { size: "medium" } }, [
                          _vm._v(_vm._s(scope.row.info_flow.platform_name)),
                        ])
                      : _c("el-tag", { attrs: { size: "medium" } }, [
                          _vm._v(" 无"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "备注",
              align: "center",
              prop: "remark",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作日期", align: "center", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "180px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [1047],
                            expression: "[1047]",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          loading: scope.row.loadingBtn,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.buildApp(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.loadingBtn ? "生成中" : "生成apk"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [1045],
                            expression: "[1045]",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [1046],
                            expression: "[1046]",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-data-line",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDataView(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称", prop: "channelName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入渠道名称" },
                    model: {
                      value: _vm.form.channelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channelName", $$v)
                      },
                      expression: "form.channelName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道code" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入渠道code,不填自动生成" },
                    model: {
                      value: _vm.form.channelCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channelCode", $$v)
                      },
                      expression: "form.channelCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道分组", prop: "channelGroup" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        "allow-create": "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.channelGroup,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channelGroup", $$v)
                        },
                        expression: "form.channelGroup",
                      },
                    },
                    _vm._l(_vm.channelList, function (item) {
                      return _c("el-option", {
                        key: item.channel_group,
                        attrs: {
                          label: item.channel_group,
                          value: item.channel_group,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用ID", prop: "appId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.appId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "appId", $$v)
                        },
                        expression: "form.appId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "信息流配置" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.infoFlowId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "infoFlowId", $$v)
                        },
                        expression: "form.infoFlowId",
                      },
                    },
                    _vm._l(_vm.option, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.platform_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "唤起方式", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("点击触发"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("自动触发"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "渠道推广数据:" + _vm.channelName,
            visible: _vm.drawer,
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "T+1统计数据", name: "second" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item-tabs",
                      attrs: { slot: "label", placement: "top-start" },
                      slot: "label",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("数据来源于TSP-SG内部统计")]
                      ),
                      _c("span", [
                        _vm._v(" T+1统计数据"),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "excludeRet",
                      staticStyle: { "justify-content": "flex-end" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOption,
                        },
                        on: { change: _vm.changeDateConsumption },
                        model: {
                          value: _vm.consumptionDateRange,
                          callback: function ($$v) {
                            _vm.consumptionDateRange = $$v
                          },
                          expression: "consumptionDateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "cardData" },
                    [
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 充值人数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.recharge_number
                                  ? _vm.consumptionCount.recharge_number
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 充值金额 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.recharge_amount
                                  ? _vm.consumptionCount.recharge_amount
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 首充人数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.first_recharge_number
                                  ? _vm.consumptionCount.first_recharge_number
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 首充金额 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.first_recharge_amount
                                  ? _vm.consumptionCount.first_recharge_amount
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 消费人数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.consume_number
                                  ? _vm.consumptionCount.consume_number
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 消费金额 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.consume_amount
                                  ? _vm.consumptionCount.consume_amount
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 退款人数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.refund_number
                                  ? _vm.consumptionCount.refund_number
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 退款金额 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.refund_number
                                  ? _vm.consumptionCount.refund_number
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 注册人数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.consumptionCount.register_count
                                  ? _vm.consumptionCount.register_count
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.activeName === "second"
                    ? _c("div", [
                        _c("div", {
                          staticClass: "consumptionChart chartComm",
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "openinstall数据", name: "first" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item-tabs",
                      attrs: { slot: "label", placement: "top-start" },
                      slot: "label",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("数据来源于openinstall")]
                      ),
                      _c("span", [
                        _vm._v(" openinstall数据"),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ]
                  ),
                  _c("el-alert", {
                    attrs: {
                      title:
                        "提示：该页面的统计数据来自通过本渠道对应的渠道链接/二维码/渠道包带来的App激活数据，不包含非本渠道数据。",
                      type: "success",
                    },
                  }),
                  _c("div", { staticClass: "excludeRet" }, [
                    _c(
                      "ul",
                      { staticClass: "data_profile" },
                      _vm._l(_vm.data_profile, function (item, ind) {
                        return _c(
                          "li",
                          {
                            class:
                              item + " " + (ind === _vm.index ? "active" : ""),
                            attrs: { "data-name": "platform" },
                            on: {
                              click: function ($event) {
                                return _vm.change(ind)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              active: _vm.detilsQuery.excludeDuplication === 1,
                              "overlay-btn": true,
                            },
                            staticStyle: { width: "70px" },
                            on: { click: _vm.excludeRepeat },
                          },
                          [_vm._v("排除重复 ")]
                        ),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "value-format": "yyyy-MM-dd",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "picker-options": _vm.pickerOption,
                          },
                          on: { change: _vm.changeDate },
                          model: {
                            value: _vm.dateRange,
                            callback: function ($$v) {
                              _vm.dateRange = $$v
                            },
                            expression: "dateRange",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cardData" },
                    [
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 访问量 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.drawer ? _vm.dataList.visit : 0) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 点击量 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.drawer ? _vm.dataList.click : 0) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 安装数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.drawer ? _vm.dataList.install : 0) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 注册数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.drawer ? _vm.dataList.register : 0) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 平均1日后留存数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.drawer ? _vm.dataList.survive_d1 : 0) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 平均7日后留存数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.drawer ? _vm.dataList.survive_d7 : 0) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 平均30日后留存数 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.drawer ? _vm.dataList.survive_d30 : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.activeName === "first"
                    ? _c("div", [_c("div", { staticClass: "chart chartComm" })])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "友盟数据", name: "third" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item-tabs",
                      attrs: { slot: "label", placement: "top-start" },
                      slot: "label",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("数据来源于友盟")]
                      ),
                      _c("span", [
                        _vm._v(" 友盟数据"),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "excludeRet" }, [
                    _c(
                      "ul",
                      { staticClass: "data_profile" },
                      _vm._l(
                        _vm.data_profile.slice(1, _vm.data_profile.length),
                        function (item, ind) {
                          return _c(
                            "li",
                            {
                              class:
                                item +
                                " " +
                                (ind === _vm.umindex ? "active" : ""),
                              attrs: { "data-name": "platform" },
                              on: {
                                click: function ($event) {
                                  return _vm.umChange(ind)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "excludeRet",
                        staticStyle: { "justify-content": "flex-end" },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "value-format": "yyyy-MM-dd",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "picker-options": _vm.pickerOption,
                          },
                          on: { change: _vm.changeDateumengDataRange },
                          model: {
                            value: _vm.umengDataRange,
                            callback: function ($$v) {
                              _vm.umengDataRange = $$v
                            },
                            expression: "umengDataRange",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cardData" },
                    [
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 新增用户 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.UmengCount.newUsers
                                  ? _vm.UmengCount.newUsers
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 活跃用户 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.UmengCount.activityUsers
                                  ? _vm.UmengCount.activityUsers
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 累计用户 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.UmengCount.totalUsers
                                  ? _vm.UmengCount.totalUsers
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 启动用户 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.UmengCount.launches
                                  ? _vm.UmengCount.launches
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 1日留存率 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.UmengCount.one ? _vm.UmengCount.one : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 7日留存率 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.UmengCount.seven ? _vm.UmengCount.seven : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.activeName === "third"
                    ? _c("div", [
                        _c("div", { staticClass: "UmengData chartComm" }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "上报数据", name: "fourth" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item-tabs",
                      attrs: { slot: "label", placement: "top-start" },
                      slot: "label",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("数据来源于OH上报提交")]
                      ),
                      _c("span", [
                        _vm._v(" 上报数据"),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "excludeRet",
                      staticStyle: { "justify-content": "flex-end" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOption,
                        },
                        on: { change: _vm.changeDatereportDataRange },
                        model: {
                          value: _vm.reportDataRange,
                          callback: function ($$v) {
                            _vm.reportDataRange = $$v
                          },
                          expression: "reportDataRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "cardData" },
                    [
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 注册量 "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reportData.register
                                  ? _vm.reportData.register
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 安装量(android/ios) "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reportData.download
                                  ? _vm.reportData.download.android
                                  : 0
                              ) +
                              "/ " +
                              _vm._s(
                                _vm.reportData.download
                                  ? _vm.reportData.download.ios
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 未识别渠道安装量(android/ios) "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reportData.download
                                  ? _vm.reportData.download.unknown_android
                                  : 0
                              ) +
                              "/ " +
                              _vm._s(
                                _vm.reportData.download
                                  ? _vm.reportData.download.unknown_ios
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 充值笔数(有/无设备标识) "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reportData.recharge.marked
                                  ? _vm.reportData.recharge.marked
                                  : 0
                              ) +
                              " / " +
                              _vm._s(
                                _vm.reportData.recharge.noMark
                                  ? _vm.reportData.recharge.noMark
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("el-card", { staticClass: "box-card" }, [
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-view",
                            staticStyle: { color: "#409eff" },
                          }),
                          _vm._v(" 充值金额(有/无设备标识) "),
                        ]),
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reportData.amount.marked
                                  ? _vm.reportData.amount.marked / 100
                                  : 0
                              ) +
                              "/ " +
                              _vm._s(
                                _vm.reportData.amount.noMark
                                  ? _vm.reportData.amount.noMark / 100
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.activeName === "fourth"
                    ? _c("div", [
                        _c("div", { staticClass: "reportData chartComm" }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邀请二维码",
            visible: _vm.qrCodeVisible,
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.qrCodeVisible = $event
            },
          },
        },
        [
          _c("div", {
            staticStyle: { width: "200px", margin: "auto" },
            attrs: { id: "qrCodeUrl" },
          }),
        ]
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }