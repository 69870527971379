//
//
//
//
//
//
//
//
//

import util from "@/libs/util";
import { kanbanView } from "@/api/kanban";
export default {
  methods: {
    // dev.dlvc.3yakj.com
    // dlvc.3yakj.com
    largescreen: function largescreen() {
      kanbanView().then(function (res) {
        var token = util.cookies.get('token');
        if (location.host === "dmp.3yakj.com") {
          window.open("https://dlvc.3yakj.com/?token=" + token);
        } else {
          window.open("https://dev.dlvc.3yakj.com/?token=" + token);
          // window.open("http://dev-dlv.3yakj.com/?token="+token)
        }
      });
    }
  }
};