import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getclearTypeConf, addType, updateType, delType } from "@/api/system/applicationconfig/clearingConfig";
import { getGroupList } from "@/api/system/applicationconfig/grop";
export default {
  name: "index",
  data: function data() {
    return {
      options: [],
      title: "",
      open: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        route: undefined
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        reference_type: [{
          required: true,
          message: "reference_type不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    getGroupList({
      page: 1,
      size: 99
    }).then(function (res) {
      _this.options = res.list;
      _this.loading = false;
    });
  },
  methods: {
    /**表格合并单元格 */objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      // 判断哪一列 如果需要处理多了 使用 || 进行处理即可
      if (columnIndex === 0) {
        var _row = this.flitterData(this.list).one[rowIndex];
        var _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    /**合并表格的第一列，处理表格数据 */flitterData: function flitterData(arr) {
      var spanOneArr = [];
      var concatOne = 0;
      arr.forEach(function (item, index) {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          //注意这里的data是表格绑定的字段，根据自己的需求来改
          if (item.name === arr[index - 1].name) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr
      };
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = Object.assign({}, row);
      this.open = true;
      this.title = "修改";
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.form = {};
      this.open = true;
      this.title = "添加";
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this2 = this;
      var id = row.id;
      this.$confirm('是否确认删除？').then(function () {
        return delType({
          id: id
        });
      }).then(function () {
        _this2.getList();
        _this2.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this3.form.id != undefined) {
            updateType(_this3.form).then(function (res) {
              _this3.msgSuccess("修改成功");
              _this3.open = false;
              _this3.getList();
            });
          } else {
            addType(_this3.form).then(function (res) {
              _this3.msgSuccess("新增成功");
              _this3.open = false;
              _this3.getList();
            });
          }
        }
      });
    },
    /** 查询应用商店列表 */getList: function getList() {
      var _this4 = this;
      this.loading = true;
      getclearTypeConf(this.queryParams).then(function (res) {
        _this4.list = res.list;
        _this4.total = res.total;
        _this4.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};