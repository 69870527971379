import { refreshToken } from "@/api/login";
import util from "@/libs/util";
export function getrefreshToken() {
  var time = util.cookies.get("expiresIn");
  var timer = setInterval(function () {
    time -= 1;
    if (time < 1000) {
      util.cookies.set('token', util.cookies.get("refreshToken"));
      refreshToken().then(function (res) {
        var access_token = res.access_token,
          refresh_token = res.refresh_token;
        util.cookies.set('token', access_token);
        util.cookies.set('refreshToken', refresh_token);
        clearInterval(timer);
        getrefreshToken();
      }).catch(clearInterval(timer));
    }
  }, 100000);
}