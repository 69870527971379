import request from '@/utils/request';

//查询运营数据
export function getrealTimeCount(query) {
  return request({
    url: 'admin/inner/operate/realTimeCount',
    method: 'get',
    params: query
  });
}
//查询渠道
export function getListAll(query) {
  return request({
    url: 'admin/inner/operate/allChannel',
    method: 'get',
    params: query
  });
}
//查询应用
export function getAllApp(query) {
  return request({
    url: 'admin/inner/operate/allApp',
    method: 'get',
    params: query
  });
}