import request from "@/utils/request";
export function getEventList(query) {
  return request({
    url: 'admin/inner/report/event/list',
    method: 'get',
    params: query
  });
}
export function eventSave(data) {
  return request({
    url: 'admin/inner/report/event/save',
    method: 'post',
    data: data
  });
}
export function updateStatus(data) {
  return request({
    url: 'admin/inner/report/event/updateStatus',
    method: 'post',
    data: data
  });
}