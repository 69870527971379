import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getinappStorelist, createAppStore, deleteAppStore, updateAppStore, getKeysAppStore, generateMonitoringLink } from "@/api/system/extensionmant/adReturn";
import { deepClone, copyLink } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      keyList: {},
      method: [],
      copyText: copyLink,
      link: "",
      linkUrl: "",
      platform: {
        id: ""
      },
      platList: ["ios", "android"],
      arr: [],
      dialogVisible: false,
      statusOption: [{
        value: 1,
        label: '开启'
      }, {
        value: 2,
        label: '禁用'
      }],
      title: "",
      open: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {
        arr: []
      },
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        route: undefined
      },
      // 表单校验
      rules: {
        name: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        key: [{
          required: true,
          message: "名称不能为空",
          trigger: "blur"
        }],
        callback_url: [{
          required: true,
          message: "回传地址不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注不能为空",
          trigger: "blur"
        }]
      },
      openInstallData: {},
      dataList: {}
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    getKeysAppStore().then(function (res) {
      _this.keyList = res;
    });
  },
  methods: {
    oninputUrl: function oninputUrl() {
      var str = "";
      this.form.arr.forEach(function (item) {
        str += "&".concat(item.key, "=").concat(item.value);
      });
      this.linkUrl = this.host + str;
    },
    oninputKey: function oninputKey() {
      var obj = {};
      this.form.arr.forEach(function (item) {
        obj[item.key] = item.value;
      });
      this.link = obj;
    },
    deleteInput: function deleteInput(index) {
      this.form.arr.splice(index, 1);
      this.oninputKey();
    },
    addInput: function addInput() {
      this.form.arr.push({
        key: "",
        value: ""
      });
      this.$forceUpdate();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
      this.form = {
        arr: [{}]
      };
      this.link = "";
    },
    /**
     * 检测链接弹框
     */theLink: function theLink(row) {
      var _this2 = this;
      this.form = {
        arr: [{}]
      };
      this.linkUrl = "";
      this.dialogVisible = true;
      this.platform = row;
      generateMonitoringLink({
        key: this.platform.key
      }).then(function (res) {
        _this2.host = res.host;
      });
    },
    submit: function submit() {
      var _this3 = this;
      updateAppStore({
        id: this.platform.id,
        monitoring_url: this.linkUrl
      }).then(function (res) {
        _this3.msgSuccess("修改成功");
        _this3.dialogVisible = false;
        _this3.getList();
      });
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.form = deepClone(row);
      var arr = [];
      for (var key in this.form.argument) {
        arr.push({
          key: key,
          value: this.form.argument[key]
        });
      }
      this.form.arr = arr;
      this.oninputKey();
      this.open = true;
      this.title = "修改";
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this4 = this;
      var id = row.id || this.ids;
      this.$confirm('是否确认删除"' + row.platform_name + '"的数据项？').then(function () {
        return deleteAppStore({
          id: id
        });
      }).then(function () {
        _this4.getList();
        _this4.msgSuccess("删除成功");
      });
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this5 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this5.form.argument = _this5.link;
          if (_this5.form.id != undefined) {
            updateAppStore(_this5.form).then(function (res) {
              _this5.msgSuccess("修改成功");
              _this5.open = false;
              _this5.getList();
            });
          } else {
            createAppStore(_this5.form).then(function (res) {
              _this5.msgSuccess("新增成功");
              _this5.open = false;
              _this5.getList();
            });
          }
        }
      });
    },
    /** 查询渠道列表 */getList: function getList() {
      var _this6 = this;
      this.loading = true;
      getinappStorelist(this.queryParams).then(function (res) {
        if (res.data) {
          _this6.list = res.data;
          _this6.total = res.total;
        }
        _this6.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    test: function test(a, b) {
      return a + b;
    }
  }
};