import request from "@/utils/request";

//首页链接列表
export function linksList(query) {
  return request({
    url: 'admin/inner/links/list',
    method: 'get',
    params: query
  });
}
//上传图片
export function uploadFile(data) {
  return request({
    url: 'admin/common/uploadImage',
    method: 'post',
    data: data
  });
}
//新增链接
export function linksSave(data) {
  return request({
    url: 'admin/inner/links/save',
    method: 'post',
    data: data
  });
}
//修改链接
export function updateLinks(data) {
  return request({
    url: 'admin/inner/links/update',
    method: 'put',
    data: data
  });
}
//删除链接
export function delLinks(id) {
  return request({
    url: 'admin/inner/links/del?id=' + id,
    method: 'delete'
  });
}