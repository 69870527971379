import request from '@/utils/request';
// 外部用户列表
export function getopenuser(data) {
  return request({
    url: 'admin/inner/manageUser/getUserList',
    method: 'post',
    data: data
  });
}
// 删除外部用户
export function delopenuser(query) {
  return request({
    url: 'admin/inner/manageUser/delUser',
    method: 'delete',
    params: query
  });
}
// 新增用户
export function adduser(data) {
  return request({
    url: 'admin/inner/manageUser/saveUser',
    method: 'post',
    data: data
  });
}
//重置密码
export function resetPassword(data) {
  return request({
    url: 'admin/inner/manageUser/resetPassword',
    method: 'put',
    data: data
  });
}