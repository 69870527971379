//修改官方渠道
import request from "@/utils/request";
export function countSevenDayByChannel(query) {
  return request({
    url: 'admin/inner/channel/countSevenDayByChannel',
    method: 'get',
    params: query
  });
}
/**
 * 查询应用不带分页
 * @param query
 * @returns {*}
 */
export function getapplication(query) {
  return request({
    url: "admin/inner/channel/myAppWithChannelCode",
    method: "get",
    params: query
  });
}
/**
 *
 * @param query
 * @returns {*}
 */
export function saveCost(data) {
  return request({
    url: "admin/inner/channel/saveCost",
    method: "put",
    data: data
  });
}