var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "1" || _vm.type === "2"
    ? _c(
        "d2-container",
        [
          _c(
            "div",
            { staticClass: "page-header" },
            [
              _c("el-avatar", {
                staticClass: "user-avatar",
                attrs: {
                  src: _vm.userInfo.userInfo.avatar_url
                    ? _vm.userInfo.userInfo.avatar_url
                    : "https://q1.qlogo.cn/g?b=qq&nk=190848757&s=640",
                },
              }),
              _c("div", { staticClass: "title" }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.dateState()) +
                      ", " +
                      _vm._s(_vm.userInfo.username)
                  ),
                ]),
                _c("span", [_vm._v(" 开始您一天的工作吧！ ")]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [2],
                          expression: "[2]",
                        },
                      ],
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "25px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("友情链接")])]
                      ),
                      _c(
                        "el-row",
                        _vm._l(_vm.projects, function (ref, index) {
                          var name = ref.name
                          var icon = ref.icon
                          var describe = ref.describe
                          var link = ref.link
                          return _c(
                            "el-col",
                            { key: index, attrs: { span: 8 } },
                            [
                              _c(
                                "el-card",
                                {
                                  staticStyle: { padding: "0" },
                                  attrs: { shadow: "hover" },
                                },
                                [
                                  _c("div", { staticClass: "project-detail" }, [
                                    _c("div", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: link,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: { src: icon, alt: "" },
                                          }),
                                          _c("span", {
                                            staticClass: "name",
                                            domProps: {
                                              textContent: _vm._s(name),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("div", {
                                      staticClass: "slogan",
                                      attrs: { title: describe },
                                      domProps: {
                                        textContent: _vm._s(describe),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", [_vm._v("操作日志")]),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right", padding: "3px 0" },
                              attrs: { type: "text" },
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "system/mylog" } },
                                [_vm._v("更多")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        _vm._l(_vm.logList, function (ref, index) {
                          var route = ref.route
                          var created_at = ref.created_at
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticClass: "activity",
                              attrs: { span: 24 },
                            },
                            [
                              _c("el-avatar", {
                                key: index,
                                staticClass: "activity-avatar",
                                attrs: {
                                  src: _vm.userInfo.userInfo.avatar_url
                                    ? _vm.userInfo.userInfo.avatar_url
                                    : "https://q1.qlogo.cn/g?b=qq&nk=190848757&s=640",
                                  size: "small",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "activity-detail",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("p", [_vm._v(_vm._s(_vm.farmat(route)))]),
                                  _c("p", {
                                    staticStyle: {
                                      color: "#333333",
                                      "font-size": "10px",
                                    },
                                    domProps: {
                                      textContent: _vm._s(created_at),
                                    },
                                  }),
                                ]
                              ),
                              index + 1 < _vm.logList.length
                                ? _c("el-divider")
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [_c("span", [_vm._v("快捷导航")])]
                        ),
                        _c(
                          "el-row",
                          _vm._l(_vm.navigators, function (ref, index) {
                            var name = ref.name
                            var icon = ref.icon
                            var route = ref.route
                            var color = ref.color
                            return _c(
                              "el-col",
                              {
                                key: index,
                                staticStyle: { padding: "0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c("el-card", { attrs: { shadow: "hover" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "flex-direction": "column",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function () {
                                          _vm.gotoRoute(route)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        class: "fa fa-" + icon,
                                        style: {
                                          "font-size": "25px",
                                          color: color,
                                        },
                                        attrs: { slot: "title" },
                                        slot: "title",
                                      }),
                                      _c("div", {
                                        staticStyle: {
                                          "text-align": "center",
                                          "font-size": "12px",
                                          "margin-top": "20px",
                                        },
                                        domProps: { textContent: _vm._s(name) },
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-top": "25px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "work" },
                          [
                            _c("d2-icon-svg", {
                              staticStyle: {
                                "margin-left": "50%",
                                transform: "translateX(-50%)",
                                height: "216px",
                              },
                              attrs: { name: "work" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-top": "25px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [_c("span", [_vm._v("部门统计")])]
                        ),
                        _c(
                          "div",
                          [
                            _c("v-chart", {
                              staticClass: "chart",
                              attrs: { option: _vm.option },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _c("d2-container", [_vm._v(" 开发中... ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }