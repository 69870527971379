//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMemberAppList, updateMemberAppStatus } from "@/api/system/openApi/applicationList";
export default {
  name: "index",
  data: function data() {
    return {
      //审核状态
      options: [{
        status: 1,
        label: "启用"
      }, {
        status: 2,
        label: "关闭"
      }],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      userList: [{}],
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        username: undefined,
        status: undefined,
        deptId: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**
     * 审核
     * @param command
     * @param id
     */handleCommand: function handleCommand(command, id) {
      var _this = this;
      var data = {
        id: id,
        status: command
      };
      updateMemberAppStatus(data).then(function (res) {
        _this.msgSuccess("操作成功");
        _this.getList();
      });
    },
    /** 查询用户列表 */getList: function getList() {
      var _this2 = this;
      this.loading = true;
      getMemberAppList(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
        _this2.userList = res.list;
        _this2.total = res.total;
        _this2.loading = false;
      });
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        userId: undefined,
        deptId: undefined,
        username: undefined,
        nickName: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: undefined,
        status: "0"
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};