import "D:\\FANJ\\fj-dmp-ui\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\FANJ\\fj-dmp-ui\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\FANJ\\fj-dmp-ui\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\FANJ\\fj-dmp-ui\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
// 防止xss注入
import xss from "xss";
// 核心插件
import d2Admin from '@/plugin/d2admin';
import "@/assets/style/font.css";
// store
import store from '@/store/index';
import '@/assets/style/fanxin.scss'; //
// 菜单和路由设置
import router from './router';
import { menuHeader } from '@/menu';
// d2-crud-plus 安装与初始化
import './install';
// 配置vxe-table
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import { getUrlParam, addDateRange, resetForm, parseTime } from "@/utils/common.js";
import { download } from '@/utils/request';

// 分页组件
import Pagination from "@/components/Pagination";
// md5加密
import md5 from 'js-md5';
import DictTag from '@/components/DictTag';
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar";
import directive from './directive'; // directive

// 全局组件挂载
Vue.component('RightToolbar', RightToolbar);
Vue.component('DictTag', DictTag);

// 核心插件
Vue.use(directive);
Vue.use(d2Admin);
Vue.use(VXETable);

// 全局方法挂载
Vue.prototype.$md5 = md5;
Vue.prototype.getUrlParam = getUrlParam;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.resetForm = resetForm;
Vue.prototype.parseTime = parseTime;
Vue.prototype.download = download;
Vue.prototype.xss = xss;
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
};

// 全局组件挂载
Vue.component('Pagination', Pagination);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    // this.$store.commit('d2admin/page/init', frameInRoutes)
    // 设置顶栏菜单
    // this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // 设置侧边栏菜单
    // this.$store.commit('d2admin/menu/asideSet', menuAside)
    // 初始化菜单搜索功能
    // this.$store.commit('d2admin/search/init', menuAside)
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow');
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load');
    // 获取并记录用户
    this.$store.commit('d2admin/ua/get');
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen');
  },
  watch: {
    // 检测路由变化切换侧边栏内容
    '$route.matched': {
      handler: function handler(matched) {
        if (matched.length > 0) {
          var _side = menuHeader.filter(function (menu) {
            return menu.path === matched[0].path;
          });
          if (_side.length > 0) {
            this.$store.commit('d2admin/menu/asideSet', _side[0].children);
          }
        }
      },
      immediate: true
    }
  }
}).$mount('#app');