import request from "@/utils/request";
export function listJobAll(query) {
  return request({
    url: "admin/inner/new_tsp_manage/list",
    method: "get",
    params: query
  });
}
export function getscriptList(query) {
  return request({
    url: "admin/inner/tsp/scriptList",
    method: "get",
    params: query
  });
}
export function hostList(query) {
  return request({
    url: "admin/inner/tsp/hostList",
    method: "get",
    params: query
  });
}
export function addJob(data) {
  return request({
    url: "admin/inner/new_tsp_manage/add",
    method: "post",
    data: data
  });
}
export function addchildJob(data) {
  return request({
    url: "admin/inner/new_tsp_manage/addSonJob",
    method: "post",
    data: data
  });
}
export function tspjobstart(data) {
  return request({
    url: "admin/inner/new_tsp_manage/startJob",
    method: "post",
    data: data
  });
}
export function tspjobpause(data) {
  return request({
    url: "admin/inner/new_tsp_manage/pauseJob?",
    method: "post",
    data: data
  });
}
export function delJob(query) {
  return request({
    url: "admin/inner/new_tsp_manage/removeJob",
    method: "post",
    params: query
  });
}
export function jobresume(data) {
  return request({
    url: "admin/inner/tspManage/job/resume",
    method: "put",
    data: data
  });
}
export function resumeBatch(data) {
  return request({
    url: "admin/inner/new_tsp_manage/startBatch",
    method: "post",
    data: data
  });
}
export function stopBatch(data) {
  return request({
    url: "admin/inner/new_tsp_manage/stopBatch",
    method: "post",
    data: data
  });
}