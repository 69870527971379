var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用", prop: "orgId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择应用",
                    filterable: "",
                    "value-key": "orgId",
                  },
                  on: { change: _vm.changeApp },
                  model: {
                    value: _vm.queryParams.orgId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orgId", $$v)
                    },
                    expression: "queryParams.orgId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.orgId,
                    attrs: { label: item.displayName, value: item.orgId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOption,
                },
                on: { change: _vm.changeDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: { data: _vm.list, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "广告系列名称",
              prop: "metadata.campaignName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.searchword(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.metadata.campaignName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "展示次数",
              prop: "total.impressions",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "点击次数", prop: "total.taps" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "安装次数",
              prop: "total.installs",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "激活", prop: "total.activation" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "注册", prop: "total.register" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "新下载次数",
              prop: "total.newDownloads",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "重新下载次数",
              prop: "total.redownloads",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "latOn安装次数",
              prop: "total.latOnInstalls",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "latOff安装次数",
              prop: "total.latOffInstalls",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "平均每次转化费用",
              prop: "total.avgCPA.amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" ￥" + _vm._s(scope.row.total.avgCPA.amount) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "平均每次点击费用",
              prop: "total.avgCPT.amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" ￥" + _vm._s(scope.row.total.avgCPT.amount) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "平均每千次展示费用",
              prop: "total.avgCPM.amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" ￥" + _vm._s(scope.row.total.avgCPM.amount) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "支出",
              prop: "total.localSpend.amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " ￥" + _vm._s(scope.row.total.localSpend.amount) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "点击率", prop: "total.ttr" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "转化率",
              prop: "total.conversionRate",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [42],
                            expression: "[42]",
                          },
                        ],
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.recordList(scope.row)
                          },
                        },
                      },
                      [_vm._v("归因记录")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: { visible: _vm.drawer, title: "搜索词列表", size: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryTerms,
                size: "small",
                inline: true,
                "label-width": "68px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                      align: "right",
                      "unlink-panels": "",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOption,
                    },
                    model: {
                      value: _vm.dateRangeTerms,
                      callback: function ($$v) {
                        _vm.dateRangeTerms = $$v
                      },
                      expression: "dateRangeTerms",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTerms,
                  expression: "loadingTerms",
                },
              ],
              attrs: { data: _vm.termsList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "广告组ID",
                  prop: "metadata.adGroupId",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "广告组名称",
                  prop: "metadata.adGroupName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "搜索词ID",
                  prop: "metadata.keywordId",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "搜索词",
                  prop: "metadata.keyword",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "搜索词文本",
                  prop: "metadata.searchTermText",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "激活",
                  prop: "total.activation",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "注册",
                  prop: "total.register",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "展示次数",
                  prop: "total.impressions",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "点击次数",
                  prop: "total.taps",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "新安装",
                  prop: "total.newDownloads",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "重复安装",
                  prop: "total.redownloads",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "latOn安装次数",
                  prop: "total.latOnInstalls",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "latOff安装次数",
                  prop: "total.latOffInstalls",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "平均每次转化费用(元)",
                  prop: "total.avgCPA.amount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "平均每次点击费用(元)",
                  prop: "total.avgCPT.amount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支出",
                  prop: "total.localSpend.amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " ￥" +
                            _vm._s(scope.row.total.localSpend.amount) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "点击率", prop: "total.ttr" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "转化率",
                  prop: "total.conversionRate",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [42],
                                expression: "[42]",
                              },
                            ],
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.recordList(scope.row)
                              },
                            },
                          },
                          [_vm._v("归因记录")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalTerms > 0,
                expression: "totalTerms>0",
              },
            ],
            attrs: {
              total: _vm.totalTerms,
              page: _vm.queryTerms.page,
              limit: _vm.queryTerms.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryTerms, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryTerms, "size", $event)
              },
              pagination: _vm.getmsList,
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }