import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['logout'])), {}, {
    /**
     * @description 登出
     */logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },
    /** 个人信息 */userInfo: function userInfo() {
      this.$router.push("/userInfo");
    }
  })
};