var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content",
      style: { backgroundImage: "url(" + _vm.bgUrl + ")" },
    },
    [
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.machineType === "Mobile",
              expression: "machineType==='Mobile'",
            },
          ],
          staticClass: "down",
          attrs: { type: "primary", id: "downloadButton" },
        },
        [_vm._v("下载")]
      ),
      _c(
        "el-popover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.machineType === "Desktop",
              expression: "machineType==='Desktop'",
            },
          ],
          attrs: { placement: "top", width: "200", trigger: "hover" },
        },
        [
          _c("div", {
            staticStyle: { width: "200px" },
            attrs: { id: "qrCodeUrl" },
          }),
          _c(
            "el-button",
            {
              staticClass: "down",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [_vm._v("下载")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }