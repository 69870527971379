import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.join.js";
// 随机密码,数字,字母下划线,大小写都有
export function complexPsw(min, max) {
  // 可以生成随机密码的相关数组
  var num = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  var english = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  var ENGLISH = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  var special = ['-', '_', '#'];
  var config = num.concat(english).concat(ENGLISH).concat(special);

  // 先放入一个必须存在的
  var arr = [];
  arr.push(getOne(num));
  arr.push(getOne(english));
  arr.push(getOne(ENGLISH));
  arr.push(getOne(special));

  // 获取需要生成的长度
  var len = min + Math.floor(Math.random() * (max - min + 1));
  for (var i = 4; i < len; i++) {
    // 从数组里面抽出一个
    arr.push(config[Math.floor(Math.random() * config.length)]);
  }

  // 乱序
  var newArr = [];
  for (var j = 0; j < len; j++) {
    newArr.push(arr.splice(Math.random() * arr.length, 1)[0]);
  }

  // 随机从数组中抽出一个数值
  function getOne(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
  return newArr.join('');
}
// console.log(complexPsw(6,18))   //  最少6位，最大18位

// 随机六位数字密码
export function createPassword() {
  var Num = '';
  for (var i = 0; i < 6; i++) {
    //  想要几位就写几，我需要6位，所以我写的6
    Num += Math.floor(Math.random() * 10);
  }
  return Num;
}
// console.log(createPassword())   // 六位随机数字

// 随机六位数密码,包含字母大小写和数字
export var randomWord = function randomWord() {
  var code = '';
  for (var i = 0; i < 6; i++) {
    var type = getRandom(1, 3);
    switch (type) {
      case 1:
        code += String.fromCharCode(getRandom(48, 57)); // 数字
        break;
      case 2:
        code += String.fromCharCode(getRandom(65, 90)); // 大写字母
        break;
      case 3:
        code += String.fromCharCode(getRandom(97, 122)); // 小写字母
        break;
    }
  }
  return code;
};
function getRandom(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}
export default randomWord; // 我这里只暴露出去了我需要的一种