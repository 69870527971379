import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { list } from "@/api/system/log/monitoringAddress";
export default {
  name: "index",
  data: function data() {
    return {
      loading: false,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        userId: [],
        route: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 查询登录日志 */getList: function getList() {
      var _this = this;
      this.loading = true;
      list(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (response) {
        _this.list = response.data;
        _this.total = response.total;
        _this.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 多选框选中数据 */handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.operId;
      });
      this.multiple = !selection.length;
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('admin/inner/user/log', _objectSpread(_objectSpread({}, this.queryParams), {}, {
        export: true
      }), "operlog_".concat(new Date().getTime(), ".xlsx"));
    }
  },
  computed: {
    fomartErrorType: function fomartErrorType() {
      return function (e) {
        switch (e) {
          case 0:
            return "上报成功";
          case 1:
            return "队列投递失败";
          case 2:
            return "渠道无效";
          case 3:
            return "回调地址有误";
          case 4:
            return "未开通对应平台的数据上报功能";
          case 5:
            return "无法识别的上报平台";
          case 6:
            return "上报接口请求异常";
          case 7:
            return "未识别的上报类型";
          case 8:
            return "回调通知失败";
          case 9:
            return "回调通知成功";
        }
      };
    }
  }
};