import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getopenuser, delopenuser, adduser, resetPassword } from "@/api/system/openApi/user";
import { randomWord } from "@/utils/initPassword";
export default {
  name: "index",
  data: function data() {
    var validatePhone = function validatePhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      open: false,
      title: "",
      //用户状态
      options: [{
        status: 1,
        label: "启用"
      }, {
        status: 2,
        label: "注销"
      }],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      userList: null,
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        username: undefined,
        status: undefined,
        deptId: undefined
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: "用户名称不能为空",
          trigger: "blur"
        }, {
          min: 2,
          max: 20,
          message: '用户名称长度必须介于 2 和 20 之间',
          trigger: 'blur'
        }],
        truename: [{
          required: true,
          message: "用户姓名不能为空",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "用户密码不能为空",
          trigger: "blur"
        }, {
          min: 5,
          max: 20,
          message: '用户密码长度必须介于 5 和 20 之间',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          validator: validatePhone,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: "用户邮箱不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**
     * 切换密码
     */changeWord: function changeWord() {
      this.$set(this.form, "password", randomWord(6, 6));
    },
    /**
     * 操作日志
     * @param e
     */details: function details(e) {
      this.$router.push("/user/details/" + e.id);
    },
    /** 查询用户列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      getopenuser(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
        _this.userList = res.data;
        _this.total = res.total;
        _this.loading = false;
      });
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加用户";
      this.$set(this.form, "password", randomWord(6, 6));
    },
    /**
     * 取消按钮
     */cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.form = {
        userId: undefined,
        deptId: undefined,
        username: undefined,
        nickName: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: undefined,
        status: "0"
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 重置密码按钮操作 */handleResetPwd: function handleResetPwd(row) {
      var _this2 = this;
      this.$set(this.form, "password", randomWord(6, 6));
      this.$prompt('请输入"' + row.username + '"的新密码', "提示", {
        inputValue: this.form.password,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputPattern: /^.{5,20}$/,
        inputErrorMessage: "用户密码长度必须介于 5 和 20 之间"
      }).then(function (_ref) {
        var value = _ref.value;
        resetPassword({
          id: row.id,
          password: value
        }).then(function (res) {
          _this2.msgSuccess("修改成功，新密码是：" + value);
        });
      }).catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          adduser(_this3.form).then(function (res) {
            _this3.msgSuccess("新增成功");
            _this3.open = false;
            _this3.getList();
          });
        }
      });
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this4 = this;
      var userIds = row.id || this.ids;
      this.$confirm('是否确认删除用户为"' + row.username + '"的数据项？').then(function () {
        return delopenuser({
          id: userIds
        });
      }).then(function () {
        _this4.getList();
        _this4.msgSuccess("删除成功");
      }).catch(function () {});
    }
  }
};