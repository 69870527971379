var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("基础数据")]),
      _c(
        "div",
        { staticClass: "rightbtn" },
        [
          _vm.chanleIndex === 1
            ? _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择应用",
                    size: "small",
                    lable: "应用",
                    filterable: "",
                    "value-key": "id",
                  },
                  on: { change: _vm.changeApp },
                  model: {
                    value: _vm.dataKey,
                    callback: function ($$v) {
                      _vm.dataKey = $$v
                    },
                    expression: "dataKey",
                  },
                },
                _vm._l(_vm.appList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item },
                  })
                }),
                1
              )
            : _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择渠道",
                    size: "small",
                    lable: "渠道",
                    filterable: "",
                  },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.queryParams.channelCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "channelCode", $$v)
                    },
                    expression: "queryParams.channelCode",
                  },
                },
                _vm._l(_vm.chanleList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.channel_name,
                      value: item.channel_code,
                    },
                  })
                }),
                1
              ),
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                class: ["btn", _vm.chanleIndex === 1 ? "active" : ""],
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.channel(1)
                  },
                },
              },
              [_vm._v("友盟")]
            ),
            _c(
              "button",
              {
                class: ["btn", _vm.chanleIndex === 2 ? "active" : ""],
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.channel(2)
                  },
                },
              },
              [_vm._v("openinstall")]
            ),
          ]),
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                class: [
                  "btn",
                  _vm.deviceIndex === 1 && _vm.chanleIndex === 2
                    ? "active"
                    : "",
                ],
                attrs: {
                  type: "button",
                  disabled: _vm.chanleIndex === 1 ? true : false,
                },
                on: {
                  click: function ($event) {
                    return _vm.deviceSide(1)
                  },
                },
              },
              [_vm._v("all ")]
            ),
            _c(
              "button",
              {
                class: ["btn", _vm.deviceIndex === 2 ? "active" : ""],
                staticStyle: { "border-right": "0" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.deviceSide(2)
                  },
                },
              },
              [_vm._v("安卓 ")]
            ),
            _c(
              "button",
              {
                class: ["btn", _vm.deviceIndex === 3 ? "active" : ""],
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.deviceSide(3)
                  },
                },
              },
              [_vm._v("ios ")]
            ),
          ]),
          _c("el-button", {
            staticStyle: { height: "36px", "margin-left": "20px" },
            attrs: { icon: "el-icon-refresh", size: "mini" },
            on: { click: _vm.resetQuery },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.count, function (item, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "widget-container",
          },
          [
            _c("div", { staticClass: "contentItem" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
              _c("div", { staticClass: "range" }, [_vm._v(_vm._s(item.date))]),
              _c("div", { staticClass: "total" }, [_vm._v("合计")]),
              _c("div", { staticClass: "number-content" }, [
                _vm._v(_vm._s(item.value)),
                _c("span", { staticClass: "measuresUnit" }, [
                  _vm._v(_vm._s(item.type === 0 ? "人" : "%")),
                ]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(
        _vm.queryParams.type === "uMeng"
          ? _vm.count.slice(0, _vm.count.length - 1)
          : _vm.count,
        function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "widget-container",
              staticStyle: { width: "50%", height: "300px" },
            },
            [
              _c("div", { staticClass: "contentItem" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "range" }, [
                  _vm._v(_vm._s(item.date)),
                ]),
                _c("div", {
                  staticStyle: { height: "210px" },
                  attrs: {
                    id: "basic" + index,
                    "data-name": item.name,
                    "data-englishname": item.english,
                  },
                }),
              ]),
            ]
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }