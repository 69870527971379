var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-descriptions",
        [
          _c("el-descriptions-item", { attrs: { label: "用户名" } }, [
            _vm._v(_vm._s(_vm.info.truename)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(_vm._s(_vm.info.phone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "邮箱" } }, [
            _vm._v(_vm._s(_vm.info.email)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }