import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import { getrealTimeCount, getListAll, getAllApp } from "@/api/system/operationManagement/basicData";
export default {
  name: "index",
  data: function data() {
    return {
      dataKey: {},
      uMeng: {
        'visit': "访问量",
        'visit1': "访问量",
        'visit2': "访问量",
        'visit3': "访问量"
      },
      openInstall: {
        'visit': "访问量",
        'visit1': "访问量",
        'visit2': "访问量",
        'visit3': "访问量"
      },
      loading: true,
      //渠道列表
      chanleList: [],
      //应用列表
      appList: [],
      //查询
      queryParams: {
        type: "uMeng"
      },
      chanleIndex: 1,
      deviceIndex: 2,
      //汇总数据
      count: [{
        "name": "新增用户汇总",
        "value": "",
        "type": 0 //openInstall或者uMeng
      }, {
        "name": "活跃用户数汇总",
        "value": "",
        "type": 0 //openInstall或者uMeng
      }, {
        "name": "累计总用户数",
        "value": "",
        "type": 0 //openInstall或者uMeng
      }, {
        "name": "启动用户数汇总",
        "value": "",
        "type": 0 //openInstall或者uMeng
      }, {
        "name": "1日留存率",
        "value": "",
        "type": 1 //openInstall或者uMeng
      }, {
        "name": "7日留存率",
        "value": "",
        "type": 1 //openInstall或者uMeng
      }, {
        "name": "30日留存率",
        "value": "",
        "type": 1 //openInstall或者uMeng
      }, {
        "name": "平均激活用户数",
        "value": "",
        "type": 0 //openInstall或者uMeng
      }],

      //图表数据
      list: {},
      opinstallCount: {},
      opinstallList: {}
    };
  },
  created: function created() {
    var _this = this;
    /**
     * 获取渠道
     */
    getListAll().then(function (res) {
      _this.chanleList = res;
      _this.queryParams.channelCode = res[0].channel_code;
      // this.getBasicData()
    });
    /**
     * 获取应用
     */
    getAllApp().then(function (res) {
      _this.appList = res;
      _this.queryParams.uKey = res[0].uAndriodKey;
      _this.dataKey = res[0];
      _this.getBasicData();
    });
  },
  methods: {
    /**
     * 切换应用
     */changeApp: function changeApp(e) {
      this.queryParams.uKey = this.deviceIndex === 2 ? this.dataKey.uAndriodKey : this.dataKey.uiOSKey;
      this.loading = true;
      this.getBasicData();
    },
    /**
     * 切换渠道
     */change: function change(e) {
      this.queryParams.channelCode = e;
      this.loading = true;
      this.getBasicData();
    },
    /**
     * 获取运营数据
     */getBasicData: function getBasicData() {
      var _this2 = this;
      getrealTimeCount(this.queryParams).then(function (res) {
        if (res.type === "uMeng") {
          _this2.count = res.count;
          _this2.list = res.list;
        } else {
          var _res$count, _res$list;
          _this2.count = (_res$count = res.count) === null || _res$count === void 0 ? void 0 : _res$count.all;
          _this2.list = (_res$list = res.list) === null || _res$list === void 0 ? void 0 : _res$list.all;
          _this2.opinstallCount = res.count;
          _this2.opinstallList = res.list;
        }
        _this2.renderChart();
        _this2.loading = false;
      }).catch(function (err) {
        _this2.count = [];
        _this2.loading = false;
      });
    },
    /**
     * 切换渠道
     * @param e
     */channel: function channel(e) {
      this.chanleIndex = e;
      this.queryParams.type = e === 1 ? "uMeng" : "openInstall";
      this.getBasicData();
    },
    /**
     * 切换终端
     * @param e
     */deviceSide: function deviceSide(e) {
      this.deviceIndex = e;
      if (this.chanleIndex === 1) {
        if (e === 2) {
          this.queryParams.uKey = this.dataKey.uAndriodKey;
        } else if (e === 3) {
          this.queryParams.uKey = this.dataKey.uiOSKey;
        }
        this.getBasicData();
      } else {
        if (e === 1) {
          this.count = this.opinstallCount.all;
          this.list = this.opinstallList.all;
        } else if (e === 2) {
          this.count = this.opinstallCount.android;
          this.list = this.opinstallList.android;
        } else {
          this.count = this.opinstallCount.ios;
          this.list = this.opinstallList.ios;
        }
      }
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.queryParams.channelCode = this.chanleList[0].channel_code;
      this.queryParams.type = "uMeng";
      this.chanleIndex = 2;
      this.getBasicData();
    },
    /**
     * 渲染图表
     */renderChart: function renderChart() {
      var that = this;
      this.$nextTick(function () {
        (function () {
          var basic0 = document.getElementById('basic0');
          var name = basic0.dataset.name;
          var englishname = basic0.dataset.englishname;
          var myChart = echarts.init(basic0);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              }
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: that.list.date,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  type: "dashed"
                }
              }
            },
            series: [{
              data: that.list[englishname],
              type: 'line',
              name: name,
              symbol: "circle",
              symbolSize: 6,
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              lineStyle: {
                width: 1.5
              }
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
        (function () {
          var basic1 = document.getElementById('basic1');
          var name = basic1.dataset.name;
          var englishname = basic1.dataset.englishname;
          var myChart = echarts.init(basic1);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              },
              borderWidth: 0
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: that.list.date,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              name: name,
              type: 'bar',
              data: that.list[englishname],
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              barWidth: "20%"
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
        (function () {
          var basic2 = document.getElementById('basic2');
          var name = basic2.dataset.name;
          var englishname = basic2.dataset.englishname;
          var myChart = echarts.init(basic2);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              }
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: that.list.date,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  type: "dashed"
                }
              }
            },
            series: [{
              data: that.list[englishname],
              type: 'line',
              name: name,
              symbol: "circle",
              symbolSize: 6,
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              lineStyle: {
                width: 1.5
              }
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
        (function () {
          var basic3 = document.getElementById('basic3');
          var name = basic3.dataset.name;
          var englishname = basic3.dataset.englishname;
          var myChart = echarts.init(basic3);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              },
              borderWidth: 0
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: that.list.date,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              name: name,
              type: 'bar',
              data: that.list[englishname],
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              barWidth: "20%"
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
        (function () {
          var basic4 = document.getElementById('basic4');
          var name = basic4.dataset.name;
          var englishname = basic4.dataset.englishname;
          var myChart = echarts.init(basic4);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              }
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: that.list.one_time,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  type: "dashed"
                }
              }
            },
            series: [{
              data: that.list[englishname],
              type: 'line',
              name: name,
              symbol: "circle",
              symbolSize: 6,
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              lineStyle: {
                width: 1.5
              }
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
        (function () {
          var basic5 = document.getElementById('basic5');
          var name = basic5.dataset.name;
          var englishname = basic5.dataset.englishname;
          var myChart = echarts.init(basic5);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              }
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: that.list.seven_time,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  type: "dashed"
                }
              }
            },
            series: [{
              data: that.list[englishname],
              type: 'line',
              name: name,
              symbol: "circle",
              symbolSize: 6,
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              lineStyle: {
                width: 1.5
              }
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
        (function () {
          var basic6 = document.getElementById('basic6');
          var name = basic6.dataset.name;
          var englishname = basic6.dataset.englishname;
          var myChart = echarts.init(basic6);
          var option = {
            legend: {
              show: true,
              data: [name],
              bottom: 0,
              left: "center",
              itemWidth: 20,
              itemHeight: 2,
              icon: "rect"
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: "#000000",
              textStyle: {
                color: "#fff"
              }
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: "10%",
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: that.list.thirty_time,
              axisTick: {
                alignWithLabel: true,
                lineStyle: {
                  color: "#CCCCCC"
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  type: "dashed"
                }
              }
            },
            series: [{
              data: that.list[englishname],
              type: 'line',
              name: name,
              symbol: "circle",
              symbolSize: 6,
              itemStyle: {
                color: "#5291FF" //线的颜色以及头部显示的颜色
              },

              lineStyle: {
                width: 1.5
              }
            }]
          };
          option && myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        })();
      });
    }
  }
};