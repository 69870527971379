import request from '@/utils/request';

//查询应用列表
export function getappList(query) {
  return request({
    url: 'admin/inner/channel/appList',
    method: 'get',
    params: query
  });
}
//查询事件列表
export function geteventList(query) {
  return request({
    url: 'admin/inner/application/event/list',
    method: 'get',
    params: query
  });
}

//查询事件统计数据
export function getAllDataByEvent(data) {
  return request({
    url: 'admin/inner/application/event/getAllDataByEvent',
    method: 'post',
    data: data
  });
}
//查询自有事件列表
export function getappEventList(query) {
  return request({
    url: 'admin/inner/report/event/appEventList',
    method: 'get',
    params: query
  });
}
//查询自有事件统计数据
export function ownEventData(query) {
  return request({
    url: 'admin/inner/report/event/ownEventData',
    method: 'get',
    params: query
  });
}