import { render, staticRenderFns } from "./persion.vue?vue&type=template&id=5763d883&scoped=true&"
import script from "./persion.vue?vue&type=script&lang=js&"
export * from "./persion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5763d883",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\FANJ\\fj-dmp-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5763d883')) {
      api.createRecord('5763d883', component.options)
    } else {
      api.reload('5763d883', component.options)
    }
    module.hot.accept("./persion.vue?vue&type=template&id=5763d883&scoped=true&", function () {
      api.rerender('5763d883', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/openApi/user/persion.vue"
export default component.exports