import request from "@/utils/request";
export function getJobList(query) {
  return request({
    url: "admin/inner/application/storeConfig/job/list",
    method: "get",
    params: query
  });
}
export function addJob(data) {
  return request({
    url: "admin/inner/application/storeConfig/job/add",
    method: "post",
    data: data
  });
}
export function startjob(query) {
  return request({
    url: "admin/inner/application/storeConfig/job/start",
    method: "GET",
    params: query
  });
}