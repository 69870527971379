//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localeMixin from '@/locales/mixin.js';
export default {
  mixins: [localeMixin]
};