import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { clearReportErrorLog, list } from "@/api/system/log/exceptionLog";
export default {
  name: "index",
  data: function data() {
    return {
      option: [{
        value: "download",
        label: '下载量'
      }, {
        value: "recharge",
        label: '充值量'
      }, {
        value: "register",
        label: '注册量'
      }],
      options: [{
        value: 0,
        label: '上报成功'
      }, {
        value: 1,
        label: '队列投递失败'
      }, {
        value: 2,
        label: '渠道无效'
      }, {
        value: 3,
        label: '回调地址有误'
      }, {
        value: 4,
        label: '未开通对应平台的数据上报功能'
      }, {
        value: 5,
        label: '无法识别的上报平台'
      }, {
        value: 6,
        label: '上报接口请求异常'
      }, {
        value: 7,
        label: '未识别的上报类型'
      }, {
        value: 8,
        label: '回调通知失败'
      }, {
        value: 9,
        label: '回调通知成功'
      }],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        userId: [],
        route: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 查询登录日志 */getList: function getList() {
      var _this = this;
      this.loading = true;
      list(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (response) {
        _this.list = response.list;
        _this.total = response.total;
        _this.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /**
     *一键清空
     */oneClickClear: function oneClickClear() {
      var _this2 = this;
      clearReportErrorLog().then(function (res) {
        _this2.msgSuccess("清除成功");
        _this2.getList();
      });
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 多选框选中数据 */handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.operId;
      });
      this.multiple = !selection.length;
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      this.download('admin/inner/user/log', _objectSpread(_objectSpread({}, this.queryParams), {}, {
        export: true
      }), "operlog_".concat(new Date().getTime(), ".xlsx"));
    }
  },
  computed: {
    fomartType: function fomartType() {
      return function (e) {
        switch (e) {
          case "download":
            return "下载量";
          case "recharge":
            return "充值量";
          case "register":
            return "注册量";
          case "install":
            return "安装量";
        }
      };
    }
  }
};