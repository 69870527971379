import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { getCaptcha as _getCaptcha } from "@/api/login";
export default {
  data: function data() {
    return {
      // 表单
      formLogin: {
        username: '',
        password: ''
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'change'
        }],
        cap: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        }]
      },
      image_base: null,
      tab: 2
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['login'])), {}, {
    /**
     * 注册
     */inregister: function inregister() {
      this.$router.push("/register");
    },
    /**
     * RAM用户登录
     */inlogin: function inlogin() {
      this.$router.push("/inLogin");
    },
    /**
     * @description 提交表单
     */submit: function submit() {
      var _this = this;
      var that = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          // 具体需要传递的数据请自行修改代码
          _this.login({
            email: that.formLogin.username,
            password: that.$md5(that.formLogin.password),
            cap: that.formLogin.cap
          }).then(function () {
            // 重定向对象不存在则返回顶层路径
            _this.$router.replace('/');
          }).catch(function () {
            _this.getCaptcha();
          });
        } else {
          // 登录表单校验失败
          _this.$message.error('表单校验失败，请检查');
        }
      });
    },
    /**
     * 获取验证码
     */getCaptcha: function getCaptcha() {
      var _this2 = this;
      _getCaptcha().then(function (res) {
        _this2.image_base = res.cap;
      });
    },
    /**
     * 切换登陆方式
     * @param e
     */loginClick: function loginClick(e) {
      this.tab = e;
    }
  }),
  created: function created() {
    this.$store.dispatch('d2admin/db/databaseClear');
    this.getCaptcha();
  }
};