var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "platform_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.platform_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "platform_name", $$v)
                  },
                  expression: "queryParams.platform_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增 ")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tables",
          attrs: { border: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "key", align: "center", prop: "key" },
          }),
          _c("el-table-column", {
            attrs: { label: "回传地址", align: "center", prop: "callback_url" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "监测地址",
              align: "center",
              prop: "monitoring_url",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.monitoring_url
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.monitoring_url,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  size: "mini",
                                  type: "success",
                                  plain: "",
                                  id: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyText(
                                      scope.row.monitoring_url
                                    )
                                  },
                                },
                              },
                              [_vm._v("复制 ")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.theLink(scope.row)
                              },
                            },
                          },
                          [_vm._v("生成监测链接 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 2
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("不可用"),
                        ])
                      : _vm._e(),
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("可用"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "备注", align: "center", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作日期", align: "center", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [1137],
                            expression: "[1137]",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [1138],
                            expression: "[1138]",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用商店", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "key", prop: "key" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.key,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "key", $$v)
                        },
                        expression: "form.key",
                      },
                    },
                    _vm._l(_vm.keyList, function (k, value) {
                      return _c("el-option", {
                        key: value,
                        attrs: { label: k, value: value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "回传地址", prop: "callback_url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入回传地址" },
                    model: {
                      value: _vm.form.callback_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "callback_url", $$v)
                      },
                      expression: "form.callback_url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOption, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "额外参数" } },
                _vm._l(_vm.form.arr, function (item, index) {
                  return _c(
                    "el-row",
                    {
                      key: index,
                      staticClass: "prompt",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入参数" },
                            on: { input: _vm.oninputKey },
                            model: {
                              value: item.key,
                              callback: function ($$v) {
                                _vm.$set(item, "key", $$v)
                              },
                              expression: "item.key",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { span: 10 },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入参数" },
                            on: { input: _vm.oninputKey },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                          _vm.form.arr.length > 1
                            ? _c("i", {
                                staticClass: "el-icon-remove-outline addImg",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteInput(index)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.form.arr.length - 1 === index
                            ? _c("i", {
                                staticClass:
                                  "el-icon-circle-plus-outline addImg",
                                staticStyle: { right: "-30px" },
                                on: { click: _vm.addInput },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("el-alert", {
            attrs: {
              title: "安卓应用市场商店广告回传请求额外参数",
              type: "success",
            },
          }),
          _vm.link
            ? _c("el-alert", {
                attrs: { title: JSON.stringify(_vm.link), type: "success" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "监测链接",
            width: "40%",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.form.arr, function (item, index) {
            return _c(
              "el-row",
              { key: index, staticClass: "prompt", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入参数" },
                      on: { input: _vm.oninputUrl },
                      model: {
                        value: item.key,
                        callback: function ($$v) {
                          _vm.$set(item, "key", $$v)
                        },
                        expression: "item.key",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { position: "relative" },
                    attrs: { span: 10 },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入参数" },
                      on: { input: _vm.oninputUrl },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                    _vm.form.arr.length > 1
                      ? _c("i", {
                          staticClass: "el-icon-remove-outline addImg",
                          on: {
                            click: function ($event) {
                              return _vm.deleteInput(index)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.form.arr.length - 1 === index
                      ? _c("i", {
                          staticClass: "el-icon-circle-plus-outline addImg",
                          staticStyle: { right: "-30px" },
                          on: { click: _vm.addInput },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.linkUrl
            ? _c("el-alert", { attrs: { title: _vm.linkUrl, type: "success" } })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }