var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-color-picker", {
    staticClass: "btn-text can-hover",
    attrs: { value: _vm.value, predefine: _vm.predefine, size: "mini" },
    on: { change: _vm.set },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }