import request from '@/utils/request';

// 查询操作日志列表
export function list(query) {
  return request({
    url: 'admin/inner/channel/infoFlow/reportErrorLog',
    method: 'get',
    params: query
  });
}
export function clearReportErrorLog() {
  return request({
    url: 'admin/inner/channel/infoFlow/clearReportErrorLog',
    method: 'delete'
  });
}