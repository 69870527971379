import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getEventList, eventSave, updateStatus } from "@/api/system/extensionmant/eventList";
export default {
  name: "index",
  data: function data() {
    return {
      options: [],
      arr: [{}],
      params: [],
      dialogVisible: false,
      title: "",
      open: false,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        route: undefined
      },
      // 表单校验
      rules: {
        event_name: [{
          required: true,
          message: "事件名称不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注不能为空",
          trigger: "blur"
        }]
      },
      appName: ""
    };
  },
  created: function created() {
    if (this.$route.params.appId) {
      this.queryParams.app_id = this.$route.params.appId;
      this.appName = this.$route.params.appName;
      this.form.app_id = this.$route.params.appId;
      this.getList();
    }
  },
  methods: {
    /**
     * 状态修改
     */handleStatusChange: function handleStatusChange(row) {
      var _this = this;
      var text = row.status === 1 ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.event_name + '？').then(function () {
        return updateStatus({
          id: row.id,
          status: row.status
        });
      }).then(function () {
        _this.msgSuccess(text + "成功");
      }).catch(function () {
        row.status = row.status === 0 ? 1 : 0;
      });
    },
    deleteInput: function deleteInput(index) {
      this.arr.splice(index, 1);
    },
    addInput: function addInput() {
      this.arr.push({
        "name": "",
        //参数名称
        "remark": "" //参数备注
      });
    },
    details: function details(e) {
      this.dialogVisible = true;
      this.params = e;
    },
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.arr = [{}];
      this.open = true;
      this.title = "添加";
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this2.form.id != undefined) {
            updateStoreConfig(_this2.form).then(function (res) {
              _this2.msgSuccess("修改成功");
              _this2.open = false;
              _this2.getList();
            });
          } else {
            _this2.form.params = _this2.arr;
            eventSave(_this2.form).then(function (res) {
              _this2.msgSuccess("新增成功");
              _this2.open = false;
              _this2.getList();
            });
          }
        }
      });
    },
    /** 查询应用商店列表 */getList: function getList() {
      var _this3 = this;
      this.loading = true;
      getEventList(this.queryParams).then(function (res) {
        _this3.list = res.data;
        _this3.total = res.total;
        _this3.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};