import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAClsInfo, getAsaList, getSearchTermsList } from "@/api/system/extensionmant/asa";
import { parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      termsList: [],
      drawer: false,
      total: 0,
      totalTerms: 0,
      dateRange: [],
      dateRangeTerms: [],
      //日期
      pickerOption: {
        disabledDate: function disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近15天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      //应用
      options: [],
      // 遮罩层
      loading: true,
      loadingTerms: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      },
      queryTerms: {
        page: 1,
        size: 10
      },
      column: []
    };
  },
  created: function created() {
    var _this = this;
    this.dateRange[0] = parseTime(new Date().getTime() - 86400000 * 1, "{y}-{m}-{d}");
    this.dateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    getAClsInfo(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
      var _res$data$;
      _this.options = res.data;
      _this.queryParams.orgId = (_res$data$ = res.data[0]) === null || _res$data$ === void 0 ? void 0 : _res$data$.orgId;
      _this.getList();
    });
  },
  methods: {
    recordList: function recordList(e) {
      this.$router.push('/extensionmant/asa/AttributionRecord/' + e.metadata.campaignId + (e.metadata.keywordId ? "/" + e.metadata.keywordId : ""));
    },
    changeApp: function changeApp(e) {
      getList();
    },
    searchword: function searchword(e) {
      this.queryTerms.page = 1;
      this.queryTerms.orgId = e.metadata.orgId;
      this.queryTerms.campaignId = e.metadata.campaignId;
      this.dateRangeTerms = this.dateRange;
      this.drawer = true;
      this.termsList = [];
      this.getmsList();
    },
    getmsList: function getmsList() {
      var _this2 = this;
      this.loadingTerms = true;
      getSearchTermsList(this.addDateRange(this.queryTerms, this.dateRangeTerms, "defult")).then(function (res) {
        _this2.termsList = res.data.reportingDataResponse.row;
        _this2.totalTerms = res.pagination.totalResults;
        _this2.loadingTerms = false;
      });
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getList();
    },
    /** 查询 */getList: function getList() {
      var _this3 = this;
      this.loading = true;
      getAsaList(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
        _this3.list = res.data.reportingDataResponse.row;
        _this3.total = res.pagination.totalResults;
        _this3.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    }
  }
};