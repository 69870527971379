var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "任务名称", prop: "task_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入任务名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.task_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "task_name", $$v)
                  },
                  expression: "queryParams.task_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "执行状态", prop: "task_status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: "请选择状态", clearable: "" },
                  model: {
                    value: _vm.queryParams.task_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "task_status", $$v)
                    },
                    expression: "queryParams.task_status",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.status,
                    attrs: { label: item.label, value: item.status },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "操作时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAdd(0)
                    },
                  },
                },
                [_vm._v("新增 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-refresh-right",
                    size: "mini",
                    disabled: _vm.ids.length > 0 ? false : true,
                  },
                  on: { click: _vm.recovery },
                },
                [_vm._v("一键开启 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-refresh-right",
                    size: "mini",
                    disabled: _vm.ids.length > 0 ? false : true,
                  },
                  on: { click: _vm.stopBatch },
                },
                [_vm._v("一键停止 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _vm.openFold
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.Toexpandall },
                    },
                    [_vm._v(" 全部展开 ")]
                  )
                : _vm._e(),
              _vm.closeFold
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.ToClose },
                    },
                    [_vm._v("全部收起 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "alarm",
          attrs: {
            border: "",
            data: _vm.jobList,
            "row-key": "id",
            "default-expand-all": false,
            "row-style": _vm.rowstyle,
            "tree-props": { children: "children" },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "任务编号", align: "center", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "任务名称",
              align: "center",
              prop: "task_name",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "执行命令",
              align: "center",
              prop: "path",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "执行方式", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.cron_expression
                            ? scope.row.cron_expression
                            : "立即"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "执行状态",
                align: "center",
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.task_status === 0
                        ? _c("el-tag", [_vm._v("未开始")])
                        : _vm._e(),
                      scope.row.task_status === 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("运行中"),
                          ])
                        : _vm._e(),
                      scope.row.task_status === 2
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("已完成"),
                          ])
                        : _vm._e(),
                      scope.row.task_status === 3
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("任务错误"),
                          ])
                        : _vm._e(),
                      scope.row.task_status === 4
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("暂停"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 执行状态 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "在任务执行过程中任务是否正常执行完毕",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { label: "任务状态", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.job_run === "stop"
                        ? _c("el-button", {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-close",
                              circle: "",
                              size: "mini",
                            },
                          })
                        : _vm._e(),
                      scope.row.job_run === "running"
                        ? _c("el-button", {
                            attrs: {
                              type: "success",
                              icon: "el-icon-check",
                              circle: "",
                              size: "mini",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 任务状态 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "启动/停止",
                        placement: "top",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "间隔时间(s)",
              align: "center",
              prop: "interval_time",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "间隔顺序",
              align: "center",
              prop: "interval_method",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              align: "center",
              prop: "create_time",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "备注",
              align: "center",
              prop: "remark",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
              width: "300px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-plus",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAdd(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("添加子任务 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.forMart(scope.row),
                          size: "mini",
                          type: "text",
                          icon: "el-icon-caret-right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.jobStart(scope.row)
                          },
                        },
                      },
                      [_vm._v("开启任务 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: scope.row.job_run === "stop",
                          size: "mini",
                          type: "text",
                          icon: "el-icon-view",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.stopjob(scope.row)
                          },
                        },
                      },
                      [_vm._v("暂停任务 ")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        attrs: { size: "mini" },
                        on: {
                          command: function (command) {
                            return _vm.handleCommand(command, scope.row)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _c("i", {
                            staticClass: "el-icon-d-arrow-right el-icon--right",
                          }),
                          _vm._v("更多 "),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: "resumejob",
                                  icon: "el-icon-s-operation",
                                  disabled:
                                    scope.row.job_run === "running" ||
                                    scope.row.task_status === 3 ||
                                    scope.row.type === 1,
                                },
                              },
                              [_vm._v("恢复任务 ")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: "deljob",
                                  icon: "el-icon-delete",
                                },
                              },
                              [_vm._v("删除任务")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "appendto-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务选择", prop: "method_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.change },
                      model: {
                        value: _vm.form.method_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "method_type", $$v)
                        },
                        expression: "form.method_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("命令执行"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("脚本执行"),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("api执行"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务名称", prop: "task_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务名称" },
                    model: {
                      value: _vm.form.task_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "task_name", $$v)
                      },
                      expression: "form.task_name",
                    },
                  }),
                ],
                1
              ),
              _vm.form.method_type === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "执行命令", prop: "path" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入执行命令" },
                        model: {
                          value: _vm.form.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "path", $$v)
                          },
                          expression: "form.path",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.method_type === 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "接口路径", prop: "path" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入接口路径" },
                        model: {
                          value: _vm.form.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "path", $$v)
                          },
                          expression: "form.path",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.method_type === 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "query_method" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入query_method" },
                        model: {
                          value: _vm.form.query_method,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "query_method", $$v)
                          },
                          expression: "form.query_method",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.method_type === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "服务器配置", prop: "host" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.host,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "host", $$v)
                            },
                            expression: "form.host",
                          },
                        },
                        _vm._l(_vm.serves, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.host },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.method_type === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "脚本列表", prop: "path" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.path,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "path", $$v)
                            },
                            expression: "form.path",
                          },
                        },
                        _vm._l(_vm.scriptList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.scriptName, value: item.url },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "执行策略", prop: "type" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v("单次")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v("循环")]
                  ),
                ],
                1
              ),
              _vm.form.type === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "cron表达式", prop: "cron_expression" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入cron表达式" },
                        model: {
                          value: _vm.form.cron_expression,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cron_expression", $$v)
                          },
                          expression: "form.cron_expression",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.parent_id !== 0
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "执行间隔（秒）",
                          prop: "interval_time",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^\\d]/g, '')",
                            placeholder: "请输入间隔时长",
                          },
                          model: {
                            value: _vm.form.interval_time,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "interval_time", $$v)
                            },
                            expression: "form.interval_time",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "子任务执行规则", prop: "interval" } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.form.interval_method,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "interval_method", $$v)
                              },
                              expression: "form.interval_method",
                            },
                          },
                          [_vm._v("上级任务开始时")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.form.interval_method,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "interval_method", $$v)
                              },
                              expression: "form.interval_method",
                            },
                          },
                          [_vm._v("上级任务结束时")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }