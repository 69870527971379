import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import layoutHeaderAside from '@/layout/header-aside';
import userinfo from "@/layout/header-aside/components/header-user/userinfo";
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
  // 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('dashboard/workbench/index')
  }, {
    path: '/userInfo',
    name: 'userInfo',
    meta: {
      title: '个人信息',
      auth: true
    },
    // component: userinfo
    component: function component() {
      return import('@/layout/header-aside/components/header-user/userinfo');
    }
  },
  // dashboard 工作台
  {
    path: 'workbench',
    name: 'workbench',
    meta: {
      title: '工作台',
      auth: true
    },
    component: _import('dashboard/workbench')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, {
  path: '/inlogin',
  name: 'inlogin',
  component: _import('system/inlogin')
}, {
  path: '/register',
  name: 'register',
  component: _import('system/register')
}, {
  path: '/channel',
  name: 'channel',
  component: _import('channel')
}, {
  path: '/externalLogin',
  name: 'externalLogin',
  component: _import('externalLogin')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);