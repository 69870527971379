var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      attrs: { placement: "bottom", size: "small" },
      on: { command: _vm.handleChange },
    },
    [
      _c(
        "el-button",
        { staticClass: "d2-mr btn-text can-hover", attrs: { type: "text" } },
        [
          _c("d2-icon", {
            staticStyle: { "font-size": "16px" },
            attrs: { name: "font" },
          }),
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.options, function (item) {
          return _c(
            "el-dropdown-item",
            { key: item.value, attrs: { command: item.value } },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: _vm.iconName(item.value) },
              }),
              _vm._v(_vm._s(item.label) + " "),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }