var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("h3", [_vm._v("个人信息")]),
          _c("perion", { attrs: { id: _vm.id } }),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [_c("h3", [_vm._v("应用列表")]), _c("list", { attrs: { id: _vm.id } })],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("h3", [_vm._v("日志管理")]),
          _c("detail", { attrs: { id: _vm.id } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }