import _objectSpread from "D:/FANJ/fj-dmp-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import { getCaptcha as _getCaptcha } from "@/api/login";
import commonData from "@/utils/commonData";
import { Message } from "element-ui";
import { innerLogin, getGoogleQrCode } from "@/api/login";
import ClickOutside from 'vue-click-outside';
import VerificationCode from "@/components/VerificationCode";
export default {
  data: function data() {
    return {
      borderBalloon: true,
      checkBalloon: true,
      timer: null,
      seconds: 30,
      loginData: {},
      verVisible: false,
      verification: {
        action: "",
        username: "",
        code: "",
        google_salt: "",
        codeUrl: ""
      },
      gridData: [],
      visible: false,
      redirectUri: "",
      loading: true,
      tab: 1,
      formLogin: {},
      image_base: "",
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'change'
        }],
        cap: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    /**
     * 钉钉登陆获取authCode
     */
    if (this.getUrlParam("authCode")) {
      this.inlogin({
        authCode: this.getUrlParam("authCode")
      }).then(function () {
        // 重定向对象不存在则返回顶层路径
        _this.$router.replace('/');
      });
    }
    this.getCaptcha();
  },
  directives: {
    ClickOutside: ClickOutside
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ["googleLogin", "inlogin"])), {}, {
    complete: function complete(e) {
      var sum = "";
      for (var i = 0; i < e.length; i++) {
        sum += e[i];
      }
      this.verification.code = sum;
      this.clickVcation();
    },
    closeVer: function closeVer() {
      this.verVisible = false;
      this.borderBalloon = true;
      this.formLogin = {};
      this.getCaptcha();
    },
    /**
     * 刷新二维码
     */refresh: function refresh() {
      this.getGoogleQr();
    },
    JumpUri: function JumpUri() {
      if (this.isUrl(this.redirectUri)) {
        window.location.href = window.location.origin + "/externalLogin?redirectUri=" + decodeURIComponent(this.redirectUri);
      } else {
        Message({
          message: "请输入完整地址",
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    isUrl: function isUrl(str) {
      var v = new RegExp("^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$", 'i');
      return v.test(str);
    },
    /**
     * 切换主账号登陆
     */login: function login() {
      this.$router.push("/login");
    },
    /**
     * 切换登陆方式
     * @param e
     */loginClick: function loginClick(e) {
      this.tab = e;
    },
    /**
     * 获取验证码
     */getCaptcha: function getCaptcha() {
      var _this2 = this;
      _getCaptcha().then(function (res) {
        _this2.image_base = res.cap;
      });
    },
    /**
     * @description 谷歌验证
     */clickVcation: function clickVcation() {
      var _this3 = this;
      this.googleLogin(this.verification).then(function () {
        _this3.borderBalloon = true;
        // 重定向对象不存在则返回顶层路径
        _this3.$router.replace('/');
      }).catch(function () {
        _this3.checkBalloon = false;
        _this3.borderBalloon = false;
        setTimeout(function () {
          _this3.checkBalloon = true;
        }, 1000);
      });
    },
    /**
     * @description 提交表单
     */submit: function submit() {
      var _this4 = this;
      var that = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          innerLogin({
            username: _this4.formLogin.username,
            password: _this4.$md5(_this4.formLogin.password),
            cap: _this4.formLogin.cap
          }).then(function (res) {
            if (res.action === "bind") {
              _this4.$confirm('系统检测到你尚未绑定设备,是否立即绑定谷歌设备？').then(function () {
                that.loginData = res;
                that.getGoogleQr();
              });
            } else {
              _this4.verification = res;
              _this4.verVisible = true;
            }
          }).catch(function () {
            _this4.getCaptcha();
          });
        } else {
          // 登录表单校验失败
          _this4.$message.error('表单校验失败，请检查');
        }
      });
    },
    /**
     * 获取二维码
     */getGoogleQr: function getGoogleQr() {
      var _this5 = this;
      getGoogleQrCode(this.loginData).then(function (e) {
        _this5.seconds = 30;
        if (_this5.timer) clearInterval(_this5.timer);
        _this5.timer = setInterval(function () {
          _this5.seconds--;
          if (_this5.seconds === 0) {
            clearInterval(_this5.timer);
          }
        }, 1000);
        _this5.verVisible = true;
        _this5.verification = e;
      });
    }
  }),
  mounted: function mounted() {
    var _this6 = this;
    /**
     * 钉钉快捷登陆
     * dinglntx7t94hv8xzgyi 正式
     * dinguits1gjjn2m4td2m
     */
    window.DTFrameLogin({
      id: 'login_container',
      width: 240,
      height: 240
    }, {
      redirect_uri: encodeURIComponent(commonData.redirect_uri),
      client_id: commonData.client_id,
      scope: 'openid',
      response_type: 'code',
      prompt: 'consent'
    }, function (loginResult) {
      _this6.loading = false;
      var redirectUrl = loginResult.redirectUrl,
        authCode = loginResult.authCode,
        state = loginResult.state;
      // 这里可以直接进行重定向
      window.location.href = redirectUrl;
      // 也可以在不跳转页面的情况下，使用code进行授权
    }, function (errorMsg) {
      // 这里一般需要展示登录失败的具体原因
      console.log("Login Error: ".concat(errorMsg));
    });
    setTimeout(function () {
      _this6.loading = false;
    }, 1200);
  },
  components: {
    VerificationCode: VerificationCode
  }
};