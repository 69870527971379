export default {
  namespaced: true,
  state: {
    // 灰度
    active: false
  },
  mutations: {
    /**
     * @description 切换灰度状态
     * @param {Object} state state
     */toggle: function toggle(state) {
      state.active = !state.active;
    },
    /**
     * @description 设置灰度模式
     * @param {Object} state state
     * @param {Boolean} active active
     */set: function set(state, active) {
      state.active = active;
    }
  }
};