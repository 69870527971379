import request from '@/utils/request';
export function getclearTypeConf(query) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/list',
    method: 'get',
    params: query
  });
}
export function addType(data) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/add',
    method: 'post',
    data: data
  });
}
export function updateType(data) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/update',
    method: 'put',
    data: data
  });
}
export function delType(query) {
  return request({
    url: 'admin/inner/application/clearTypeConfig/del',
    method: 'delete',
    params: query
  });
}