import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { appOfficialChannelListByAppId, getAppUserOrderBaseData } from "@/api/system/extensionmant/recharge";
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import { getapplication } from "@/api/system/extensionmant/myData";
import { parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      total: 0,
      dateRange: [],
      //日期
      pickerOption: {
        disabledDate: function disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近15天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      //应用
      options: [],
      channel: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        user_id: "",
        channel_code: ""
      },
      column: [],
      //合计数据
      count: {}
    };
  },
  created: function created() {
    var _this = this;
    this.dateRange[0] = parseTime(new Date().getTime() - 86400000 * 7, "{y}-{m}-{d}");
    this.dateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    /**
     * 获取应用和渠道
     */
    getapplication(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
      _this.options = res.list;
      _this.getList();
    });
  },
  methods: {
    changeApp: function changeApp(e) {
      this.gitmarkpChannel(e);
      this.queryParams.channel_code = '';
    },
    gitmarkpChannel: function gitmarkpChannel(app_id) {
      var _this2 = this;
      appOfficialChannelListByAppId({
        app_id: app_id
      }).then(function (res) {
        _this2.channel = res;
        _this2.channel.push({
          channel_code: "ios",
          channel_name: "IOS"
        });
      });
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getList();
    },
    htmlToExcel: function htmlToExcel() {
      var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '默认标题';
      var wb = XLSX.utils.table_to_book(document.getElementById('consumption'));
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      var blob = new Blob([wbout], {
        type: 'application/octet-stream'
      });
      FileSaver.saveAs(blob, "".concat(title, ".xlsx"));
    },
    /** 查询应用商店列表 */getList: function getList() {
      var _this3 = this;
      this.loading = true;
      getAppUserOrderBaseData(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
        _this3.count = res.count;
        _this3.list = res.data;
        _this3.total = res.total;
        _this3.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};