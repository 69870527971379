import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { appOfficialChannelListByAppId, countByAppAndUser } from "@/api/system/extensionmant/appliedData";
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import { getapplication } from "@/api/system/extensionmant/myData";
import { parseTime } from "@/utils/common";
export default {
  name: "index",
  data: function data() {
    return {
      appDeviceList: [{
        name: '安卓',
        value: 'android'
      }, {
        name: 'IOS',
        value: 'iOS'
      }, {
        name: 'wap',
        value: 'wap'
      }],
      total: 0,
      dateRange: [],
      //日期
      pickerOption: {
        disabledDate: function disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近15天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 1000 * 3600 * 24 * 28);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      //应用
      options: [],
      channel: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        user_id: "",
        channel_code: ""
      },
      column: [],
      //合计数据
      count: {}
    };
  },
  created: function created() {
    var _this = this;
    this.dateRange[0] = parseTime(new Date().getTime() - 86400000 * 7, "{y}-{m}-{d}");
    this.dateRange[1] = parseTime(new Date().getTime(), "{y}-{m}-{d}");
    /**
     * 获取应用和渠道
     */
    getapplication(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
      _this.options = res.list;
      _this.getList();
    });
  },
  methods: {
    //表尾合计行计算方法
    getSummaries: function getSummaries(param) {
      var _this2 = this;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        //根据当前列绑定的字段名进行判断，根据字段名决定展示什么内容
        switch (column.property) {
          case "consume_amount":
            sums[index] = _this2.count.consume_amount || 0;
            break;
          case "consume_frequency":
            sums[index] = _this2.count.consume_frequency || 0;
            break;
          case "consume_number":
            sums[index] = _this2.count.consume_number || 0;
            break;
          case "first_recharge_amount":
            sums[index] = _this2.count.first_recharge_amount || 0;
            break;
          case "first_recharge_frequency":
            sums[index] = _this2.count.first_recharge_frequency || 0;
            break;
          case "first_recharge_number":
            sums[index] = _this2.count.first_recharge_number || 0;
            break;
          case "recharge_amount":
            sums[index] = _this2.count.recharge_amount || 0;
            break;
          case "recharge_frequency":
            sums[index] = _this2.count.recharge_frequency || 0;
            break;
          case "recharge_number":
            sums[index] = _this2.count.recharge_number || 0;
            break;
          case "refund_amount":
            sums[index] = _this2.count.refund_amount || 0;
            break;
          case "refund_amount_subsidy":
            sums[index] = _this2.count.refund_amount_subsidy || 0;
            break;
          case "refund_number":
            sums[index] = _this2.count.refund_number || 0;
            break;
          case "refund_number_subsidy":
            sums[index] = _this2.count.refund_number_subsidy || 0;
            break;
          case "register_count":
            sums[index] = _this2.count.register_count || 0;
            break;
          case "before_month_two_day_amount":
            sums[index] = _this2.count.before_month_two_day_amount || 0;
            break;
          case "before_month_recharge_amount":
            sums[index] = _this2.count.before_month_recharge_amount || 0;
            break;
          case "today_sum_amount":
            sums[index] = _this2.count.today_sum_amount || 0;
            break;
          //不需要计算合计的列就展示‘--’
          default:
            sums[index] = "--";
            break;
        }
      });
      return sums;
    },
    changeApp: function changeApp(e) {
      this.gitmarkpChannel(e);
      this.queryParams.channel_code = '';
    },
    gitmarkpChannel: function gitmarkpChannel(app_id) {
      var _this3 = this;
      appOfficialChannelListByAppId({
        app_id: app_id
      }).then(function (res) {
        _this3.channel = res;
      });
    },
    /**
     * 切换时间查询
     */changeDate: function changeDate() {
      this.getList();
    },
    htmlToExcel: function htmlToExcel() {
      var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '默认标题';
      var wb = XLSX.utils.table_to_book(document.getElementById('consumption'));
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      var blob = new Blob([wbout], {
        type: 'application/octet-stream'
      });
      FileSaver.saveAs(blob, "".concat(title, ".xlsx"));
    },
    /** 查询应用商店列表 */getList: function getList() {
      var _this4 = this;
      this.loading = true;
      countByAppAndUser(this.addDateRange(this.queryParams, this.dateRange, "defult")).then(function (res) {
        _this4.count = res.count;
        _this4.list = res.data;
        _this4.total = res.total;
        _this4.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};