var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _vm._m(0),
    _c("div", { staticClass: "irBWyp" }, [
      _c("div", [
        _c("div", { staticClass: "tabs-nav" }, [
          _c(
            "div",
            {
              staticClass: "tabs-item",
              on: {
                click: function ($event) {
                  return _vm.loginClick(1)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tabs-item-text",
                  style: { color: _vm.tab == 2 ? "#ADADAD" : "#040D2B" },
                },
                [
                  _vm._v("扫码快速登录 "),
                  _c("div", { class: [{ active: _vm.tab == 1 }] }),
                ]
              ),
            ]
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === 1,
                expression: "tab===1",
              },
            ],
            staticClass: "tab-content",
          },
          [
            _c("div", { staticClass: "alipay-text" }, [
              _vm._v("钉钉扫码快速登录"),
            ]),
            _vm._m(1),
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { id: "login_container" },
            }),
            _vm._m(2),
          ]
        ),
      ]),
      _c("div", { staticClass: "third-party-login-wrap" }, [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticClass: "footer-item" }, [
            _c("span", [
              _vm.redirectUri
                ? _c(
                    "div",
                    {
                      staticClass: "ram-login-text",
                      staticStyle: { color: "#194DEE" },
                    },
                    [
                      _vm._v(
                        "您正在登陆 " +
                          _vm._s(
                            decodeURIComponent(_vm.redirectUri).split("/")[2]
                          )
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marketing-wrapper" }, [
      _c("img", { attrs: { src: "/image/theme/star/1.png" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-icon-item" }, [
      _c("i", { staticClass: "dingding iconfont" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alipay-texts" }, [
      _c("span", [_vm._v("未注册用户在扫描后将为您注册新账号")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }