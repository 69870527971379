//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStoreConfig, addStoreConfig, updateStoreConfig, delStoreConfig, getstoreList } from "@/api/system/applicationconfig/appStore";
import { getapplication } from "@/api/system/applicationconfig/applist";
export default {
  name: "index",
  data: function data() {
    return {
      option: [],
      options: [],
      title: "",
      open: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        route: undefined
      },
      // 表单校验
      rules: {
        storeName: [{
          required: true,
          message: "应用商店名称不能为空",
          trigger: "blur"
        }],
        storeLink: [{
          required: true,
          message: "请选择应用",
          trigger: "blur"
        }],
        appId: [{
          required: true,
          message: "应用不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    getapplication(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
      _this.options = res.list;
    });
    getstoreList(this.addDateRange(this.queryParams, this.dateRange)).then(function (res) {
      _this.option = res;
    });
    this.getList();
  },
  methods: {
    /**
     * 表单重置
     */reset: function reset() {
      this.resetForm("form");
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      this.$set(this.form, "storeLink", row.store_link);
      this.$set(this.form, "storeName", row.store_name);
      this.$set(this.form, "id", row.id);
      this.open = true;
      this.title = "修改";
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.form = {};
      this.open = true;
      this.title = "添加";
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this2 = this;
      var id = row.id;
      this.$confirm('是否确认删除？').then(function () {
        return delStoreConfig({
          id: id
        });
      }).then(function () {
        _this2.getList();
        _this2.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this3.form.id != undefined) {
            updateStoreConfig(_this3.form).then(function (res) {
              _this3.msgSuccess("修改成功");
              _this3.open = false;
              _this3.getList();
            });
          } else {
            addStoreConfig(_this3.form).then(function (res) {
              _this3.msgSuccess("新增成功");
              _this3.open = false;
              _this3.getList();
            });
          }
        }
      });
    },
    /** 查询应用商店列表 */getList: function getList() {
      var _this4 = this;
      this.loading = true;
      getStoreConfig(this.queryParams).then(function (res) {
        _this4.list = res.data;
        _this4.total = res.total;
        _this4.loading = false;
      });
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
};