import request from "@/utils/request";
export function getAsaList(data) {
  return request({
    url: '/admin/inner/channel/appleAds/getCampaigns',
    method: 'post',
    data: data
  });
}
export function getAClsInfo(query) {
  return request({
    url: '/admin/inner/channel/appleAds/getAClsInfo',
    method: 'get',
    params: query
  });
}
export function getSearchTermsList(data) {
  return request({
    url: '/admin/inner/channel/appleAds/getSearchTermsList',
    method: 'post',
    data: data
  });
}
export function getAppleAdsReportLogList(data) {
  return request({
    url: '/admin/inner/channel/appleAds/getAppleAdsReportLogList',
    method: 'post',
    data: data
  });
}