var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("h3", [_vm._v("业务数据源")]),
          _c("business-data", { attrs: { appId: _vm.appId } }),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("h3", [_vm._v("应用商店爬取任务")]),
          _c("app-configuration", { attrs: { appId: _vm.appId } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }